<template>
  <collapsible
    :contentClasses="['countryCard__collapsible-content']"
    :wrapperClasses="['collapsible-panel', 'flex', 'flex-column']"
  >
    <template slot="trigger" slot-scope="renderProps">
      <h4 class="collapsible-panel__heading h5">
        <button
          @click.prevent="renderProps.toggleOpen"
          class="collapsible-panel__toggle card__inner card__inner--dark card__section--keyline"
        >
          <icon-base icon-name="chevron-down" width="15" height="9" className="collapsible-panel__indicator">
            <icon-chevron-down />
          </icon-base>
          <span class="h3 no-margin-bottom">{{ title }}</span>
          <span class="countryCard__selection_count pull-right sml-push-right">
            <slot name="count"></slot>
          </span>
        </button>
      </h4>
    </template>
    <template slot="content">
      <div class="collapsible-panel__content">
        <slot name="content"></slot>
      </div>
    </template>
  </collapsible>
</template>

<script>
  import Collapsible from '../Collapsible.vue';
  import IconBase from '../icons/IconBase.vue';
  import IconChevronDown from '../icons/IconChevronDown.vue';

  export default {
    name: 'CollapsibleSection',
    props: {
      title: String,
    },
    components: {
      Collapsible,
      IconBase,
      IconChevronDown,
    },
  };
</script>
