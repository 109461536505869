var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"user-management__title"},[_c('h1',[_vm._v("User Management")]),_vm._v(" "),_c('a',{staticClass:"btn btn--primary",attrs:{"href":"/admin/user/create","target":"_self"}},[_c('svg',{staticClass:"icon sml-push-left",attrs:{"viewBox":"0 0 18 20","width":"16px","height":"20px"}},[_c('use',{attrs:{"xmlns:xlink":"http://www.w3.org/1999/xlink","xlink:href":"/svg/icon-sprite.svg#icon-survey-new"}})]),_vm._v("\n      New user")])]),_vm._v(" "),_c('UserDatabase',{ref:"userDatabase",attrs:{"allocationEnabled":"","allocationOptions":_vm.allocationOptions.data,"defaultFilters":{},"filters":[
      {
        id: 'role',
        label: 'Role',
        options: ['Admin', 'Client', 'Respondent']
      } ].concat( _vm.filters
    ),"columns":[
      {
        type: 'checkbox'
      },
      {
        label: 'Name',
        value: function (item) { return item.name; },
        type: 'link',
        action: function (item) { return ("/admin/user/profile/" + (item.id) + "/"); }
      },
      {
        label: 'Role',
        value: function (item) { return item.role || '-'; }
      },
      {
        label: 'Locality',
        value: function (item) { return item.locality_names.length ? _vm.displayFirstOrWithCounter(item.locality_names) : '-'; }
      },
      {
        label: 'Sector',
        value: function (item) { return item.sector_names.length ? _vm.displayFirstOrWithCounter(item.sector_names) : '-'; }
      },
      {
        label: 'Country',
        value: function (item) { return item.country_names.length ? _vm.displayFirstOrWithCounter(item.country_names) : '-'; }
      },
      {
        label: 'Tags',
        value: function (item) { return item.tags.length
            ? item.tags.map(function (tag) {
                return ("#" + (tag.tag));
              }).join(', ')
            : '-'; }
      },
      { label: '', type: 'actions', value: function (item) { return item; } }
    ]}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }