<template>
  <div>
    <div class="form__input_actions flex flex-align-center flex-between">
      <div class="flex flex-align-center">
        <div class="flex flex-align-center">
          <icon-base icon-name="probe" width="20" height="20"
            ><icon-probe
          /></icon-base>
          <span class="form__input_type">
            {{ displayNumber }}. Probed questions
          </span>
        </div>
      </div>
      <div class="flex flex-align-center">
        <slot name="hiddenQuestionInputs"></slot>
        <slot name="removeField"></slot>
      </div>
    </div>
    <question-label
    :pageNumber="pageNumber"
    :questionNumber="questionNumber"
    >
    </question-label>

    <!-- hidden input bound to the number of questions assigned to this probe, used to validate a minimum of one question -->
    <input
    type="hidden"
    :value="numberOfQuestions"
    :name="`pages[${pageNumber}][questions][${questionNumber}][num_probe_questions]`"
    :data-vv-name="`pages.${pageNumber}.questions.${questionNumber}.num_probe_questions`"
    data-vv-as="probe questions"
    v-validate="'num_probe_questions'"
    />

    <div class="form__group">
      <survey-question-builder
        :question-number="questionNumber"
        :page-number="pageNumber"
        :parent-u-i-d="config.uid"
        :parent-index="displayNumber"
        :question-limit="12"
      >
        <div slot="hiddenPageInputs" slot-scope="hiddenPageInputs">
          <input
            type="hidden"
            :value="`${hiddenPageInputs.totalInputs}`"
            :name="`pages[${pageNumber}][questions][${questionNumber}][num_probe_questions]`"
          />
        </div>
        <div slot="addQuestionButton" slot-scope="questionButton">
          <div
            class="btn btn--secondary sml-push-top"
            v-on:click="questionButton.toggleQuestionSelector()"
            v-click-outside="questionButton.hideQuestionSelector"
          >
            Add probe question
            <icon-base
              icon-name="add"
              width="17"
              height="20"
              className="sml-push-right"
              ><icon-add
            /></icon-base>
          </div>
        </div>
      </survey-question-builder>
      <div>
        <slot name="moveFieldUp"></slot>
        <slot name="moveFieldDown"></slot>
      </div>
      <small
        v-show="numberOfQuestions === 0"
        class="center text-center push-top danger"
      >
        Probes must contain at least one question
      </small>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import Focus from "../../directives/focus";
import IconBase from "../icons/IconBase.vue";
import IconProbe from "../icons/IconProbe.vue";
import IconAdd from "../icons/IconAdd.vue";
import SurveyQuestionBuilder from "../survey/SurveyQuestionBuilderNested.vue";
import QuestionLabel from "./QuestionLabel.vue";

export default {
  name: "Probed",
  components: {
    IconBase,
    IconProbe,
    IconAdd,
    SurveyQuestionBuilder,
    QuestionLabel
  },
  directives: {
    Focus,
    ClickOutside,
  },
  inject: ["$validator"],
  props: {
    pageNumber: Number,
    questionNumber: Number,
    displayNumber: String | Number,
  },
  created() {},
  methods: {},
  data() {
    return {};
  },
  computed: {
    config() {
      return this.$store.getters.config(this.pageNumber, this.questionNumber);
    },
    numberOfQuestions(){
      return this.childQuestions.length
    },
    childQuestions() {
      return this.$store.getters.questions(this.pageNumber).filter(question => question.config.parentUID == this.config.uid)
    }
  },
};
</script>
