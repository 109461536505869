import { render, staticRenderFns } from "./HonorariumAllocation.vue?vue&type=template&id=78da3f56&scoped=true&"
import script from "./HonorariumAllocation.vue?vue&type=script&lang=js&"
export * from "./HonorariumAllocation.vue?vue&type=script&lang=js&"
import style0 from "./HonorariumAllocation.vue?vue&type=style&index=0&id=78da3f56&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78da3f56",
  null
  
)

export default component.exports