const _ = require('lodash');

const formAutosave = (() => {

  let isSaving = false;
  let shouldSave = false;


  function saveAction () {
    const form = document.querySelector('.js-respond-form');
    const path = form.getAttribute('action');
    const autosavePath = path.replace('respond', 'respond-autosave');
    const formData = new FormData(form);
    const csrfToken = document.querySelector('input[name="_token"]').value;

    let xhr = new XMLHttpRequest();
    isSaving = true;
    xhr.open("POST", autosavePath);
    xhr.setRequestHeader('X-CSRF-TOKEN', csrfToken);
    xhr.onload = () => {
      isSaving = false;
      if (shouldSave){
        shouldSave = false;
        saveAction();
      }
      if (xhr.status != 200) {
        console.log(xhr.status);
      }
    };
    xhr.send(formData);
  }

  const requestSave = _.debounce(() => {
    if (!isSaving){
      saveAction();
      return;
    }
    shouldSave = true;
  }, 1500)

  function changeEvent(el) {
    el.addEventListener('change', (e) => {
      e.preventDefault();
      requestSave();
    });
  }

  function bindUIEvents() {
    let respondForm = document.querySelector('.js-respond-form');
    if (respondForm) {
      Array.from(respondForm.querySelectorAll('input, textarea, select')).forEach(changeEvent);
    }
  }

  function init() {
    bindUIEvents();
  }

  return {
    init,
  };
})();

export default formAutosave;
