<template>
  <div>
    <label v-for="option in field.valuesAllowed">
      <input
        type="radio"
        v-model="value"
        v-bind:value="option.key"
        v-on:input="updateValue($event.target.value)">
      <span>{{ option.name }}</span>
    </label>

  </div>
</template>

<script>

export default {
  name: 'FieldRadio',
  data() {
    return {
      'value': null
    };
  },
  mounted() {
  },
  props: {
    field: Object
  },
  methods: {
    updateValue: function (value) {
      this.$emit('input', value);

      if (this.field.refreshRequirementsOnChange) {
        this.$emit('refreshRequirements');
      }
    }
  },
};
</script>
