import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import surveyBuilderStore from './survey_builder';
import matrixStore from './matrix';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    survey: surveyBuilderStore,
    matrix: matrixStore,
  },
  plugins: [
    // TODO: We think we can take this out? Breaks survey dist matrix when in use.
    createPersistedState({
      key: window.location.pathname.split('surveys/')[1],
      paths: ['1']
    }),
  ],
});
