import h from './_helpers';

const toggleShow = (() => {
  const toggleAnchor = Array.from(document.querySelectorAll('[data-toggle="anchor"]'));

  function toggle(anchor) {
    const targetRelationship = anchor.getAttribute('data-toggle-relationship');
    const toggleTarget = h[`get${targetRelationship}`](anchor);

    anchor.classList.toggle('open');

    toggleTarget.forEach((target) => {
      target.classList.toggle('open');
    });
  }

  function bindUIEvents() {
    toggleAnchor.forEach((anchor) => {
      anchor.addEventListener('click', toggle.bind(null, anchor));
    });
  }

  function init() {
    bindUIEvents();
  }

  return {
    init,
  };
})();

export default toggleShow;
