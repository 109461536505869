<template>
  <div>
    <div class="sml-padding-v-half">
      <small>
        Click and drag options to re-order
        <icon-base icon-name="reorder" width="12" height="11" className="">
          <icon-reorder/>
        </icon-base>
      </small>
      <draggable
        v-model="stateOptions"
        @start="drag = true"
        @end="drag = false"
        @change="handleDrag"
        :options="{ disabled: stateOptions.length < 2 }"
      >
        <div
          class="rankingQuestionInput sml-push-top"
          v-for="(option, index) in stateOptions"
          :key="index"
        >
          <span class="form__input-index">{{ index + 1 }}.</span>
          <span class="ranking-label">{{ option.label }}</span>
        </div>
      </draggable>
      <input
        hidden
        :id="dataName"
        :name="dataName"
        :data-id="id"
        :value="response"
      />
      <small v-if="!this.stateSaved" class="danger">
        <strong>You must confirm your ranking before you can submit your survey response.</strong>
      </small>
      <button
        :data-name="dataName"
        v-on:click="handleSave"
        type="button"
        :disabled="this.stateSaved"
        class="btn btn--primary js-modal-trigger pull-right sml-push-top"
      >
        Confirm ranking
      </button>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import Focus from "../../directives/focus";
import { shuffle } from "lodash";
import axios from "axios";
import IconBase from "../icons/IconBase.vue";
import IconReorder from "../icons/IconReorder.vue";

export default {
  name: "SurveyRankingInput",
  components: {
    draggable,
    IconBase,
    IconReorder,
  },
  props: {
    id: {},
    survey: {},
    options: {},
    answer: {},
  },
  directives: {
    Focus,
  },
  data() {
    return {
      stateOptions: [],
      stateSaved: false,
    };
  },
  mounted() {
    if (!!this.answer) {
      this.$log.debug(`The respondent has a confirmed ranking for question ${this.id} in the database.`)
      this.stateSaved = true;
      this.stateOptions = this.answer
        .split(",")
        .map((label, id) => ({ label, id }));
    } else {
      this.disableFormSubmit()
      this.stateOptions = shuffle(this.options).map((option, id) => ({
        ...option,
        id,
      }));
    }
    },
    methods: {
    disableFormSubmit() {
      Array.from(this.submitButtons).forEach((el) => {
        this.$log.debug('Disabling form submit.')
        el.setAttribute('disabled', 'disabled')
      });
    },
    enableFormSubmit() {
      Array.from(this.submitButtons).forEach((el) => {
        this.$log.debug('Enabling form submit.')
        el.removeAttribute('disabled');
      });
    },
    handleDrag() {
      this.stateSaved = false;
      this.disableFormSubmit()
    },
    handleSave(e) {
      e.preventDefault();
      this.disableFormSubmit()

      const req = axios.post(`/payer/surveys/${this.survey}/save-question`, {
        questions: { [this.id]: this.response },
      });
      req.then((res) => {
        this.stateSaved = true
        this.enableFormSubmit()
      }).catch((error) => {
        throw new Error(error);
      });
    },
  },
  computed: {
    response() {
      return this.stateOptions.map((option) => option.label).join(",")
    },
    dataName() {
      return `questions[${this.id}]`
    },
    submitButtons() {
      return document.getElementsByClassName('survey-respond-proceed-button')
    }
  },
};
</script>
