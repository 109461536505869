<template>
  <icon-base
    v-if="type === 'Rating'"
    icon-name="rating"
    width="12"
    height="5"
    :viewboxWidth="19"
    :viewboxHeight="6"
    :className="''"
    ><icon-rating
  /></icon-base>
  <icon-base
    v-else-if="type === 'Ranked'"
    icon-name="ranking"
    width="12"
    height="12"
    :viewboxWidth="19"
    :viewboxHeight="19"
    :className="''"
    ><icon-ranking
  /></icon-base>
  <icon-base
    v-else-if="type === 'Table'"
    icon-name="table"
    width="11"
    height="12"
    :viewboxWidth="19"
    :viewboxHeight="20"
    :className="''"
    ><icon-table
  /></icon-base>
  <icon-base
    v-else-if="type === 'Probed'"
    icon-name="probe"
    width="12"
    height="12"
    :viewboxWidth="20"
    :viewboxHeight="20"
    :className="''"
    ><icon-probe
  /></icon-base>
  <icon-base
    v-else-if="type === 'Dropdown'"
    icon-name="dropdown"
    width="11"
    height="12"
    :viewboxWidth="19"
    :viewboxHeight="20"
    :className="''"
    ><icon-dropdown
  /></icon-base>
  <icon-base
    v-else-if="type === 'Radio'"
    icon-name="radio"
    width="11"
    height="12"
    :viewboxWidth="19"
    :viewboxHeight="20"
    :className="''"
    ><icon-radio
  /></icon-base>
  <icon-base
    v-else-if="type === 'TextInput'"
    icon-name="text"
    width="12"
    height="12"
    :viewboxWidth="17"
    :viewboxHeight="17"
    :className="''"
    ><icon-text
  /></icon-base>
  <icon-base
    v-else-if="type === 'TextArea'"
    icon-name="textarea"
    width="12"
    height="11"
    :viewboxWidth="17"
    :viewboxHeight="15"
    :className="''"
    ><icon-text-area
  /></icon-base>
  <icon-base
    v-else-if="type === 'NumberInput'"
    icon-name="number"
    width="11"
    height="12"
    :viewboxWidth="15"
    :viewboxHeight="17"
    :className="''"
    ><icon-number
  /></icon-base>
  <icon-base
    v-else-if="type === 'Checkbox'"
    icon-name="checkbox"
    width="11"
    height="12"
    :viewboxWidth="19"
    :viewboxHeight="20"
    :className="''"
    ><icon-checkbox
  /></icon-base>
</template>

<script>
import IconBase from "../icons/IconBase.vue";
import IconText from "../icons/IconText.vue";
import IconTextArea from "../icons/IconTextArea.vue";
import IconNumber from "../icons/IconNumber.vue";
import IconCheckbox from "../icons/IconCheckbox.vue";
import IconDropdown from "../icons/IconDropdown.vue";
import IconRadio from "../icons/IconRadio.vue";
import IconRating from "../icons/IconRating.vue";
import IconProbe from "../icons/IconProbe.vue";
import IconRanking from "../icons/IconRanking.vue";
import IconTable from "../icons/IconTable.vue";

export default {
  name: "QuestionIcon",
  components: {
    IconBase,
    IconRating,
    IconRanking,
    IconProbe,
    IconDropdown,
    IconRadio,
    IconText,
    IconTextArea,
    IconNumber,
    IconCheckbox,
    IconTable,
  },
  props: {
    type: String,
  },
};
</script>
