const fileUpload = (() => {
  const fileInputs = Array.from(document.querySelectorAll('input[type="file"]'));
  const clearFileBtns = Array.from(document.querySelectorAll('.fileInput__delete'));

  function setInputLabel(input) {
    const fileInput = input.closest('.fileInput');
    const inputLabel = fileInput.querySelector('.fileInput__name');
    const clearFileBtn = fileInput.querySelector('.fileInput__delete');
    const uploadFileBtn = fileInput.querySelector('.fileInput__uploadBtn');

    // Set input label, show 'remove file' button and hide upload button
    inputLabel.textContent = input.files.item(0).name;
    clearFileBtn.style.display = 'flex';
    uploadFileBtn.style.display = 'none';
  }

  function clearFileInput(e) {
    const fileInput = e.closest('.fileInput');
    const inputToClear = fileInput.querySelector('input[type="file"]');
    const inputLabel = fileInput.querySelector('.fileInput__name');
    const clearFileBtn = fileInput.querySelector('.fileInput__delete');
    const uploadFileBtn = fileInput.querySelector('.fileInput__uploadBtn');

    // Looks a bit odd but is needed to provide support for older IE versions
    inputToClear.value = '';
    inputToClear.type = '';
    inputToClear.type = 'file';

    // Clear input label, hide 'remove file' button and show upload button
    inputLabel.textContent = '';
    clearFileBtn.style.display = 'none';
    uploadFileBtn.style.display = 'flex';
  }

  function bindUIEvents() {
    fileInputs.forEach((fileInput) => {
      fileInput.addEventListener('change', setInputLabel.bind(null, fileInput));
    });

    clearFileBtns.forEach((btn) => {
      btn.addEventListener('click', clearFileInput.bind(null, btn));
    });
  }

  function init() {
    bindUIEvents();
  }

  return {
    init,
  };
})();

export default fileUpload;
