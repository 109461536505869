<template>
  <div>
    <input type="text"
           v-model="value"
           @change="updateValue($event.target.value)"
           :placeholder="field.example"
    />
  </div>
</template>

<script>

export default {
  name: 'FieldText',
  data() {
    return {
      'value': null
    };
  },
  mounted() {
  },
  props: {
    field: Object
  },
  methods: {
    updateValue: function (value) {
      this.$emit('input', value);

      if (this.field.refreshRequirementsOnChange) {
        this.$emit('refreshRequirements');
      }
    },
    clearValue: function (event) {
      this.value = null;
    }
  },
};
</script>
