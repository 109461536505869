<template>
  <div>
    <div class="flex flex-align-start sml-negate-padding-sides-half">
      <country-card
        v-for="(country, index) in countriesAdded"
        :key="country.id"
        :country="countriesAdded[index]"
      >
      </country-card>

      <div v-show="this.countriesAdded.length < countries.length">
        <div class="dropdown sml-push-top" v-bind:class="{ 'dropdown--is-visible' : countrySelectorActive }">
          <button type="button" class="btn btn--primary" @click="toggleCountrySelector()"
                  v-click-outside="hideCountrySelector">
            Add country
            <icon-base icon-name="add" width="17" height="20" className="sml-push-right">
              <icon-add/>
            </icon-base>
          </button>
          <div class="dropdown__menu dropdown__menu--left">
            <button type="button" class="dropdown__menu_item" v-for="(country, index) in countries" :key="index"
                    @click="addCountry(country)" v-if="countryIsNotSelected(country)">
              {{ country.name }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="card sml-push-top flex" v-if="countriesAdded.length > 0">
      <div class="card__inner card__inner--info card--border-radius">
        The selected scope will require a maximum of <strong><count :slice="selectedSlice"/></strong> coins per question
      </div>
    </div>
  </div>
</template>

<script>
  import ClickOutside from 'vue-click-outside';
  import IconBase from '../icons/IconBase.vue';
  import IconAdd from '../icons/IconAdd.vue';
  import CountryCard from './CountryCard.vue';
  import Count from './Count.vue';

  export default {
    name: 'Matrix',
    props: {
      countries: Array,
      config: Array,
      configClinical: Array,
    },
    components: {
      IconBase,
      IconAdd,
      Count,
      CountryCard,
    },
    directives: {
      ClickOutside,
    },
    data() {
      return {
        countrySelectorActive: false
      };
    },
    mounted() {
      if (!this.config) return;
      this.initState();
    },
    computed: {
      selectedSlice() {
        return this.$store.state.matrix.selectedSlice;
      },
      countriesAdded() {
        return this.$store.state.matrix.countriesAdded;
      },
    },
    methods: {
      toggleCountrySelector() {
        this.countrySelectorActive = !this.countrySelectorActive;
      },
      hideCountrySelector() {
        this.countrySelectorActive = false;
      },
      addCountry(country) {
        this.$store.commit({
          type: 'addCountry',
          country,
        });
      },
      countryIsNotSelected(selectedCountry) {
        // Invert the result for use in v-if
        return !this.countriesAdded.some(country => country.name === selectedCountry.name);
      },
      addRespondentType(type = 'payers', entries) {
        return entries.map(item => Object.assign({}, item, {
          respondentType: type,
        }));
      },
      initState() {
        // Combine clinical ('other') and 'payers', but add
        // a property which we can segregate based on later.
        const config = [].concat(
          this.addRespondentType('payers', this.config),
          this.addRespondentType('others', this.configClinical),
        );

        this.$store.commit({
          type: 'setInitialCountryState',
          config,
        });

        config.forEach((row) => {
          // What country is this for? Has it been added?
          let requestedCountry = this.countries.find((c) => {
            return c.id === row.country_id;
          });

          let addedCountry = this.$store.state.matrix.countriesAdded.find((c) => {
            return c.id === row.country_id;
          });

          if (addedCountry === undefined) {
            this.$store.commit({
              type: 'addCountry',
              country: requestedCountry
            });
          }
        });
      }
    },
  };
</script>

<style scoped>
  .dropdown {
    align-self: flex-start;
    margin-left: 10px;
  }
</style>
