<template>
  <div
    class="dropdown"
    v-bind:class="{ 'dropdown--is-visible': questionSelectorActive }"
  >
    <slot
      name="addQuestionButton"
      :toggleQuestionSelector="toggleQuestionSelector"
      :hideQuestionSelector="hideQuestionSelector"
    >
      <div
        class="btn btn--secondary"
        v-on:click="toggleQuestionSelector"
        v-click-outside="hideQuestionSelector"
      >
        Add question
        <icon-base
          icon-name="add"
          width="17"
          height="20"
          className="sml-push-right"
          ><icon-add
        /></icon-base>
      </div>
    </slot>
    <div class="dropdown__menu dropdown__menu--left">
      <a class="dropdown__menu_item" @click.prevent="addField('TextInput')">
        <icon-base icon-name="text" width="17" height="7"
          ><icon-text
        /></icon-base>
        Short text
      </a>
      <a class="dropdown__menu_item" @click.prevent="addField('TextArea')">
        <icon-base icon-name="text-area" width="17" height="15"
          ><icon-text-area
        /></icon-base>
        Long text
      </a>
      <a class="dropdown__menu_item" @click.prevent="addField('NumberInput')">
        <icon-base icon-name="number" width="15" height="17"
          ><icon-number
        /></icon-base>
        Number
      </a>
      <a class="dropdown__menu_item" @click.prevent="addField('Checkbox')">
        <icon-base icon-name="checkbox" width="19" height="20"
          ><icon-checkbox
        /></icon-base>
        Checkbox
      </a>
      <a class="dropdown__menu_item" @click.prevent="addField('Radio')">
        <icon-base icon-name="radio" width="19" height="20"
          ><icon-radio
        /></icon-base>
        Multiple choice
      </a>
      <a class="dropdown__menu_item" @click.prevent="addField('Dropdown')">
        <icon-base icon-name="dropdown" width="19" height="20"
          ><icon-dropdown
        /></icon-base>
        Dropdown
      </a>
      <a class="dropdown__menu_item" @click.prevent="addField('Rating')">
        <icon-base icon-name="rating" width="19" height="6"
          ><icon-rating
        /></icon-base>
        Rating
      </a>
      <a class="dropdown__menu_item" @click.prevent="addField('Probed')">
        <icon-base icon-name="probe" width="20" height="20"
          ><icon-probe
        /></icon-base>
        Probed
      </a>
      <a class="dropdown__menu_item" @click.prevent="addField('Ranked')">
        <icon-base icon-name="ranking" width="19" height="19"
          ><icon-ranking
        /></icon-base>
        Ranking
      </a>
      <a class="dropdown__menu_item" @click.prevent="addField('Table')">
        <icon-base icon-name="ranking" width="19" height="20"
          ><icon-table
        /></icon-base>
        Table
      </a>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import IconBase from "../icons/IconBase.vue";
import IconTrash from "../icons/IconTrash.vue";
import IconAdd from "../icons/IconAdd.vue";
import TextInput from "../question_types/TextInput";
import IconText from "../icons/IconText.vue";
import TextArea from "../question_types/TextArea";
import IconTextArea from "../icons/IconTextArea.vue";
import NumberInput from "../question_types/NumberInput";
import Ranked from "../question_types/RankingInput";
import IconNumber from "../icons/IconNumber.vue";
import Checkbox from "../question_types/Checkbox";
import IconCheckbox from "../icons/IconCheckbox.vue";
import Dropdown from "../question_types/Dropdown";
import IconDropdown from "../icons/IconDropdown.vue";
import Radio from "../question_types/Radio";
import Probed from "../question_types/Probed";
import IconRadio from "../icons/IconRadio.vue";
import Rating from "../question_types/Rating";
import IconRating from "../icons/IconRating.vue";
import IconProbe from "../icons/IconProbe.vue";
import IconChevronDown from "../icons/IconChevronDown.vue";
import IconChevronUp from "../icons/IconChevronUp.vue";
import IconDrag from "../icons/IconDrag.vue";
import IconTable from "../icons/IconTable.vue";
import IconRanking from "../icons/IconRanking.vue";
import Table from "../question_types/Table";

export const ROOT_SURVEY_PARENT = "root_survey_parent";

export default {
  name: "QuestionDropdown",
  components: {
    IconBase,
    IconTrash,
    IconAdd,
    TextInput,
    IconText,
    TextArea,
    IconTextArea,
    NumberInput,
    IconNumber,
    Checkbox,
    IconCheckbox,
    Dropdown,
    IconDropdown,
    Radio,
    Probed,
    IconRadio,
    Rating,
    IconRating,
    IconProbe,
    IconChevronDown,
    IconChevronUp,
    Ranked,
    IconDrag,
    Table,
    IconTable,
    IconRanking,
  },
  directives: {
    ClickOutside,
  },
  props: {
    updateSurvey: Function,
  },
  data() {
    return {
      questionSelectorActive: false,
      questionSwitcherActive: null,
      questionMoved: null,
    };
  },
  methods: {
    toggleQuestionSelector() {
      this.questionSelectorActive = !this.questionSelectorActive;
    },
    hideQuestionSelector() {
      this.questionSelectorActive = false;
    },
    addField(type) {
      this.$store.commit({
        type: "addField",
        questionType: type,
        pageNumber: this.pageNumber,
        parentUID: this.parentUID,
      });

      const page = this.$store.state.survey.pages[this.pageNumber]
      const questions = this.$store.state.survey.pages[this.pageNumber].questions
      const question = questions[questions.length-1]

      this.$emit('fieldAdded');
    },
  },
  computed: {
    pageNumber() {
      return this.$store.state.survey.selected.page.pageNumber;
    },
    parentUID() {
      return this.$store.state.survey.selected.page.uid;
    },
    rootUID() {
      return ROOT_SURVEY_PARENT;
    },
  },
};
</script>
