<template>
  <svg xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="`0 0 ${viewboxWidth === null ? width : viewboxWidth} ${viewboxHeight === null ? height : viewboxHeight}`"
    :aria-labelledby="iconName"
    :class="className"
    role="presentation"
  >
    <g :fill="iconColor">
      <slot />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
    },
    iconColor: {
      type: String,
      default: 'currentColor',
    },
    viewboxWidth: {
      type: Number,
      default: null,
    },
    viewboxHeight: {
      type: Number,
      default: null,
    },
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    className: {
      type: [String],
      default: 'sml-push-left',
    }
  },
};
</script>
