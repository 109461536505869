<template>
    <tbody>
        <tr v-for="(row, rowIndex) in options[0].rows" v-bind:key="rowIndex">
            <td class="table-input__label">{{ row.label || row }}</td>
            <td v-for="(column, colIndex) in options[0].columns" v-bind:key="`row-${rowIndex}-col-${colIndex}`">
                <label class="table-input__label">
                    <input type="number" v-on:change="updateTextValue(rowIndex)"
                        :name="`question-${questionId}-row-${rowIndex}-col-${colIndex}`"
                        v-model="stateRows[rowIndex].columns[colIndex].value" />
                </label>
            </td>
        </tr>
    </tbody>
</template>

<script>
import { find } from "lodash";
export default {
    name: "SurveyTableNumberInput",
    props: {
        options: {},
        answer: {},
        questionId: ""
    },
    data() {
        return { stateRows: [] };
    },
    methods: {
        updateTextValue(rowIndex) {
            const current = this.stateRows[rowIndex];

            const textValue = "(" + current.columns.map((col, index) => {
                const colName = this.options[0].columns[index];
                return `${colName}: ${col.value}`;
            }).join(", ") + ")"

            this.$set(this.stateRows[rowIndex], "textValue", textValue)
            this.updateParent();
        },
        updateParent() {
            this.$emit("update", {stateRows: this.stateRows, hasAnswer: this.hasAnswer});
        }
    },
    created() {
        const answer = this.answer;
        this.stateRows = this.options[0].rows.map((row, index) => {
            const prevAnswer = find(answer, { index: index });
            return {
                index,
                row,
                columns: prevAnswer?.columns || this.options[0].columns.map((c) => { return { value: null } }),
                textValue: prevAnswer?.textValue ?? "N/A"
            };
        });
        this.updateParent();
    },
    computed: {
        hasAnswer() {
            return this.stateRows.some(x=> x.columns.some(y=> y.value !== null && y.value !== ""));
        }
    }
};
</script>
