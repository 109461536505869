<template>
  <div class="checkboxQuestion">
    <div class="form__input_actions flex flex-align-center flex-between">
      <div class="flex flex-align-center">
        <div class="flex flex-align-center">
          <icon-base icon-name="checkbox" width="19" height="20">
            <icon-checkbox />
          </icon-base>
          <span class="form__input_type"> {{ displayNumber }}. Checkbox </span>
        </div>
        <div>
          <slot name="changeFieldType"></slot>
        </div>
      </div>
      <div class="flex flex-align-center">
        <slot name="hiddenQuestionInputs"></slot>
        <slot name="requiredField"></slot>
        <slot name="removeField"></slot>
      </div>
    </div>
    <question-label
    :pageNumber="pageNumber"
    :questionNumber="questionNumber"
    >

    </question-label>
    <question-options
      :pageNumber="pageNumber"
      :questionNumber="questionNumber"
    >
    </question-options>
    <div class="flex sml-push-top sml-negate-padding-sides-half">
      <div class="sml-padding-sides-half">
        <label>Min number of selections</label>
        <input type="number" placeholder="Optional"
          :name="`pages[${this.pageNumber}][questions][${this.questionNumber}][config][minSelections]`"
          :data-vv-name="`pages.${this.pageNumber}.questions.${this.questionNumber}.config.minSelections`"
          data-vv-as="minimum selections" :class="minSelectionHasErrors() ? 'is-invalid' : ''"
          v-model.number="minSelections"
          v-validate="{ max_value: this.minSelectionMax() }"
          min="0"
          :max="this.minSelectionMax()" />
      </div>
      <div class="sml-padding-sides-half">
        <label>Max number of selections</label>
        <input type="number" placeholder="Optional"
          :name="`pages[${this.pageNumber}][questions][${this.questionNumber}][config][maxSelections]`"
          :data-vv-name="`pages.${this.pageNumber}.questions.${this.questionNumber}.config.maxSelections`"
          data-vv-as="maximum selections" :class="maxSelectionHasErrors() ? 'is-invalid' : ''"
          v-model.number="maxSelections" v-validate="{ max_value: optionLabels.length }"
          min="0"
          :max="optionLabels.length" />
      </div>
    </div>
    <small v-if="maxSelectionHasErrors()" class="danger">
      {{ errors.first(this.maxValueValidationName) }}
    </small>
    <small v-if="minSelectionHasErrors()" class="danger">
      {{ errors.first(this.minValueValidationName) }}
    </small>
    <div>
      <slot name="moveFieldUp"></slot>
      <slot name="moveFieldDown"></slot>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import Focus from "../../directives/focus";
import IconBase from "../icons/IconBase.vue";
import IconCheckbox from "../icons/IconCheckbox.vue";
import IconReorder from "../icons/IconReorder.vue";
import IconCross from "../icons/IconCross.vue";
import IconPlus from "../icons/IconPlus.vue";
import QuestionLabel from "./QuestionLabel.vue";
import QuestionOptions from "./QuestionOptions.vue"
import { setMinOptions, setMaxOptions } from "./QuestionTypesUtilityFunctions";

export default {
  name: "Checkbox",
  components: {
    draggable,
    IconBase,
    IconCheckbox,
    IconReorder,
    IconCross,
    IconPlus,
    QuestionLabel,
    QuestionOptions
  },
  props: {
    pageNumber: Number,
    questionNumber: Number,
    displayNumber: String | Number,
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  inject: ["$validator"],
  directives: {
    Focus,
  },
  data() {
    return {
      minValueValidationName: `pages.${this.pageNumber}.questions.${this.questionNumber}.config.minSelections`,
      maxValueValidationName: `pages.${this.pageNumber}.questions.${this.questionNumber}.config.maxSelections`,
    };
  },
  methods: {
    setMinOptions,
    setMaxOptions,
    minSelectionHasErrors() {
      return this.errors.has(this.minValueValidationName)
    },
    maxSelectionHasErrors() {
      return this.errors.has(this.maxValueValidationName)
    },
    minSelectionMax() {
      if (this.maxSelections && this.maxSelections < this.optionLabels.length) {
        return this.maxSelections;
      }
      return this.optionLabels.length;
    },
  },
  computed: {
    config() {
      return this.$store.getters.config(this.pageNumber, this.questionNumber);
    },
    optionLabels() {
      return this.config.options
    },
    minSelections: {
      get() {
        return this.config.minSelections
      },
      set(value) {
        this.setMinOptions(this.pageNumber, this.questionNumber, value)
      }
    },
    maxSelections: {
      get() {
        return this.config.maxSelections
      },
      set(value) {
        this.setMaxOptions(this.pageNumber, this.questionNumber, value)
      }
    },
  },
};
</script>
