<template>
  <div class="table">
    <table class="table--default">
      <thead>
        <tr>
          <th
            v-if="handleSelectAll"
            class="check-cell"
            @click="handleSelectAll"
          >
            <!-- <div class="check" :class="{ selected: allSelected }"></div> -->
          </th>
          <th v-for="(headCell, index) in headCells" :key="index">
            {{ headCell }}
          </th>
        </tr>
      </thead>
      <div v-if="loading">
        <div class="spinner spinner--table">
          <div class="rect1"></div>
          <div class="rect2"></div>
          <div class="rect3"></div>
          <div class="rect4"></div>
          <div class="rect5"></div>
        </div>
      </div>
      <tbody v-else>
        <tr
          v-for="(item, index) in items"
          :key="index"
          v-bind:class="{ 'row--excluded': item.excluded }"
        >
          <TableCell
            v-for="(cell, index) in item.cells"
            :key="index"
            :data="cell"
            :selected="cell.selected"
          >
          </TableCell>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import TableCell from "./TableCell.vue";

export default {
  name: "Table",
  components: {
    TableCell,
  },
  props: {
    loading: Boolean,
    sector: {},
    headCells: Array,
    items: [],
    allSelected: Boolean,
    handleSelectAll: Function,
    isSelected: {},
  },

  updated() {},
};
</script>
