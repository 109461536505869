<template>
  <div id="user-sector-selector">
    <label class="required" v-text="label" v-bind:class="{ required: required }"></label>
    <div v-if="reducedSectors.length">
      <div
        style="visibility: hidden; position: absolute; height: 0"
        v-for="(selected, index) in reducedSectors"
        :key="`selected--${index}`"
      >
        <select :name="name" v-if="selected" v-model="selected.id">
          <option :value="null">Please select</option>
          <optgroup
            v-for="(group, index) in options"
            :label="'\xa0'.repeat(4 * group.level) + group.text"
            :key="index"
          >
            <option
              v-for="(item, index) in group.items"
              :value="item.value"
              :key="index"
              v-bind:data-display="`${item.country} - ${item.group} - ${item.text}`"
            >
              {{ "&nbsp;".repeat(4 * group.level) }}{{ item.text }}
            </option>
          </optgroup>
        </select>
      </div>
    </div>

    <div
      class="mb-2 user-sector__dropdown"
      v-for="(stateSelectedSector, countryIndex) in stateSelectedSectors"
      :key="countryIndex"
    >
      <div>
        <label>Country</label>
        <select v-on:change="updateCountry" :data-country-index="countryIndex">
          <option :value="null">Please select country</option>
          <option
            v-for="(country, index) in availableCountries"
            :key="`country--${index}`"
            :value="country.uid"
            :selected="stateSelectedSector.uid === country.uid"
          >
            {{ country.name }}
          </option>
        </select>
        <div class="sml-push-top-half">
          <button type="button" class="btn btn--secondary" @click="addCountry">
            Add another country
          </button>
        </div>
      </div>
      <div
        v-for="(locality, localityIndex) in stateSelectedSectors[countryIndex]
          .localities"
        :key="localityIndex"
        class="user-sector__dropdown-sub"
      >
        <!-- Locality selector -->
        <div>
          <label v-if="localityIndex === 0">Locality</label>
          <select
            :data-country-index="countryIndex"
            :data-locality-index="localityIndex"
            v-on:change="updateLocality"
          >
            <option :value="null">Please select locality</option>
            <option
              v-for="(item, itemIndex) in availableLocalities(
                stateSelectedSector.uid
              )"
              :key="itemIndex"
              :value="item.uid"
              :selected="item.uid === locality.uid"
            >
              {{ item.name }}
            </option>
          </select>
          <div class="sml-push-top-half mb-2">
            <button
              v-if="
                stateSelectedSectors[countryIndex].localities &&
                stateSelectedSectors[countryIndex].localities.length <
                  availableLocalities(stateSelectedSector.uid).length &&
                localityIndex ===
                  stateSelectedSectors[countryIndex].localities.length - 1
              "
              type="button"
              class="btn btn--secondary"
              @click="addLocality(countryIndex)"
            >
              Add another locality
            </button>
          </div>
        </div>
        <!-- Sector selectors -->
        <div v-if="locality.sectors.length">
          <div
            v-for="(sector, sectorIndex) in locality.sectors"
            :key="sector.uid"
          >
            <label v-if="localityIndex === 0 && sectorIndex === 0"
              >Sector</label
            >
            <select
              :data-country-index="countryIndex"
              :data-locality-index="localityIndex"
              :data-sector-index="sectorIndex"
              v-on:change="updateSector"
            >
              <option :value="null">Please select sector</option>
              <option
                v-for="(item, itemIndex) in computedSectors(
                  countryIndex,
                  localityIndex
                )"
                :key="itemIndex"
                :value="item.uid"
                :selected="item.uid === sector.uid"
              >
                {{ item.name }}
              </option>
            </select>
            <div class="sml-push-top-half mb-2">
              <button
                v-if="
                  locality.sectors &&
                  locality.sectors.length <
                    computedSectors(countryIndex, localityIndex).length &&
                  sectorIndex === locality.sectors.length - 1
                "
                type="button"
                class="btn btn--secondary"
                @click="addSector(countryIndex, localityIndex)"
              >
                Add another sector
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import IconBase from "../../icons/IconBase.vue";
import IconDelete from "../../icons/IconDelete.vue";
import short from "short-uuid";
import { find } from "lodash";

export default {
  name: "UserSectorSelector",
  components: {
    IconBase,
    IconDelete,
  },
  props: {
    label: {
      type: String,
      default: "User sector",
    },
    addButtonLabel: {
      type: String,
      default: "Add another user sector",
    },
    name: {
      type: String,
      default: "sectors[]",
    },
    availableSectors: {
      required: true,
      type: Object,
    },
    selectedSectors: {
      required: true,
      type: Array,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      stateSelectedSectors: [],
      customLabel: [],
    };
  },
  computed: {
    availableCountries() {
      // console.log("avail", this.availableSectors);
      return Object.keys(this.availableSectors).map((key, id) => {
        return {
          name: key,
          uid: short.generate(),
          localities: Object.keys(this.availableSectors[key]).map(
            (locality, id) => {
              return {
                name: locality,
                uid: short.generate(),
                sectors: Object.keys(this.availableSectors[key][locality]).map(
                  (sector) => {
                    return {
                      uid: short.generate(),
                      id: sector,
                      name: this.availableSectors[key][locality][sector],
                    };
                  }
                ),
              };
            }
          ),
        };
      });
    },

    options() {
      let options = [];
      Object.keys(this.availableSectors).forEach((countryName) => {
        options.push({ level: 0, text: countryName, items: [] });
        Object.keys(this.availableSectors[countryName]).forEach((levelName) => {
          options.push({
            level: 1,
            text: levelName,
            items: _.map(
              this.availableSectors[countryName][levelName],
              (name, id) => {
                return {
                  value: parseInt(id),
                  text: name,
                  group: levelName,
                  country: countryName,
                };
              }
            ),
          });
        });
      });
      return options;
    },
    reducedSectors() {
      return this.stateSelectedSectors
        ?.map((country) =>
          country.localities?.map((locality) => locality.sectors).flat()
        )
        .flat();
    },
  },

  created() {
    let countries = [];
    this.availableCountries.forEach((country) => {
      let localities = [];
      country.localities.forEach((locality) => {
        let sectors = [];
        locality.sectors.forEach((sector) => {
          if (
            this.selectedSectors
              .map((item) => item.id)
              .includes(parseInt(sector.id))
          )
            sectors.push(sector);
        });
        if (sectors.length) localities.push({ ...locality, sectors });
      });
      if (localities.length) countries.push({ ...country, localities });
    });
    this.stateSelectedSectors = countries.length ? countries : [{ id: null }];
  },
  updated() {},
  methods: {
    availableLocalities(country) {
      const data = find(this.availableCountries, { uid: country });
      return data.localities;
    },
    computedSectors(countryIndex, localityIndex) {
      const localityId =
        this.stateSelectedSectors[countryIndex].localities[localityIndex].uid;
      const sectors = this.availableCountries
        .filter(
          (country) =>
            country.uid === this.stateSelectedSectors[countryIndex].uid
        )[0]
        .localities.filter(
          (locality) => locality.uid === localityId
        )[0].sectors;

      return sectors;
    },
    updateCountry(e) {
      const index = e.target.dataset.countryIndex;
      this.stateSelectedSectors.splice(index, 1, {
        ...this.stateSelectedSectors[index],
        uid: e.target.value,
        localities: this.stateSelectedSectors[index].localites || [
          { id: null, sectors: [] },
        ],
      });
    },
    updateLocality(e) {
      const countryIndex = e.target.dataset.countryIndex;
      const localityIndex = e.target.dataset.localityIndex;
      const country = this.availableCountries.filter(
        (country) => country.uid === this.stateSelectedSectors[countryIndex].uid
      )[0];
      // country.name
      const locality = country.localities.filter(
        (locality) => locality.uid === e.target.value
      )[0];

      this.stateSelectedSectors[countryIndex].localities.splice(
        localityIndex,
        1,
        { uid: e.target.value, sectors: [locality.sectors[0]] }
      );
    },
    updateSector(e) {
      const countryIndex = e.target.dataset.countryIndex;
      const localityIndex = e.target.dataset.localityIndex;
      const sectorIndex = e.target.dataset.sectorIndex;

      const country = this.availableCountries.find((i) => i.uid == this.stateSelectedSectors[countryIndex].uid );
      const locality = country.localities.find((i) => i.uid == this.stateSelectedSectors[countryIndex].localities[localityIndex].uid);
      const sector = locality.sectors.find((i) => i.uid == e.target.value);

      this.stateSelectedSectors[countryIndex].localities[
        localityIndex
      ].sectors.splice(sectorIndex, 1, sector);
    },
    updateLabel(e, index) {
      let arr = this.customLabel;
      arr.splice(
        index,
        1,
        e.target.options[e.target.selectedIndex].dataset.display
      );
      this.customLabel = arr;
    },

    addSector(countryIndex, localityIndex) {
      this.stateSelectedSectors[countryIndex].localities[
        localityIndex
      ].sectors.push({
        uid: short.generate(),
        sectors: [],
      });
    },
    addLocality(countryIndex) {
      this.stateSelectedSectors[countryIndex].localities.push({
        uid: short.generate(),
        sectors: [],
      });
    },
    addCountry() {
      this.stateSelectedSectors.push({ id: null, localities: [] });
    },
    deleteSector(index) {
      if (this.stateSelectedSectors.length > 1) {
        this.stateSelectedSectors.splice(index, 1);
      }
    },
  },
};
</script>

<style scoped></style>
