<template>
  <div>
    <div class="sml-push-top">
      <label v-text="label" class="required"></label>
      <select v-bind="selectProps" @change="handleChange" class="required">
        <option :value="null">Please select</option>
        <option
          v-for="item in options"
          v-bind:key="item"
          :value="item"
          :selected="item === defaultValue || item === 'Other (text)' && stateCustom"
        >
          {{ item }}
        </option>
      </select>
    </div>
    <div v-if="stateCustom" class="sml-push-top">
      <label class="required">Enter role in organisation</label>
      <input
        v-bind:value="defaultValue"
        id="organisation_role"
        name="organisation_role"
        type="text"
        class="required"
      />
    </div>
  </div>
</template>

<script>
import IconBase from "../../icons/IconBase.vue";
import IconDelete from "../../icons/IconDelete.vue";

export default {
  name: "UserOrganisationRoleInput",
  components: {
    IconBase,
    IconDelete,
  },
  props: {
    label: {
      type: String,
      default: "Role in organisation",
    },
    name: {
      type: String,
      default: "organisation_role",
    },
    defaultValue: {
      type: String,
    },
    customRole: {
      type: String,
      default: "Test",
      // required: true,
    },
    options: {
      type: Array,
      default: () => ["Medical Director", "Pharmacy Director", "Other (text)"],
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      stateDefaultValue: null,
      stateCustom: false,
    };
  },
  computed: {
    selectProps() {
      if (this.stateCustom) return null;
      else return { id: "organisation_role", name: "organisation_role" };
    },
  },
  created() {
    if (this.defaultValue && !this.options.includes(this.defaultValue))
      this.stateCustom = true;
  },
  methods: {
    handleChange(e) {
      if (e.target.value === "Other (text)") this.stateCustom = true;
      else this.stateCustom = false;
    },
  },
};
</script>

<style scoped></style>
