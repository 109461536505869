const additionalUserFields = (() => {
  const conditionalSelects = Array.from(
    document.querySelectorAll(".user-conditional-select")
  );

  const conditionalCheckboxes = Array.from(
    document.querySelectorAll(".user-conditional-checkbox")
  );

  const conditionalDate = document.querySelector(".user-conditional-date");

  const customOrganisationRole = document.querySelector(
    ".organisationRoleSelect"
  );

  function showAppropriateFields(inputEl) {
    const getCurrentValue = (input) => {
      switch (input.tagName) {
        case "SELECT":
          return input.options[input.selectedIndex].text.toLowerCase();
        case "INPUT":
          if (input.type === "checkbox") return input.checked.toString();
          return input.value;
        default:
          return input.value;
      }
    };

    const currentValue = getCurrentValue(inputEl);

    const targetSections = Array.from(
      document.querySelectorAll(`[data-conditional="${inputEl.id}"]`)
    );

    if (!targetSections.length) return;

    const setInput = (elem, value) => {
      if (elem.getAttribute("data-conditional-value") === value) {
        elem.style.display = "block";

        Array.from([
          ...elem.querySelectorAll("input.required"),
          ...elem.querySelectorAll("select.required"),
        ]).forEach((requiredInput) => {
          requiredInput.disabled = false;
          requiredInput.required = true;
        });

        // disabled nested conditional els
        Array.from(elem.querySelectorAll("[data-conditional]")).forEach(
          (nestedElem) => {
            nestedElem.style.display = "none";
            const nestedElemInput = document.querySelector(
              `#${nestedElem.getAttribute("data-conditional")}`
            );
            setInput(nestedElem, getCurrentValue(nestedElemInput));
          }
        );
      } else {
        elem.style.display = "none";
        Array.from([
          ...elem.querySelectorAll("input.required"),
          ...elem.querySelectorAll("select.required"),
        ]).forEach((requiredInput) => {
          requiredInput.disabled = true;
          requiredInput.required = false;
        });
      }
    };

    targetSections.forEach((elem) => {
      setInput(elem, currentValue);
    });
  }

  function validateDate(el) {
    const yearEl = el.querySelector(".startingYear");
    const monthEl = el.querySelector(".startingMonth");
    const today = new Date(Date.now());

    if (parseInt(yearEl.value, 10) === today.getFullYear()) {
      monthEl.max = today.getMonth();
    } else monthEl.max = 12;
  }

  function init() {
    if (conditionalSelects.length) {
      conditionalSelects.forEach((select) => {
        select.addEventListener("change", () => showAppropriateFields(select));
        showAppropriateFields(select);
      });
    }

    if (conditionalCheckboxes.length) {
      conditionalCheckboxes.forEach((input) => {
        input.addEventListener("change", () => showAppropriateFields(input));
        showAppropriateFields(input);
      });
    }

    if (conditionalDate) {
      conditionalDate.querySelector("input").addEventListener("change", () => {
        validateDate(conditionalDate);
      });
    }

    if (customOrganisationRole) {
      const organisationRole = document.querySelector(".organisationRole");
      customOrganisationRole.addEventListener("change", () => {
        const selected =
          customOrganisationRole.options[customOrganisationRole.selectedIndex]
            .text;
        if (selected.toLowerCase() === "other (text)") {
          organisationRole.value = null;
          organisationRole.required = true;
        } else {
          organisationRole.value = selected;
        }
      });
    }
  }

  return {
    init,
  };
})();

export default additionalUserFields;
