<template>
  <div class="sml-push-top">
    <div v-if="!stateCustom">
      <button type="button" class="btn btn--secondary" @click="handleEntry">
        Add new {{ type }}
      </button>
    </div>

    <div v-if="stateCustom" class="sml-push-top">
      <div class="admin-custom-currency-selector__actions">
        <div>
        <label class="required"
          :for="currencyCodeName">Enter {{ type }}</label>
        <input
          v-bind:value="defaultCodeValue"
          :id="currencyCodeName"
          :name="currencyCodeName"
          type="text"
          placeholder="ISO 4217 Currency Code"
          required
        />
        </div>

        <div>
        <label class="required"
          :for="perPaymentName">Per Payment Value</label>
        <input
          v-bind:value="defaultPerPaymentValue"
          :id="perPaymentName"
          :name="perPaymentName"
          type="number"
          placeholder="Per Payment Value"
          required
        />
        </div>

        <button class="btn btn--secondary" @click="cancelEntry">Cancel</button>
        <button class="btn btn--primary">Save</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminCustomCurrencySelector",
  props: {
    label: {
      type: String,
    },
    currencyCodeName: {
      type: String,
      default: "code",
    },
    perPaymentName: {
      type: String,
      default: "per_payment",
    },
    type: {},
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      stateDefaultCodeValue: null,
      stateDefaultPerPaymentValue: 0,
      stateCustom: false,
    };
  },
  computed: {},
  created() {},
  methods: {
    handleEntry() {
      this.stateCustom = true;
    },
    cancelEntry() {
      this.stateCustom = false;
    },
  },
};
</script>

<style scoped>
.admin-custom-currency-selector__actions {
  display: flex;
  gap: 2rem;
}
</style>
