<template>
  <div class="sb__question-inner animated fadeIn">
    <div
      slot="pageTitleAndDescription"
      :key="`pageInfo${selected.page.pageNumber}`"
    >
      <input
        type="text"
        placeholder="Page title"
        class="sml-push-top-half"
        :class="
          errors.has(`pages.${selected.page.pageNumber}.name`)
            ? 'is-invalid'
            : ''
        "
        :name="`pages[${selected.page.pageNumber}][name]`"
        v-model="selected.page.name"
        v-validate="{ required: true }"
        data-vv-as="page title"
        :data-vv-name="`pages.${selected.page.pageNumber}.name`"
        :readonly="disabled"
        @input="updatePageTitle"
        v-focus
      />
      <small
        v-show="errors.has(`pages.${selected.page.pageNumber}.name`)"
        class="danger"
      >
        <strong>{{
          errors.first(`pages.${selected.page.pageNumber}.name`)
        }}</strong>
      </small>

      <textarea
        v-validate="{ max: 100000 }"
        data-vv-validate-on="input"
        :data-vv-name="`pages.${selected.page.pageNumber}.description`"
        data-vv-as="page description"
        placeholder="Page description"
        rows="6"
        class="sml-push-top-half"
        :name="`pages[${selected.page.pageNumber}][description]`"
        v-model="selected.page.description"
        :readonly="disabled"
        @input="updatePageDescription"
      ></textarea>
      <small
        v-show="errors.has(`pages.${selected.page.pageNumber}.description`)"
        class="danger"
      >
        <strong>{{
          errors.first(`pages.${selected.page.pageNumber}.description`)
        }}</strong>
      </small>
    </div>

    <div slot="hiddenPageInputs">
      <input
        type="hidden"
        :value="`${selected.page.questions.length}`"
        :name="`pages[${selected.page.pageNumber}][num_questions]`"
        :data-vv-name="`pages.${selected.page.pageNumber}.num_questions`"
        v-validate="'num_questions'"
      />
    </div>
  </div>
</template>

<script>
import Focus from "../../directives/focus";
import IconBase from "../icons/IconBase.vue";
import IconDelete from "../icons/IconDelete.vue";

export default {
  name: "SurveyPageEditor",
  inject: ["$validator"],
  components: { IconDelete, IconBase },
  props: {
    disabled: Boolean,
  },
  directives: {
    Focus,
  },
  created() {},
  methods: {
    handleRemovePage(page) {
      if (window.confirm("Are you sure you would like to delete this page?")) {
        this.$store.commit({
          type: "removePage",
          page: {
            index: page.pageNumber,
          },
        });
      }
    },
    updatePageTitle(e) {
      this.$store.commit({
        type: "updatePageTitle",
        pageNumber: this.currentPage,
        val: e.target.value,
      });
    },
    updatePageDescription(e) {
      this.$store.commit({
        type: "updatePageDescription",
        pageNumber: this.currentPage,
        val: e.target.value,
      });
    },
  },
  computed: {
    selected() {
      return this.$store.state.survey.selected;
    },
  },
};
</script>
