var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"respondent-back-btn"},[_c('a',{attrs:{"href":("/admin/survey/" + (_vm.survey.uuid) + "/distribution")}},[_vm._v("← Back to distribution")])]),_vm._v(" "),_c('div',{staticClass:"respondent-header"},[_c('div',{staticClass:"respondent-overview"},[_c('div',[_c('h3',{staticClass:"profile__title"},[_vm._v("Country")]),_vm._v(" "),_c('h4',[_vm._v(_vm._s(_vm.sector.level.country.name))])]),_vm._v(" "),_c('div',[_c('h3',{staticClass:"profile__title"},[_vm._v("Level")]),_vm._v(" "),_c('h4',[_vm._v(_vm._s(_vm.sector.level.name))])]),_vm._v(" "),_c('div',[_c('h3',{staticClass:"profile__title"},[_vm._v("Sector")]),_vm._v(" "),_c('h4',[_vm._v(_vm._s(_vm.sector.name))])])]),_vm._v(" "),_c('div',{staticClass:"respondent-allocation"},[_c('div',{staticClass:"respondent-allocation__total"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"respondent-allocation__total-requested",attrs:{"title":"Requested"}},[_c('span',[_vm._v(" "+_vm._s(_vm.totalAllocations)+" / "+_vm._s(_vm.requiredAllocations))]),_vm._v(" "),_c('span',{staticClass:"respondent-allocation__total-allocated",style:(("width:" + (_vm.totalAllocations / (_vm.requiredAllocations / 100)) + "%"))})])]),_vm._v(" "),_c('button',{staticClass:"btn btn--primary submit-survey",on:{"click":_vm.handleAllocation}},[_vm._v("\n        Save allocation\n      ")])])]),_vm._v(" "),_c('UserDatabase',{attrs:{"handleSubmitSelected":_vm.handleAllocation,"updateSelectedItems":_vm.updateSelectedItems,"selected":_vm.allocatedRespondents,"selectedStatuses":_vm.allocatedRespondentsStatuses,"previouslySelected":_vm.previouslyAllocated,"filters":_vm.filters,"defaultFilters":{
      country: this.sector.level.country.name,
      locality: this.sector.level.name,
      sector: this.sector.name,
    },"columns":[
      {
        type: 'checkbox',
        //action: (item) => handleSelect(item),
      },
      {
        label: 'Name',
        value: function (item) { return item.name; },
        type: 'link',
        action: function (item) { return ("/admin/user/profile/" + (item.id) + "/"); },
        // TODO - implement proper JS redirect on profile page
        // action: `/admin/user/profile/${item.id}/?return=/admin/survey/${survey.uuid}/distribution/allocate/${sector.id}`,
      },
      {
        label: 'Role',
        value: function (item) { return item.role || '-'; },
        hidden:
          'Cannot be selected. This respondent was selected in a previous version of this survey',
      },
      {
        label: 'Status',
        value: function (user) { return this$1.stateSelectedItemsStatus[user.id] ? this$1.stateSelectedItemsStatus[user.id] : '—'; }
      },
      {
        label: 'Locality',
        value: function (item) { return item.locality_names.length ? _vm.displayFirstOrWithCounter(item.locality_names) : '-'; },
      },
      {
        label: 'Sector',
        value: function (item) { return item.sector_names.length ? _vm.displayFirstOrWithCounter(item.sector_names) : '-'; },
      },
      {
        label: 'Country',
        value: function (item) { return item.country_names.length ? _vm.displayFirstOrWithCounter(item.country_names) : '-'; },
      },
      {
        label: 'Tags',
        value: function (item) { return item.tags.length
            ? item.tags
                .map(function (tag) {
                  return ("#" + (tag.tag));
                })
                .join(', ')
            : '-'; },
      } ]}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"respondent-allocation__total-title"},[_c('span',[_vm._v("Allocated")]),_vm._v(" "),_c('span',[_vm._v("Required")])])}]

export { render, staticRenderFns }