<template>
  <div>
      <aside v-if="saveWorkedOk" class="flash flash--success sml-c12 sml-padding-v sml-padding-sides-half">
        <div class="wrapper">
          <div class="sml-c12">
            Your bank account has been registered.
          </div>
        </div>
      </aside>

      <div v-if="saveWorkedOk" class="sml-c12 c8 sml-push-top-half">
        <a href="/payer/bank-account" class="btn btn--primary">Continue</a>
      </div>

    <div v-if="showForm">
      <section>
        <div class="sml-c12 c8">
          <label>Bank account currency</label>
          <select v-model="selectedCurrency" @change="setCurrency">
            <option
              v-for="option in currencies"
              v-bind:value="option.code"
            >
              {{ option.name }} ({{ option.code }})
            </option>
          </select>
        </div>

        <div class="sml-c12 c8 sml-push-top-half">
          <label>Type</label>
          <select v-if="sets.length > 0" v-model="selectedSet" @change="resetDetails">
            <option
              v-for="option in sets"
              v-bind:value="option"
            >
              {{ option.title }}
            </option>
          </select>
        </div>

        <div class="sml-c12 c8 sml-push-top-half" v-if="sets.length == 0">
          <p>
            Sorry, this currency is not currently supported.
          </p>
        </div>

    <!--    <div class="sml-c12 c8 sml-push-top-half">-->
    <!--      <pre>{{ payload }}</pre>-->
    <!--    </div>-->

        <div class="sml-c12 c8 sml-push-top-half" v-if="errors.general">
          <small class="danger">
            <strong>Error: Please complete the bank account details.</strong>
          </small>
        </div>

        <div class="sml-c12 c8 sml-push-top-half" v-if="selectedSet.fields">
          <label>Account holder name</label>
          <input type="text" v-model="accountHolderName" />

          <small class="danger" v-if="errors.accountHolderName">
            <strong>Error: {{ errors.accountHolderName.message }}</strong>
          </small>
        </div>

        <div
          class="sml-c12 c8 sml-push-top-half"
          v-if="selectedSet"
          v-for="field in selectedSet.fields">

          <label>{{ field.name }}</label>
          <span v-for="item in field.group">

            <FieldSelect
              v-bind:key="item.key"
              v-if="item.type === 'select'"
              :field="item"
              v-model="details[item.key]"
              v-on:refreshRequirements="refreshRequirements"
              ></FieldSelect>

            <FieldText
              v-bind:key="item.key"
              v-else-if="item.type === 'text'"
              :field="item"
              v-model="details[item.key]"
              v-on:refreshRequirements="refreshRequirements"
            ></FieldText>

            <FieldRadio
              v-bind:key="item.key"
              v-else-if="item.type === 'radio'"
              :field="item"
              v-model="details[item.key]"
              v-on:refreshRequirements="refreshRequirements"
            ></FieldRadio>

            <FieldDate
              v-bind:key="item.key"
              v-else-if="item.type === 'date'"
              :field="item"
              v-model="details[item.key]"
              v-on:refreshRequirements="refreshRequirements"
            ></FieldDate>

            <small class="danger" v-if="errors[item.key]">
                <strong>Error: {{ errors[item.key].message }}</strong>
            </small>

          </span>
        </div>

        <div class="sml-c12 c8 sml-push-top-half">
          <button type="button" class="btn btn--primary pull-right"
                  v-if="saveEnabled"
                  @click="handleSave">Save account details</button>

  <!--        <pre>{{ errors }}</pre>-->
        </div>

      </section>
    </div>

  </div>
</template>

<script>
import axios from 'axios';
import FieldDate from './FieldDate'
import FieldRadio from "./FieldRadio";
import FieldSelect from "./FieldSelect";
import FieldText from "./FieldText";

export default {
  name: 'BankAccount',
  components: {FieldRadio, FieldDate, FieldText, FieldSelect},
  data() {
    return {
      currencies: [],
      selectedCurrency: 'GBP',
      sets: {},
      selectedSet: {},
      details: {},
      accountHolderName: '',
      errors: {},
      saveWorkedOk: false,
      showForm: true
    };
  },
  mounted() {
    axios
      .get('/payer/bank-account/currencies')
      .then((response) => {
        this.currencies = response.data.body;
        this.setCurrency();
      });
  },
  computed: {
    selectedType() {
      if (this.selectedSet) {
        return this.selectedSet.type;
      }
      return null;
    },
    payload() {
      let payload = {
        'currency': this.selectedCurrency,
        'type': this.selectedSet.type,
        'details': {},
        'accountHolderName': this.accountHolderName
      };

      for (const [key, value] of Object.entries(this.details)) {
        if (key.includes('.')) {
          const parts = key.split('.');
          const root = parts[0];
          const leaf = parts[1];

          if (payload.details[root] === undefined) {
            payload.details[root] = {};
          }

          payload.details[root][leaf] = value;
        } else {
          payload.details[key] = value;
        }
      }

      return payload;
    },
    saveEnabled() {
      return this.accountHolderName != "";
    }
  },
  methods: {
    setCurrency: function () {
      this.selectedSet = {};

      axios
        .get('/payer/bank-account/requirements/' + this.selectedCurrency)
        .then((response) => {
          this.sets = response.data.body;
        });
    },
    resetDetails: function () {
      this.details = {};
    },
    refreshRequirements: function () {
      axios
        .post(
          '/payer/bank-account/requirements/' + this.selectedCurrency,
          {
            "type": this.selectedSet.type,
            "details": this.details
          }
        )
        .then((response) => {
          this.sets = response.data.body;

          // We need to update the selected set
          for (let i=0; i<this.sets.length; i++) {
            if (this.sets[i].type === this.selectedSet.type) {
              this.selectedSet = this.sets[i];
              break;
            }
          }
        });
    },
    handleSave: function () {
      this.errors = {};
      axios
        .post(
          '/payer/bank-account/save',
          this.payload
        )
        .then((response) => {
          if (response.status === 200) {

            const body = response.data.body;
            if (body.errors) {
              for (let i=0; i<body.errors.length; i++) {
                const err = body.errors[i];

                if (err.code === 'NOT_VALID') {
                    this.errors[err.path] = err;
                }

                if (err.code === 'INVALID_INPUT') {
                  this.errors.general = err;
                }
              }
              this.$forceUpdate();
            }

            if (body.id) {
              this.saveSuccess();
            }

          }
        });
    },
    saveSuccess: function () {
      this.showForm = false;
      this.saveWorkedOk = true;
    }
  },
};
</script>
