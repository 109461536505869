const timezone = (() => {
  function guess() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone || undefined;
  }

  function findTimezone(options, timezoneToFind) {
    return Array.from(options).findIndex(option => option.value === timezoneToFind);
  }

  function setTimezone(el) {
    const select = el;

    if (select.value !== '0') {
      return;
    }

    select.selectedIndex = findTimezone(select.options, guess());
  }

  function init() {
    Array.from(document.getElementsByClassName('js-timezone')).forEach(setTimezone);
  }

  return {
    init,
  };
})();

export default timezone;
