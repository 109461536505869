<template>
  <v-popover offset="16">
    <button class="btn btn--tooltip"><IconDots /></button>

    <template slot="popover">
      <div class="popover__list">
        <a v-if="data.role !== 'admin'" :href="`/impersonate/take/${data.id}`"
          >Impersonate</a
        >
        <a :href="`/admin/client/${data.id}/transactions`">Transactions</a>
        <a :href="`/admin/user/edit/${data.id}`">Edit</a>
        <a :href="`/admin/user/sendpassreset/${data.id}`">Send Reset</a>
      </div>
    </template>
  </v-popover>
</template>

<script>
import IconDots from "../../icons/IconDots.vue";

export default {
  name: "UserManagementDatabase",
  components: {
    IconDots,
  },
  props: {
    data: {},
  },
  created() {},
};
</script>
