const formSubmitDelete = (() => {

  function del(el) {
    const deleteForm = el.getAttribute('data-delete-form');
    const formDeleteClass = el.getAttribute('data-delete-class');
    const deleteFormButton = document.querySelector(`.${formDeleteClass}`);

    deleteFormButton.addEventListener('click', (e) => {
      e.preventDefault();
      document.getElementById(deleteForm).submit();
    });
  }

  function bindUIEvents() {
    Array.from(document.querySelectorAll('.js-delete')).forEach(del);
  }

  function init() {
    bindUIEvents();
  }

  return {
    init,
  };
})();

export default formSubmitDelete;
