const hamburger = (() => {
  const hamburgerIcon = document.querySelector('.hamburger');
  const hamburgerMenu = document.querySelector('.menu__toggle');

  function toggleHamburger() {
    hamburgerIcon.classList.toggle('is-active');
    hamburgerMenu.classList.toggle('open');
  }

  function bindUIEvents() {
    if (hamburgerIcon) {
      hamburgerIcon.addEventListener('click', toggleHamburger);
    }
  }

  function init() {
    bindUIEvents();
  }

  return {
    init,
  };
})();

export default hamburger;
