<template>
  <div class="countryCard__holder sml-c12 med-c6 c4 sml-padding-sides-half sml-push-top" v-if="hasLoaded">
    <article class="countryCard card card--keyline card--border-radius flex flex-column">
      <div class="card__inner payer_allocation_header">
        <button type="button" class="btn btn--tertiary btn--slim" @click="removeCountry()">
          <icon-base icon-name="trash" width="15" height="17" className=""><icon-trash /></icon-base>
        </button>
        <h4>
          {{ country.name }}
          <span class="countryCard__selection_count pull-right">
            <count :slice="{ children: [...selected.country[country.id].respondents.others, ...selected.country[country.id].respondents.payers] }"/>
          </span>
        </h4>
      </div>

      <collapsible-section title="Payers">
        <template slot="count" slot-scope="payerscount">
          <count :slice="{ children: selected.country[country.id].respondents.payers}"></count>
        </template>
        <template slot="content">
          <div
            class="card__inner"
            :class="{ 'card__section--keyline': levelIndex !== 0 }"
            v-for="(level, levelIndex) in payerData.levels"
            :key="levelIndex"
          >
            <h4 class="h3 flex flex-no-wrap flex-between">
              {{ level.name }}
              <span class="countryCard__selection_count pull-right sml-push-right">
                <count :slice="selected.country[country.id].respondents.payers[levelIndex]"/>
              </span>
            </h4>
            <div>
              <div
                class="flex flex-between flex-align-center sml-push-top-half"
                v-for="(sector, sectorIndex) in level.sectors"
                :key="sectorIndex"
              >
                <label class="label--inline label--light sml-c8">{{ sector.name }}</label>
                <input
                  type="number"
                  min="0"
                  :name="`countries[${country.id}][level][${level.id}][sector][${sector.id}]`"
                  @input="setSelectedCountGeneric('payers', country.id, level.name, levelIndex, null, [sectorIndex], $event)"
                  v-model="selected.country[country.id].respondents.payers[levelIndex].children[sectorIndex].selected_count"
                />
              </div>
            </div>
          </div>
        </template>
      </collapsible-section>

      <div
        v-for="(respondent, respondentIndex) in otherData"
        :key="respondentIndex"
        class="flex flex-column"
      >
        <collapsible-section :title="respondent.name">
          <template slot="count" :slot-scope="respondent.id">
            <count :slice="selected.country[country.id].respondents.others[respondentIndex]"></count>
          </template>
          <template slot="content">
              <div
                v-for="(child, childIndex) in respondent.children"
                :key="childIndex"
                class="flex flex-column"
              >
                <div
                  class="card__inner"
                  :class="{ 'card__section--keyline': childIndex !== 0 }"
                >
                  <h5 class="h3 flex flex-between">
                    {{ child.name }} <count :slice="selected.country[country.id].respondents.others[respondentIndex].children[childIndex]"/>
                  </h5>
                  <div>
                    <div class="flex flex-between flex-align-center sml-push-top-half">
                      <label class="label--inline label--light sml-c8 h5">
                        Any
                      </label>
                      <input
                        type="number"
                        min="0"
                        :name="`countries[${country.id}][respondent][${respondent.id}][${child.id}]`"
                        v-model="selected.country[country.id].respondents.others[respondentIndex].children[childIndex].selected_count"
                        @input="setSelectedCountGeneric('others', country.id, respondent.name, respondentIndex, child, [childIndex], $event)"
                      />
                    </div>
                    <template v-if="child.children">
                      <div
                        class="flex flex-between flex-align-center sml-push-top-half"
                        v-for="(subChild, subChildIndex) in child.children"
                        :key="subChildIndex"
                      >
                        <label class="label--inline label--light sml-c8">
                          {{ subChild.name }}
                        </label>
                        <input
                          type="number"
                          min="0"
                          :name="`countries[${country.id}][respondent][${respondent.id}][${subChild.id}]`"
                          v-model="selected.country[country.id].respondents.others[respondentIndex].children[childIndex].children[subChildIndex].selected_count"
                          @input="setSelectedCountGeneric('others', country.id, respondent.name, respondentIndex, child, [childIndex, subChildIndex], $event)"
                        />
                      </div>
                    </template>
                  </div>
                </div>
              </div>
          </template>
        </collapsible-section>
      </div>

    </article>
  </div>

  <div v-else class="sml-c12 med-c6 c4 sml-padding-sides-half sml-push-top">
    <div class="spinner">
      <div class="rect1"></div>
      <div class="rect2"></div>
      <div class="rect3"></div>
      <div class="rect4"></div>
      <div class="rect5"></div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Count from './Count.vue';
import Collapsible from '../Collapsible.vue';
import CollapsibleSection from './CollapsibleSection.vue';
import IconBase from '../icons/IconBase.vue';
import IconTrash from '../icons/IconTrash.vue';
import IconChevronDown from '../icons/IconChevronDown.vue';

export default {
  name: 'CountryCard',
  props: {
    country: Object,
  },
  components: {
    IconBase,
    IconTrash,
    Collapsible,
    CollapsibleSection,
    Count,
    IconChevronDown,
  },
  data() {
    return {
      hasLoaded: false,
      payerData: [],
      selectedDistributionInLevel: {},
      totalSelectedOptions: 0,
    };
  },
  created() {
    this.fetchPayerData();
  },
  computed: {
    initialCountryState() {
      return this.$store.state.matrix.initialCountryState;
    },
    selected() {
      return this.$store.state.matrix.selected;
    },
    countriesData() {
      return this.$store.state.matrix.countriesData;
    },
    data() {
      return {
        hasLoaded: false,
        payerData: [],
        selectedDistributionInLevel: {},
        totalSelectedOptions: 0,
      };
    },
    created() {
      this.fetchPayerData();
    },
  },
  methods: {
    /**
     * Gets the initial selected state (originally passed in via props)
     * and uses it to determine if the respondent class we're building a
     * structure for has an existing value. Since the initial data is a
     * comparatively flat structure, we just check the ID.
     */
    getHydratedCount(respondentType, target) {
      const { id, country_id, country_level_id } = target;

      // Check if we have any pre-existing data for this country
      if (
        !this.initialCountryState[country_id]
        || !this.initialCountryState[country_id][respondentType]
      ) return 0;

      // Find any existing entries for the target respondent
      const match = this.initialCountryState[country_id][respondentType]
        .find(entry => {
          if (respondentType === 'others') {
            return entry.id === id;
          }
          if (respondentType === 'payers') {
            return entry.country_level_id === country_level_id && entry.id === id;
          }
          return null;
        });

      return match ? match.pivot.count : 0;
    },
    /**
     * Modify payer data structure to mirror 'others' so we can easily
     * recursively walk the tree.
     */
    payersToGeneric({ levels }) {
      return levels.map(({ sectors }) => ({
        children: sectors.map((sector) => {
          const { selected_count } = sector;
          return {
            selected_count: (typeof selected_count !== 'number')
              ? this.getHydratedCount('payers', sector)
              : selected_count,
          };
        })
      }));
    },
    /**
     * Pre-populates 'selected' state count values, since Vue will not
     * treat these as reactive if we add them in later.
     */
    addSelectedCount(target) {
      const { selected_count, children, id } = target;

      const count = (typeof selected_count !== 'number')
        ? this.getHydratedCount('others', target)
        : selected_count;

      if (children) {
        return Object.assign({}, {
          id,
          selected_count: count,
          children: children.map((child) => (
            this.addSelectedCount(child)
          )),
        });
      }

      return {
        id,
        selected_count: count
      };
    },
    fetchPayerData() {
      const path = `/client/countries/${this.country.id}`;

      axios
        .get(path)
        .then(response => {
          const res = response.data.data;

          this.payerData = res.respondents.payers;
          this.otherData = res.respondents.others;

          // Sets data properties based on level names
          // (we can't set these up front as we can't guarantee what the names will be).
          // Used for summing selected distribution at each level
          this.payerData.levels.map(level => {
            this.$set(this.selectedDistributionInLevel, `${level.name}Sum`, 0);
          });

          this.$store.commit({
            type: 'populateSelected',
            selectedData: {
              country: {
                [res.id]: {
                  respondents: {
                    payers: this.payersToGeneric(res.respondents.payers),
                    others: res.respondents.others.map(child => this.addSelectedCount(child)),
                  },
                },
              },
            },
          });

          this.$store.commit({
            type: 'populateCountries',
            countriesData: res,
          });

          this.$store.commit('updateSelectedSlice');

          this.hasLoaded = true;
        })
        .catch(error => {
          console.log(error);
        });
    },
    setSelectedCountGeneric(respondentType, country, respondentName, respondentIndex, child, childIndex, e) {
      this.$store.commit({
        type: 'setSelectedCountGeneric',
        respondentType,
        country,
        respondent: respondentIndex,
        child: childIndex,
        val: e.target.value,
      });

      this.$store.commit('updateSelectedSlice');
    },
    removeCountry() {
      this.$store.commit({
        type: 'removeCountry',
        country: this.country,
      });
    }
  },
};
</script>

<style scoped>
  .payer_allocation_header .btn {
    background-color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
  }
</style>
