<template>
  <div class="sml-c12">
    <select v-model="value" v-on:input="updateValue($event.target.value)">
      <option
        v-for="option in field.valuesAllowed"
        v-bind:value="option.key"
      >
        {{ option.name }}
      </option>
    </select>
  </div>
</template>

<script>

export default {
  name: 'FieldSelect',
  data() {
    return {
      'value': null
    };
  },
  mounted() {
  },
  props: {
    field: Object
  },
  methods: {
    updateValue: function (value) {
      this.$emit('input', value)

      if (this.field.refreshRequirementsOnChange) {
        this.$emit('refreshRequirements');
      }
    }
  },
};
</script>
