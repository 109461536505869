var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"user-database"},[_c('user-database-filters',{attrs:{"allocationOptions":_vm.allocationOptions,"allocationEnabled":_vm.allocationEnabled && _vm.stateSelectedItems.length > 0,"handleAllocation":_vm.handleAllocation,"activeFilters":_vm.stateActiveFilters,"otherActiveFilters":_vm.stateFilters,"filters":_vm.filters,"handleAddFilter":_vm.handleAddFilter,"handleFilter":_vm.handleFilter,"handleRemoveFilter":_vm.handleRemoveFilter,"searchString":_vm.stateQueryString},on:{"update:searchString":function($event){_vm.stateQueryString=$event},"update:search-string":function($event){_vm.stateQueryString=$event}}}),_vm._v(" "),_c('div',{staticClass:"user-database__summary"},[(
        !_vm.stateLoading &&
        Object.values(_vm.stateFilters).filter(function (filter) { return !!filter; }).length > 0
      )?_c('p',[_c('span',[_vm._v(_vm._s(_vm.stateTotalItems))]),_vm._v(" users meet the selected criteria.\n    ")]):_vm._e(),_vm._v(" "),_c('a',{on:{"click":_vm.downloadUsers}},[_vm._v("Download as CSV")])]),_vm._v(" "),_c('div',{staticClass:"user-database__table"},[_c('Table',{attrs:{"loading":_vm.stateLoading,"headCells":_vm.columns.map(function (column) {
          switch (column.type) {
            case 'checkbox':
              return '';
            case 'actions':
              return '';
            default:
              return column.label;
          }
        }),"items":[].concat( _vm.stateItems.map(function (item) { return ({
          excluded: _vm.isExcluded(item),
          cells: [].concat( _vm.columns.map(function (column) {
              switch (column.type) {
                case 'checkbox':
                  return {
                    type: 'checkbox',
                    selected: _vm.isSelected(item),

                    action: function () { return _vm.handleSelect(item); },
                  };
                case 'link':
                  return {
                    label: column.value(item),
                    type: 'link',
                    action: column.action(item),
                  };
                case 'actions':
                  return {
                    type: 'actions',
                    data: item,
                  };
                default:
                  return {
                    label: column.value(item),
                    hidden: column.hidden,
                  };
              }
            }) ),
        }); }) )}})],1),_vm._v(" "),_c('div',{staticClass:"user-database__pagination"},_vm._l((Array.from(Array(_vm.stateTotalPages).keys())),function(page,index){return _c('a',{key:index,class:{ selected: parseInt(_vm.statePage) === page + 1 },attrs:{"href":("?page=" + (page + 1))},on:{"click":function($event){return _vm.handlePage(page + 1, $event)}}},[_vm._v("\n      "+_vm._s(page + 1)+"\n    ")])}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }