const matrix = {
  state: {
    countriesAdded: [],
    countriesData: [],
    initialCountryState: [],
    selected: {},
    selectedSlice: {},
  },
  getters: {
    getInitialCountryState: state => countryId => state.initialCountryState[countryId],
  },
  mutations: {
    addCountry(state, payload) {
      state.countriesAdded.push(payload.country);
    },
    removeCountry(state, payload) {
      let index = state.countriesAdded.indexOf(payload.country);
      state.countriesAdded.splice(index, 1);
      state.countriesData.splice(payload.country.id, 1);
    },
    populateCountries(state, payload) {
      state.countriesData[payload.countriesData.id] = payload.countriesData;
    },
    populateSelected(state, payload) {
      state.selected = {
        country: Object.assign(
          {},
          state.selected.country,
          payload.selectedData.country,
        ),
      };
    },
    setSelectedCountGeneric(state, payload) {
      const targetParent = state.selected.country[payload.country].respondents[payload.respondentType][payload.respondent];
      const targetChild = payload.child
        .reduce((acc, childIndex) => acc.children[childIndex], targetParent);
      targetChild.selected_count = payload.val;
    },
    setInitialCountryState(state, payload) {
      const { config } = payload;

      state.initialCountryState = config.reduce((acc, entry) => {
        const { country_id, respondentType } = entry;

        const countryList = acc[country_id] || {};
        const respList = (acc[country_id] && acc[country_id][respondentType]) || [];

        return Object.assign({}, acc, {
          [country_id]: Object.assign({}, countryList, {
            [respondentType]: [].concat(respList, entry),
          }),
        });
      }, {});
    },
    updateSelectedSlice(state) {
      if (state.selected.country) {
        state.selectedSlice = {
          children: Object.keys(state.selected.country)
            .map((id) => {
              const { others, payers } = state.selected.country[id].respondents;
              return { children: [...others, ...payers] };
            }),
        };
      } else {
        state.selectedSlice = [];
      }
    },
  },
};

export default matrix;
