<template>
  <div class="flex flex-align-center">
    <div v-if="stateSelectedTags.length">
      <div class="flex flex-align-center">
        <div
          v-for="(tag, index) in stateSelectedTags"
          class="sml-padding-right"
          :key="index"
        >
          <a href="#" class="mr-1 dark-grey" @click.prevent>#{{ tag.tag }}</a>
          <a
            href="#"
            class="danger"
            title="Delete"
            @click.prevent="deselectTag(index)"
          >
            <icon-base
              icon-name="chevron-left"
              :viewbox-height="16"
              :viewbox-width="16"
              :height="16"
              :width="16"
              hide-title
            >
              <icon-delete />
            </icon-base>
          </a>
        </div>
      </div>
    </div>
    <div class="flex">
      <div class="sml-padding-right">
        <tag-input
          v-model="newTag"
          :available-tags="availableTagsExceptSelected"
          :click="inputId"
          @clickExisting="addTag($event)"
        ></tag-input>
      </div>
      <button type="button" class="btn btn--secondary" @click="addTag(newTag)">
        Add Tag
      </button>
    </div>
    <template v-for="(selectedTag, index) in stateSelectedTags">
      <input
        type="hidden"
        :name="fieldName + '[' + index + '][tag]'"
        :value="selectedTag.tag"
        :key="`tag-${index}`"
      />
      <input
        type="hidden"
        :name="fieldName + '[' + index + '][id]'"
        :value="selectedTag.id"
        :key="`id-${index}`"
      />
    </template>
  </div>
</template>

<script>
import _ from "lodash";
import TagInput from "./TagInput";
import IconBase from "../icons/IconBase.vue";
import IconDelete from "../icons/IconDelete.vue";

export default {
  name: "TagList",
  components: {
    TagInput,
    IconBase,
    IconDelete,
  },
  props: {
    inputId: {
      type: String,
      default: "tag-input",
    },
    fieldName: {
      type: String,
      default: "tags",
    },
    availableTags: {
      required: true,
      type: Array,
    },
    selectedTags: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      newTag: {
        tag: "",
        id: null,
      },
      stateSelectedTags: [],
    };
  },
  computed: {
    availableTagsExceptSelected() {
      return _.filter(
        this.availableTags,
        (availableTag) =>
          this.stateSelectedTags.findIndex(
            (selectedTag) => selectedTag.tag === availableTag.tag
          ) === -1
      );
    },
  },
  created() {
    this.stateSelectedTags = _.cloneDeep(this.selectedTags);
  },
  methods: {
    resetNewTag() {
      this.newTag.tag = "";
      this.newTag.id = null;
    },
    deselectTag(index) {
      this.stateSelectedTags.splice(index, 1);
    },
    addTag(tag) {
      let existingIndex = this.stateSelectedTags.findIndex(
        (selectedTag) => selectedTag.tag === tag.tag
      );
      if (existingIndex === -1) {
        this.stateSelectedTags.push({ ...tag });
      }
    },
  },
};
</script>

<style scoped></style>
