<template>
  <div class="survey-builder">
    <div
      class="
        card card--danger card--border-radius
        sml-c12 sml-padding-all sml-push-top-half
      "
      v-if="errors.items.length"
    >
      You have errors on the following
      {{ errors.items.length > 1 ? "pages" : "page" }}. Please fix them before
      continuing.

      <div
        class="sml-push-top"
        v-for="(page, index) in pagesWithErrors"
        :key="index"
      >
        <strong>Page {{ page.page }}</strong>
        <div v-for="(question, index) in page.questions" :key="index">
          <div class="sml-push-top">Question {{ question.displayNo }}</div>
          <ul>
            <li v-for="(error, index) in question.errors" :key="index">
              {{ error.msg }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-for="(page, index) in pages" v-bind:key="`page-${index}`">
      <input type="hidden" :name="`pages[${index}][name]`" :value="page.name" />
      <input
        type="hidden"
        :name="`pages[${index}][description]`"
        :value="page.description"
      />
    </div>
    <template v-for="input in reducedInputs">
      <!-- Hidden input used to capture input type. Value of question title is captured in the input template  -->
      <input
        v-if="input.question.validation.required == 'on'"
        type="hidden"
        :value="input.question.validation.required ? 'on' : 'off'"
        :key="`pages[${input.page.pageNumber}][questions][${input.question.questionNumber}][validation][required]`"
        :name="`pages[${input.page.pageNumber}][questions][${input.question.questionNumber}][validation][required]`"
      />
      <!-- Type -->
      <input
        type="hidden"
        :key="`pages[${input.page.pageNumber}][questions][${input.question.questionNumber}][type]`"
        :name="`pages[${input.page.pageNumber}][questions][${input.question.questionNumber}][type]`"
        :value="input.question.type"
        :data-vv-name="`pages.${input.page.pageNumber}.questions.${input.question.questionNumber}.type`"
        data-vv-as="question"
      />
        <!-- v-validate="{ required: true }" -->
      <!-- Label -->
      <input
        type="hidden"
        :key="`pages[${input.page.pageNumber}][questions][${input.question.questionNumber}][config][label]`"
        :name="`pages[${input.page.pageNumber}][questions][${input.question.questionNumber}][config][label]`"
        :value="input.question.config.label"
        :data-vv-name="`pages.${input.page.pageNumber}.questions.${input.question.questionNumber}.config.label`"
        data-vv-as="question"
      />
        <!-- v-validate="{ required: true }" -->
      <input
        :key="`pages[${input.page.pageNumber}][questions][${input.question.questionNumber}][is_probe]`"
        type="hidden"
        :name="`pages[${input.page.pageNumber}][questions][${input.question.questionNumber}][is_probe]`"
        :value="
          input.question.config.parentUID &&
          input.question.config.parentUID !== 'root_survey_parent'
            ? 1
            : 0
        "
      />
      <!-- Options -->
      <template v-if="input.question.type === 'Table'">
        <input
          type="hidden"
          :key="`pages[${input.page.pageNumber}][questions][${input.question.questionNumber}][config][options][0][type]`"
          :name="`pages[${input.page.pageNumber}][questions][${input.question.questionNumber}][config][options][0][type]`"
          :value="
            input.question.config.options[0] &&
            input.question.config.options[0].type
          "
           />

        <input
          v-for="(column, columnIndex) in input.question.config.options[0]
            .columns"
          type="hidden"
          :key="`pages[${input.page.pageNumber}][questions][${input.question.questionNumber}][config][options][0][columns][${columnIndex}]`"
          :name="`pages[${input.page.pageNumber}][questions][${input.question.questionNumber}][config][options][0][columns][${columnIndex}]`"
          :value="
            input.question.config.options[0].columns[columnIndex] &&
            input.question.config.options[0].columns[columnIndex].label
          "
          :data-vv-name="`pages.${input.page.pageNumber}.questions.${input.question.questionNumber}.config.options.0.columns.${columnIndex}`"
          data-vv-as="option"
        />
          <!-- v-validate="{ required: true }" -->
        <input
          v-for="(row, rowIndex) in input.question.config.options[0].rows"
          :key="`pages[${input.page.pageNumber}][questions][${input.question.questionNumber}][config][options][0][rows][${rowIndex}]`"
          type="hidden"
          :name="`pages[${input.page.pageNumber}][questions][${input.question.questionNumber}][config][options][0][rows][${rowIndex}]`"
          :value="
            input.question.config.options[0].rows[rowIndex] &&
            input.question.config.options[0].rows[rowIndex].label
          "
          :data-vv-name="`pages.${input.page.pageNumber}.questions.${input.question.questionNumber}.config.options.0.rows.${rowIndex}`"
          data-vv-as="option"
        />
          <!-- v-validate="{ required: true }" -->
      </template>
      <template v-else-if="input.question.type === 'Probed'">
        <input
          type="hidden"
          :key="`pages.${input.page.pageNumber}.questions.${input.question.questionNumber}.num_probe_questions`"
          :name="`pages[${input.page.pageNumber}][questions][${input.question.questionNumber}][num_probe_questions]`"
          :data-vv-name="`pages.${input.page.pageNumber}.questions.${input.question.questionNumber}.num_probe_questions`"
          v-validate="'num_probe_questions'"
        />
      </template>
      <template v-else-if="input.question.type === 'Rating'">
        <input
          type="hidden"
          :key="`pages[${input.page.pageNumber}][questions][${input.question.questionNumber}][config][leftLabelVal]`"
          :name="`pages[${input.page.pageNumber}][questions][${input.question.questionNumber}][config][leftLabelVal]`"
          :value="input.question.config.leftLabelVal || 0"
          v-validate="{ max_value: input.question.config.rightLabelVal || 10 }"
          :data-vv-name="`pages.${input.page.pageNumber}.questions.${input.question.questionNumber}.config.leftLabelVal`"
          data-vv-as="minimum rating"
          />
        <input
          type="hidden"
          :key="`pages[${input.page.pageNumber}][questions][${input.question.questionNumber}][config][leftLabel]`"
          :name="`pages[${input.page.pageNumber}][questions][${input.question.questionNumber}][config][leftLabel]`"
          :value="input.question.config.leftLabel" />
        <input
          type="hidden"
          :key="`pages[${input.page.pageNumber}][questions][${input.question.questionNumber}][config][rightLabelVal]`"
          :name="`pages[${input.page.pageNumber}][questions][${input.question.questionNumber}][config][rightLabelVal]`"
          :value="input.question.config.rightLabelVal || 10"
          v-validate="{ min_value: input.question.config.leftLabelVal || 0 }"
          :data-vv-name="`pages.${input.page.pageNumber}.questions.${input.question.questionNumber}.config.rightLabelVal`"
          data-vv-as="maximum rating"
          />
        <input
          type="hidden"
          :key="`pages[${input.page.pageNumber}][questions][${input.question.questionNumber}][config][rightLabel]`"
          :name="`pages[${input.page.pageNumber}][questions][${input.question.questionNumber}][config][rightLabel]`"
          :value="input.question.config.rightLabel"
      /></template>
      <template v-else>
        <template
          v-for="(option, optionIndex) in input.question.config.options"
        >
          <input
            type="hidden"
            v-bind:key="`pages[${input.page.pageNumber}][questions][${input.question.questionNumber}][config][options][${optionIndex}][label]`"
            :name="`pages[${input.page.pageNumber}][questions][${input.question.questionNumber}][config][options][${optionIndex}][label]`"
            :value="input.question.config.options[optionIndex].label"
            :data-vv-name="`pages.${input.page.pageNumber}.questions.${input.question.questionNumber}.config.options.${optionIndex}.label`"
            data-vv-as="question"
          />
            <!-- v-validate="{ required: true }" -->
          <input
            type="hidden"
            v-bind:key="`pages[${input.page.pageNumber}][questions][${input.question.questionNumber}][config][options][${optionIndex}][value]`"
            :name="`pages.${input.page.pageNumber}.questions.${input.question.questionNumber}.config.options.${optionIndex}.value`"
            :value="input.question.config.options[optionIndex].value"
          />
        </template>
      </template>
    </template>
    <div class="sb__pages">
      <div class="sb__pages-header">
        <button class="btn btn--secondary" type="button" v-on:click="addPage">
          Add page
          <icon-base
            icon-name="add"
            width="17"
            height="20"
            className="sml-push-right"
            ><icon-add
          /></icon-base>
        </button>
        <question-dropdown :updateSurvey="updateSurvey" @fieldAdded="enableQuestionMode"/>
      </div>
      <div class="pages-list">
        <span
          v-if="pages.length === 0"
          class="
            sb__pages-list-item__question sb__pages-list-item__question--none
          "
          >No pages added.</span
        >
        <div v-else>
          <draggable v-model="pages" @start="drag = true" @end="drag = false">
            <div
              v-for="(page, index) in pages"
              v-bind:key="index"
              class="sb__pages-list-item"
            >
              <div
                v-bind:class="
                  stateSelected.page.pageNumber === index && !questionMode
                    ? 'sb__pages-list-item__header sb__pages-list-item__header--selected'
                    : 'sb__pages-list-item__header'
                "
                v-on:click="handlePageEditor({ ...page, pageNumber: index })"
              >
                <div class="sb__pages-list-item__icon">
                  <icon-base
                    icon-name="reorder"
                    width="15"
                    height="12"
                    className=""
                    ><icon-drag
                  /></icon-base>
                </div>
                {{ index + 1 }}.
                {{ page.name }}
              </div>
              <div class="sb__pages-list-item__questions">
                <draggable @end="updateSurvey($event, index)">
                  <div
                    v-for="(question, questionIndex) in reducedQuestion(
                      page.questions
                    )"
                    v-bind:class="
                      stateSelected.page &&
                      stateSelected.page.pageNumber === index &&
                      stateSelected.question &&
                      stateSelected.question.newIndex === questionIndex
                        ? 'sb__pages-list-item__question sb__pages-list-item__question--selected'
                        : 'sb__pages-list-item__question'
                    "
                    v-bind:key="question.config.uid"
                    v-on:click="
                      handleSelect(
                        { ...page, pageNumber: index },
                        { ...question, questionNumber: question.originalIndex }
                      )
                    "
                  >
                    <div class="sb__pages-list-item__icon">
                      <icon-base
                        icon-name="reorder"
                        width="15"
                        height="12"
                        className=""
                        ><icon-drag
                      /></icon-base>
                    </div>
                    <span class="sb__pages-list-item__question__number sml-padding-right"
                      ><span class="sb__pages-list-item__question__index">{{
                        questionIndex + 1
                      }}</span
                      ><span class="sb__pages-list-item__question__icon">
                        <question-icon :type="question.type" /></span></span
                    >{{ question.config.label }}
                  </div>
                  <div
                    v-if="page.questions.length < 1"
                    class="
                      sb__pages-list-item__question
                      sb__pages-list-item__question--none
                    "
                  >
                    No questions added.
                  </div>
                </draggable>
              </div>
            </div>
          </draggable>
        </div>
      </div>
    </div>
    <div class="sb__question">
      <div class="sb__question-header">
        <div class="btn sb__question-actions" v-if="questionMode">
          <button
            class="btn sb__question-action"
            v-on:click="handleDuplicateQuestion"
            type="button"
          >
            Duplicate
            <icon-base
              icon-name="reorder"
              :viewbox-height="18"
              :viewbox-width="16"
              :height="14"
              :width="14"
              className=""
              ><icon-duplicate
            /></icon-base>
          </button>
          <button
            v-on:click="handleRemoveQuestion"
            type="button"
            class="btn sb__question-action"
          >
            Delete
            <icon-base
              icon-name="reorder"
              :viewbox-height="16"
              :viewbox-width="16"
              :height="14"
              :width="14"
              className=""
              ><icon-delete
            /></icon-base>
          </button>
        </div>
        <div class="btn sb__question-actions" v-else>
          <button
            v-on:click="handleRemovePage"
            type="button"
            class="btn sb__question-action"
          >
            Delete
            <icon-base
              icon-name="reorder"
              :viewbox-height="16"
              :viewbox-width="16"
              :height="14"
              :width="14"
              className=""
              ><icon-delete
            /></icon-base>
          </button>
        </div>
      </div>
      <div v-for="(page, pageIndex) in pages" :key="'page' + pageIndex">
        <div v-for="(question, questionIndex) in page.questions" :key="'page' + pageIndex + 'question' + questionIndex">
              <div v-if="isRootQuestion(question)">
                <survey-question-builder
                  v-show="questionMode && questionIndex == stateSelected.question.questionNumber && pageIndex == stateSelected.page.pageNumber"
                  :input="stateSelected"
                  :stateQuestion="convertQuestionToStateQuestion(page, pageIndex, question, questionIndex)"
                  :handleRemoveQuestion="handleRemoveQuestion"
                ></survey-question-builder>
              </div>
        </div>
      </div>
      <survey-page-editor v-if="!questionMode" :disabled="disabled"
        >Page editor</survey-page-editor
      >
    </div>
  </div>
</template>

<script>
import axios from "axios";
import short from "short-uuid";
import draggable from "vuedraggable";
import IconBase from "../icons/IconBase.vue";
import IconDrag from "../icons/IconDrag.vue";
import IconDelete from "../icons/IconDelete.vue";
import IconAdd from "../icons/IconAdd.vue";
import IconDuplicate from "../icons/IconDuplicate.vue";
import QuestionIcon from "./QuestionIcon.vue";
import SurveyQuestionBuilder from "./SurveyQuestionBuilder.vue";
import SurveyPageEditor from "./SurveyPageEditor.vue";
import QuestionDropdown from "./QuestionDropdown.vue";

export const ROOT_SURVEY_PARENT = "root_survey_parent";

export default {
  name: "SurveyBuilder",
  components: {
    IconDrag,
    IconAdd,
    IconBase,
    IconDelete,
    draggable,
    SurveyQuestionBuilder,
    QuestionIcon,
    SurveyPageEditor,
    QuestionDropdown,
    IconDuplicate,
  },
  props: {
    survey: Object,
    surveyConfig: Object,
  },
  inject: ["$validator"],
  data() {
    return {
      questionMode: true,
      statePages: [],
      pagesWithErrors: [],
    };
  },
  created() {
    // Submit button is in a Blade template, but we submit in Vue so we can run client-side validation
    document.getElementById("submit-survey").onclick = (e) => {
      e.preventDefault();
      this.validatePage(this.submitSurvey);
    };

    if (document.getElementById("save-survey"))
      document.getElementById("save-survey").onclick = (e) => {
        e.preventDefault();
        document.getElementById("save-survey").disabled = true;
        document.getElementById("submit-survey").disabled = true;

        const formData = new FormData(document.getElementById("survey-form"));
        const csrfToken = document.querySelector('input[name="_token"]').value;

        let xhr = new XMLHttpRequest();

        xhr.open("POST", `/client/surveys/${this.survey.uuid}/save`);
        xhr.setRequestHeader("X-CSRF-TOKEN", csrfToken);
        xhr.onload = () => {
          if (xhr.status != 200) {
            console.log(xhr.status);
          }
        };
        xhr.send(formData);
        xhr.onload = function () {
          document.getElementById("save-survey").disabled = false;
          document.getElementById("submit-survey").disabled = false;
        }
      };
    this.$validator.validate();
    if (!this.surveyConfig || !this.surveyConfig.pages?.length) {
      this.$store.commit({
        type: "populateSurvey",
        pages: [
          {
            name: `Page ${this.pages.length + 1}`,
            description: null,
            questions: [],
          },
        ],
      });
      this.questionMode = false;
    } else
      this.$store.commit({
        type: "populateSurvey",
        pages: this.surveyConfig.pages.map((page) => {
          const { questions } = page.questions
            // Add unique IDs
            .map((question) =>
              Object.assign({}, question, {
                config: Object.assign({}, question.config, {
                  uid: short.generate(),
                }),
              })
            )
            // Sort out parentUID relations based on is_probe
            // @todo: Does this actually work with complex hydration?
            .reduce(
              (acc, question) => {
                const { previousQuestionNo, previousQuestionUID, questions } =
                  acc;
                const {
                  is_probe,
                  config: { uid },
                } = question;

                const newQuestion = Object.assign({}, question, {
                  config: Object.assign({}, question.config, {
                    options:
                      question.type === "Table"
                        ? [
                            {
                              ...question.config.options[0],
                              rows: question.config.options[0].rows.map(
                                (row) => ({ label: row })
                              ),
                              columns: question.config.options[0].columns.map(
                                (column) => ({ label: column })
                              ),
                            },
                          ]
                        : question.config.options,
                    parentUID: is_probe
                      ? previousQuestionUID
                      : ROOT_SURVEY_PARENT,
                    displayNo: is_probe
                      ? Math.round((previousQuestionNo + 0.1) * 100) / 100
                      : parseInt(Math.floor(previousQuestionNo) + 1),
                  }),
                });

                return {
                  previousQuestionUID: is_probe ? previousQuestionUID : uid,
                  previousQuestionNo: is_probe
                    ? Math.round((previousQuestionNo + 0.1) * 100) / 100
                    : parseInt(Math.floor(previousQuestionNo) + 1),
                  questions: [...questions, newQuestion],
                };
              },
              {
                previousQuestionNo: 0,
                previousQuestionUID: undefined,
                questions: [],
              }
            );

          const probeQuestions = questions.filter(x=> x.is_probe);
          probeQuestions.map((question) => {
            const parentQuestionNumber = question.config.parent_question_number
            if (parentQuestionNumber){
              const parentQuestion = questions[parentQuestionNumber]
              question.config.parentUID = parentQuestion.config.uid
            }
            return question;
          })
          return Object.assign({}, page, { questions });
        }),
      });
      this.surveyConfig.pages.forEach((page, index) => {
        this.$store.commit({
          type: "recalculateDisplayNo",
          pageNumber: index,
        });
      });

    this.$store.commit({
      type: "setSelected",
      selected: {
        page: { ...this.pages[0], pageNumber: 0 },
        question: {
          ...this.pages[0].questions[0],
          questionNumber: 0,
          newIndex: 0,
        },
      },
    });
  },
  methods: {
    convertQuestionToStateQuestion(page, pageIndex, question, questionIndex) {
      question.questionNumber = questionIndex;
      page.pageNumber = pageIndex
      return {
        question,
        page,
      }
    },
    submitSurvey() {
      document.getElementById("survey-form").submit();
    },
    hasPages() {
      return this.$store.state.survey.pages.length;
    },
    addPage() {
      const newPage = this.pages.length + 1;
      this.$store.commit({
        type: "addPage",
        page: {
          name: `Page ${newPage}`,
          description: null,
          questions: [],
        },
      });
      this.currentPage = this.pages.length - 1;
      this.$store.commit({
        type: "setSelected",
        selected: {
          page: {
            name: `Page ${newPage}`,
            description: null,
            questions: [],
            pageNumber: newPage - 1,
          },
          question: null,
        },
      });
      this.questionMode = false;
    },
    handleRemovePage(page) {
      if (
        window.confirm(
          "Are you sure you wish to delete this page and all questions contained within it?"
        )
      ) {
        this.$store.commit({
          type: "removePage",
          page: {
            index: page.pageNumber,
          },
        });
      }
    },
    handleDuplicateQuestion() {
      this.$store.commit({
        type: "duplicateField",
      });
    },
    handleRemoveQuestion() {
      const pageNumber = this.stateSelected.page.pageNumber;
      const questionNumber = this.stateSelected.page.questions.findIndex(
        (question) => question.questionNumber == this.stateSelected.question.questionNumber
      );

      if (
        window.confirm("Are you sure you would like to delete this question?")
      ) {
        if (this.pages[pageNumber].questions.length > 1)
          this.handleSelect(
            { ...this.pages[pageNumber], pageNumber },
            { ...this.pages[pageNumber].questions[0], questionNumber: 0 }
          );
        else this.questionMode = false;
        this.$store.commit({
          type: "removeField",
          pageNumber,
          questionNumber,
        });
        this.$store.commit({
          type: "recalculateDisplayNo",
          pageNumber,
        });
      }
    },
    handleSelectWithSave(page, question) {
      const formData = new FormData(document.getElementById("survey-form"));
      const csrfToken = document.querySelector('input[name="_token"]').value;

      let xhr = new XMLHttpRequest();

      xhr.open("POST", `/client/surveys/${this.survey.uuid}/save`);
      xhr.setRequestHeader("X-CSRF-TOKEN", csrfToken);
      xhr.onload = () => {
        if (xhr.status !== 200) {
          console.log(xhr.status);
        } else {
          this.handleSelect(page, question);
        }
      };
      xhr.send(formData);
    },
    handleSelect(page, question) {
      this.questionMode = true;
      this.$store.commit({
        type: "setSelected",
        selected: {
          page,
          question,
        },
      });
    },
    handlePageEditor(page) {
      this.questionMode = false;
      this.$store.commit({
        type: "setSelected",
        selected: {
          page,
          question: null,
        },
      });
    },
    handleSubmit() {
      const req = axios.post(`/client/surveys/${this.survey.uuid}/build`, {
        pages: this.$store.state.survey.pages,
      });
    },
    validatePage(callback, ...cbParams) {
      this.$validator.validateAll().then((result) => {
        if (result) {
          callback(...cbParams);
        }
      });
    },
    isRootQuestion(question){
      return (question.config.parentUID === "root_survey_parent" || !question.config.parentUID)
    },
    reducedQuestion(questions) {
      return questions
        .map((question, index) => ({ ...question, originalIndex: index }))
        .filter(
          (question) =>
            question.type == "Probed" ||
            question.config.parentUID === "root_survey_parent" ||
            !question.config.parentUID
        )
        .map((question, index) => ({ ...question, newIndex: index }));
    },
    updateSurvey(e, pageNumber) {
      const page = this.pages[pageNumber];
      let questions = page?.questions || [];
      let reducedQuestions = this.reducedQuestion(questions);

      const realOldIndex = questions.findIndex((item) => {
        return item.config.uid === reducedQuestions[e.oldIndex].config.uid;
      });
      const realNewIndex = questions.findIndex((item) => {
        return item.config.uid === reducedQuestions[e.newIndex].config.uid;
      });
      //standard code to swap 2 or more items in an array
      this.pages[pageNumber].questions.splice(realNewIndex, 0,  this.pages[pageNumber].questions.splice(realOldIndex, 1)[0]);

      this.$store.commit({
        type: "recalculateDisplayNo",
        pageNumber
      });

      this.$store.commit({
        type: "setSelected",
        selected: {
          page,
          question: {
            ...this.pages[pageNumber].questions[e.newIndex],
            questionNumber: e.newIndex,
            newIndex: e.newIndex,
          },
        },
      });
    },
    enableQuestionMode() {
      this.questionMode = this.questionMode || ! this.questionMode;
    },
  },
  updated() {
    document.getElementById("save-survey").disabled = false;
  },
  computed: {
    totalPages() {
      return this.$store.getters.pages.length;
    },
    disabled() {
      return this.surveyConfig && this.surveyConfig.redeploy;
    },
    stateSelected() {
      return this.$store.state.survey.selected;
    },
    pages: {
      get() {
        return this.$store.state.survey.pages;
      },
      set(newPageOrder) {
        // newPageOrder is the output of the draggable event for pages
        // It is the re-organised list of page state

        // For eache page set the new pageNumber value
        newPageOrder.forEach((page, newPosition) => {
          newPageOrder[newPosition].pageNumber = newPosition;
        })

        // usePopulateSurvey to set the state and cause a UI update
        this.$store.commit({
          type: "populateSurvey",
          pages: newPageOrder,
        });
      }
    },
    questionBuilderParentID() {
      return ROOT_SURVEY_PARENT;
    },
    reducedInputs() {
      return this.$store.state.survey.pages
        .map((page, pageNumber) =>
          page.questions.map((question, questionNumber) => ({
            page: { ...page, pageNumber },
            question: { ...question, questionNumber },
          }))
        )
        .flat();
    },
  },
  watch: {
    // Inputs are validated inline and SurveyPageBuilder receives errors.items as a computed prop.
    // Every time errors.items changes, run this function.
    // Used to display which page errors are on.
    "errors.items": function () {
      this.reducedPages = this.errors.items.reduce((pages, item) => {
        const page = parseInt(item.field.split(".")[1]);
        pages[page] = pages[page] || [];
        pages[page].push(item);
        return pages;
      }, {});

      const arr = Object.keys(this.reducedPages).map((pageNo) => {
        const page = this.reducedPages[pageNo]
        const obj = [];
        page.forEach((question) => {
          const questionNo = parseInt(question.field.split(".")[3]);
          const questionData =
            this.$store.state.survey.pages[pageNo].questions[questionNo];

          if (questionData){
            if (obj[questionNo])
              obj[questionNo].errors.push(question);
            else
            {
              obj[questionNo] = {errors: [question], displayNo: questionData.config.displayNo };
              obj[questionNo].displayNo = questionData.config.displayNo
            }
          }
        });
        return { page: parseInt(pageNo) + 1, questions: obj.sort((a, b)=> (+a.displayNo) > (+b.displayNo) ? 1 : -1).filter(a=> a) };
      });

      this.pagesWithErrors = arr;
    },
  },
};
</script>
