import { render, staticRenderFns } from "./UserSectorSelector--new.vue?vue&type=template&id=19c60a0d&scoped=true&"
import script from "./UserSectorSelector--new.vue?vue&type=script&lang=js&"
export * from "./UserSectorSelector--new.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19c60a0d",
  null
  
)

export default component.exports