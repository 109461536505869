<template>
  <div>
    <div class="form__input_actions flex flex-align-center flex-between">
      <div class="flex flex-align-center">
        <div class="flex flex-align-center">
          <icon-base icon-name="rating" width="19" height="6"
            ><icon-rating
          /></icon-base>
          <span class="form__input_type"> {{ displayNumber }}. Rating </span>
        </div>
        <div>
          <slot name="changeFieldType"></slot>
        </div>
      </div>
      <div class="flex flex-align-center">
        <slot name="hiddenQuestionInputs"></slot>
        <slot name="requiredField"></slot>
        <slot name="removeField"></slot>
      </div>
    </div>
    <question-label
    :pageNumber="pageNumber"
    :questionNumber="questionNumber"
    >
    </question-label>
    <div class="sml-padding-v-half">
      <div class="form__input--with-actions form__input--with-action-and-icon">
        <div
          class="form__input_clickable_icon pointer"
          @click="toggleRangeSelector('left')"
          v-click-outside="hideLeftRangeSelector"
        >
          <span class="form__input_icon--number">{{
            config.leftLabelVal || 0
          }}</span>
          <icon-base icon-name="chevron-down" width="13" height="7" className=""
            ><icon-chevron-down
          /></icon-base>
        </div>
        <div
          class="dropdown"
          :class="{ 'dropdown--is-visible': leftRangeSelectorActive }"
        >
          <div
            class="
              dropdown__menu dropdown__menu--left dropdown__menu--auto-width
            "
          >
            <div
              class="
                dropdown__menu_item dropdown__menu_item--shorter
                text-center
                pointer
              "
              :key="n"
              v-for="n in 11"
              @click="updateLabel('left', n - 1)"
            >
              {{ n - 1 }}
            </div>
          </div>
        </div>
        <input
          type="hidden"
          :name="`pages[${this.pageNumber}][questions][${this.questionNumber}][config][leftLabelVal]`"
          :value="config.leftLabelVal || 0"
        />
        <input
          type="text"
          class="form__input_label"
          placeholder="Label (optional)"
          :name="`pages[${this.pageNumber}][questions][${this.questionNumber}][config][leftLabel]`"
          @input="setLikertLabel($event, 'left')"
          v-model="config.leftLabel"
        />
      </div>
      <small v-show="errors.has(this.leftLabelValName)" class="danger">
          {{ errors.first(this.leftLabelValName) }}
      </small>
      <div class="form__input--with-actions form__input--with-action-and-icon">
        <div
          class="form__input_clickable_icon pointer"
          @click="toggleRangeSelector('right')"
          v-click-outside="hideRightRangeSelector"
        >
          <span class="form__input_icon--number">{{
            config.rightLabelVal || 10
          }}</span>
          <icon-base icon-name="chevron-down" width="13" height="7" className=""
            ><icon-chevron-down
          /></icon-base>
        </div>
        <div
          class="dropdown"
          :class="{ 'dropdown--is-visible': rightRangeSelectorActive }"
        >
          <div
            class="
              dropdown__menu dropdown__menu--left dropdown__menu--auto-width
            "
          >
            <div
              class="
                dropdown__menu_item dropdown__menu_item--shorter
                text-center
                pointer
              "
              :key="n"
              v-for="n in 11"
              @click="updateLabel('right', n - 1)"
            >
              {{ n - 1 }}
            </div>
          </div>
        </div>
        <input
          type="hidden"
          :name="`pages[${this.pageNumber}][questions][${this.questionNumber}][config][rightLabelVal]`"
          :value="config.rightLabelVal || 10"
        />
        <input
          type="text"
          class="form__input_label"
          placeholder="Label (optional)"
          :name="`pages[${this.pageNumber}][questions][${this.questionNumber}][config][rightLabel]`"
          @input="setLikertLabel($event, 'right')"
          v-model="config.rightLabel"
        />
      </div>
      <small v-show="errors.has(this.rightLabelValName)" class="danger">
          {{ errors.first(this.rightLabelValName) }}
      </small>
    </div>
    <div>
      <slot name="moveFieldUp"></slot>
      <slot name="moveFieldDown"></slot>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import Focus from "../../directives/focus";
import IconBase from "../icons/IconBase.vue";
import IconRating from "../icons/IconRating.vue";
import IconChevronDown from "../icons/IconChevronDown.vue";
import QuestionLabel from "./QuestionLabel.vue";

export default {
  name: "Rating",
  components: {
    IconBase,
    IconRating,
    IconChevronDown,
    QuestionLabel
  },
  inject: ["$validator"],
  props: {
    pageNumber: Number,
    questionNumber: Number,
    displayNumber: String | Number,
  },
  directives: {
    ClickOutside,
    Focus,
  },
  data() {
    return {
      labelValidationName: `pages.${this.pageNumber}.questions.${this.questionNumber}.config.label`,
      leftLabelValName: `pages.${this.pageNumber}.questions.${this.questionNumber}.config.leftLabelVal`,
      rightLabelValName: `pages.${this.pageNumber}.questions.${this.questionNumber}.config.rightLabelVal`,
      leftRangeSelectorActive: false,
      rightRangeSelectorActive: false,
    };
  },
  methods: {
    toggleRangeSelector(leftOrRight) {
      this[`${leftOrRight}RangeSelectorActive`] =
        !this[`${leftOrRight}RangeSelectorActive`];
    },
    hideLeftRangeSelector() {
      this.leftRangeSelectorActive = false;
    },
    hideRightRangeSelector() {
      this.rightRangeSelectorActive = false;
    },
    updateLabel(leftOrRight, val) {
      if (typeof(val) === 'number') {
        val = val.toString();
      }
      this.setLikertLabelVal(val, leftOrRight);
    },
    setQuestionLabel(e) {
      this.$store.commit({
        type: "setQuestionLabel",
        pageNumber: this.pageNumber,
        questionNumber: this.questionNumber,
        val: e.target.value,
      });
    },
    setLikertLabelVal(val, label) {
      this.$store.commit({
        type: "setLikertLabelVal",
        pageNumber: this.pageNumber,
        questionNumber: this.questionNumber,
        label,
        val,
      });
    },
    setLikertLabel(e, label) {
      this.$store.commit({
        type: "setLikertLabel",
        pageNumber: this.pageNumber,
        questionNumber: this.questionNumber,
        label,
        val: e.target.value,
      });
    },
  },
  computed: {
    inputs() {
      return this.$store.getters.questions(this.pageNumber);
    },
    config() {
      return this.$store.getters.config(this.pageNumber, this.questionNumber);
    },
  },
};
</script>
