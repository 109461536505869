const passwordStrength = (() => {
  const password = document.getElementById("password");
  const meter = document.getElementById("password-strength-meter");
  const text = document.getElementById("password-strength-text");

  const strength = {
    0: "Worst",
    1: "Bad",
    2: "Weak",
    3: "Good",
    4: "Strong",
  };

  function init() {
    if (password && meter && text) {
      password.addEventListener("input", () => {
        import("zxcvbn").then(({ default: zxcvbn }) => {
          const result = zxcvbn(password.value);
          meter.value = result.score;

          if (password.value !== "") {
            text.innerText = "Strength: " + strength[result.score];
          } else {
            text.innerText = "";
          }
        });
      });
    }
  }

  return {
    init,
  };
})();

export default passwordStrength;
