import h from './_helpers';

const vatFields = (() => {
  const isNotVatRegistered = document.getElementById('is_not_vat_registered');
  const vatNumberField = document.getElementById('vat_number_wrapper');

  function update() {
    if(isNotVatRegistered.checked) {
      vatNumberField.style.display = 'none';
    } else {
      vatNumberField.style.display = 'block';
    }
  }

  function bindUIEvents() {
    isNotVatRegistered.addEventListener('change', (e) => {
      e.preventDefault();
      update();
    });
  }

  function init() {
    if (vatNumberField && isNotVatRegistered) {
      update();
      bindUIEvents();
    }
  }

  return {
    init,
  };
})();

export default vatFields;
