<template>
  <div class="sml-push-top">
    <div v-if="!stateCustom">
      <button type="button" class="btn btn--secondary" @click="handleEntry">
        Add {{ type }}
      </button>
    </div>

    <div v-if="stateCustom" class="sml-push-top">
      <label class="required">Enter {{ type }}</label>
      <div class="admin-custom-selector__actions">
        <input
          v-bind:value="defaultValue"
          :id="id"
          :name="name"
          type="text"
          required
        />
        <button class="btn btn--secondary" @click="cancelEntry">Cancel</button>
        <button class="btn btn--primary">Save</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminCustomSelector",
  props: {
    label: {
      type: String,
    },
    name: {
      type: String,
      default: "name",
    },
    type: {},
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      stateDefaultValue: null,
      stateCustom: false,
    };
  },
  computed: {},
  created() {},
  methods: {
    handleEntry() {
      this.stateCustom = true;
    },
    cancelEntry() {
      this.stateCustom = false;
    },
  },
};
</script>

<style scoped>
.admin-custom-selector__actions {
  display: flex;
  gap: 2rem;
}
</style>
