<template>
  <td class="check-cell" v-if="data.type === 'checkbox'" @click="data.action">
    <div class="check" :class="{ selected }"></div>
  </td>
  <td v-else-if="data.type === 'link'">
    <a :href="data.action" class="cell--link">{{ data.label }}</a>
  </td>
  <td v-else-if="data.type === 'actions'">
    <UserManagementDatabaseActions
      :data="data.data"
    ></UserManagementDatabaseActions>
  </td>
  <td v-else>
    <span class="cell--label">{{ data.label }}</span>
    <span v-if="data.hidden" class="cell--hidden">{{ data.hidden }}</span>
  </td>
</template>

<script>
import UserManagementDatabaseActions from "../admin/user-management/UserManagementDatabaseActions.vue";

export default {
  name: "TableCell",
  components: {
    UserManagementDatabaseActions,
  },
  props: {
    data: {
      type: Object,
    },
    selected: {},
  },
  methods: {},
  created() {},
};
</script>
