<template>
  <section class="user-database">
    <user-database-filters
      :allocationOptions="allocationOptions"
      :allocationEnabled="allocationEnabled && stateSelectedItems.length > 0"
      :handleAllocation="handleAllocation"
      :activeFilters="stateActiveFilters"
      :otherActiveFilters="stateFilters"
      :filters="filters"
      :handleAddFilter="handleAddFilter"
      :handleFilter="handleFilter"
      :handleRemoveFilter="handleRemoveFilter"
      :searchString.sync="stateQueryString"
    ></user-database-filters>
    <div class="user-database__summary">
      <p
        v-if="
          !stateLoading &&
          Object.values(stateFilters).filter((filter) => !!filter).length > 0
        "
      >
        <span>{{ stateTotalItems }}</span> users meet the selected criteria.
      </p>
      <a @click="downloadUsers">Download as CSV</a>
    </div>

    <div class="user-database__table">
      <Table
        :loading="stateLoading"
        :headCells="
          columns.map((column) => {
            switch (column.type) {
              case 'checkbox':
                return '';
              case 'actions':
                return '';
              default:
                return column.label;
            }
          })
        "
        :items="[
          ...stateItems.map((item) => ({
            excluded: isExcluded(item),
            cells: [
              ...columns.map((column) => {
                switch (column.type) {
                  case 'checkbox':
                    return {
                      type: 'checkbox',
                      selected: isSelected(item),

                      action: () => handleSelect(item),
                    };
                  case 'link':
                    return {
                      label: column.value(item),
                      type: 'link',
                      action: column.action(item),
                    };
                  case 'actions':
                    return {
                      type: 'actions',
                      data: item,
                    };
                  default:
                    return {
                      label: column.value(item),
                      hidden: column.hidden,
                    };
                }
              }),
            ],
          })),
        ]"
      />
    </div>
    <div class="user-database__pagination">
      <a
        v-for="(page, index) in Array.from(Array(stateTotalPages).keys())"
        v-bind:key="index"
        :href="`?page=${page + 1}`"
        v-on:click="handlePage(page + 1, $event)"
        v-bind:class="{ selected: parseInt(statePage) === page + 1 }"
      >
        {{ page + 1 }}
      </a>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Table from "../table/Table.vue";
import IconBase from "../icons/IconBase.vue";
import IconAdd from "../icons/IconAdd";
import UserDatabaseFilters from "./UserDatabaseFilters.vue";

export default {
  name: "UserDatabase",
  components: {
    Table,
    IconBase,
    IconAdd,
    UserDatabaseFilters,
  },
  props: {
    allocationOptions: {},
    allocationEnabled: Boolean,
    columns: Array,
    submit: String,
    filters: Array,
    defaultFilters: {
      type: Object,
      default() {
        return {};
      },
    },
    updateSelectedItems: Function,
    selected: {
      type: Array,
      default() {
        return [];
      },
    },
    selectedStatuses: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      statePage: 1,
      stateTotalPages: 0,
      stateItems: [],
      stateTotalItems: 0,
      stateLoading: true,
      stateSelectedItems: [],
      stateExcludedItems: [],
      stateSelectedItemsStatus: {},
      stateActiveFilters: {},
      stateFilters: {},
      stateQueryString: "",
    };
  },
  watch: {
    stateQueryString(_) {
      this.handleSearch();
    }
  },
  created() {
    const page = new URLSearchParams(window.location.search).get("page");
    if (page) this.statePage = page;
    this.stateFilters = {
      ...this.defaultFilters,
    };
    this.handleSearch();
    if (this.previouslySelected)
      this.stateExcludedItems = this.previouslySelected;
    this.stateSelectedItems = this.selected;
    this.stateSelectedItemsStatus = this.selectedStatuses;
  },
  methods: {
    handlePage(page, e) {
      e.preventDefault();
      this.statePage = page;
      if (page > 1)
        window.history.pushState(
          {},
          "",
          `${window.location.pathname}?page=${page}`
        );
      else window.history.pushState({}, "", window.location.pathname);
      this.handleSearch();
    },
    handleSelect(item) {
      if (this.isSelected(item)) {
        this.stateItems = [...this.stateItems];
        this.stateSelectedItems = this.stateSelectedItems.filter(
          (a) => a !== item.id
        );
      } else {
        this.stateItems = [...this.stateItems];
        this.stateSelectedItems = [...this.stateSelectedItems, item.id];
      }
      if (this.updateSelectedItems)
        this.updateSelectedItems(this.stateSelectedItems);
    },
    isSelected(item) {
      return this.stateSelectedItems.indexOf(item.id) !== -1;
    },
    isExcluded(item) {
      return this.stateExcludedItems.indexOf(item.id) !== -1;
    },
    handleAllocation(survey) {
      const req = axios.post(`/admin/survey/${survey}/allocate`, {
        users: [...this.stateSelectedItems],
      });
      req.then((res) => {
        window.location.href = `/admin/survey/${survey}/`;
      });
    },
    handleSearch() {
      this.stateLoading = true;
      const req = axios.post("/admin/search/users", {
        query: this.stateQueryString || "*",
        filters: {
          ...this.stateFilters,
        },
        page: this.statePage,
      });
      req.then((res) => {
        const { last_page, data, total } = res.data.filtered;
        this.stateLoading = false;
        this.stateTotalPages = last_page;
        this.stateTotalItems = total;
        this.stateItems = res.data.filtered.data || res.data.filtered;
      });
    },
    handleFilter(e, uid) {
      this.stateActiveFilters = {
        ...this.stateActiveFilters,
        [uid]: { type: e.target.name, value: e.target.value },
      };
      this.statePage = 1;
      this.stateFilters = {
        ...this.stateFilters,
        [e.target.name]: Object.keys(this.stateActiveFilters)
          .map((activeFilter) => this.stateActiveFilters[activeFilter])
          .filter((activeFilter) => {
            return activeFilter.type === e.target.name;
          })
          .map((filter) => filter.value)
          .join(", "),
      };
      this.handleSearch();
    },
    handleAddFilter(item) {
      const uid = `${item.id}-${
        Object.keys(this.stateActiveFilters).length + 1 || 1
      }`;
      this.stateActiveFilters = {
        ...this.stateActiveFilters,
        [uid]: { type: item.id, value: [] },
      };

      if (!this.stateFilters[item.id]) {
        this.stateFilters = {
          ...this.stateFilters,
          [item.id]: JSON.stringify([]),
        };
      }
    },
    handleRemoveFilter(item, uid) {
      const { [uid]: removedItem, ...rest } = this.stateActiveFilters;
      this.stateActiveFilters = {
        ...rest,
      };
      this.statePage = 1;
      this.stateFilters = {
        ...this.stateFilters,
        [item.id]: Object.keys(this.stateActiveFilters)
          .map((activeFilter) => this.stateActiveFilters[activeFilter])
          .filter((activeFilter) => {
            return activeFilter.type === item.id;
          })
          .map((filter) => filter.value)
          .join(", "),
      };
      this.handleSearch();
    },
    downloadUsers() {
      const req = axios.post("/admin/search/export-users", {
        query: this.stateQueryString,
        filters: {
          ...this.stateFilters,
        },
      });
      req.then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: "text/csv" })
        );
        window.open(url);
      });
    },
  },
};
</script>
