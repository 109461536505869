<template>
  <div class="sml-push-top">
    <table class="table--default">
      <thead>
        <tr>
          <th></th>
          <th v-for="(column, index) in options[0].columns" v-bind:key="index">
            {{ column.label || column }}
          </th>
        </tr>
      </thead>
        <survey-table-radio-input 
          v-if="options[0].type === 'radio'"
          :options="options"
          :answer="answer"
          :questionId="id"
          @update="updateInput"
          ></survey-table-radio-input>
        <survey-table-multi-select-input
          v-if="options[0].type === 'multiselect'"
          :options="options"
          :answer="answer"
          :questionId="id"
          @update="updateInput"
        ></survey-table-multi-select-input>
        <survey-table-number-input 
          v-if="options[0].type === 'number'"
          :options="options"
          :answer="answer"
          :questionId="id"
          @update="updateInput"
        ></survey-table-number-input>
    </table>
    <input type="hidden" :value="response" :id="`questions[${id}]`"
      :name="`questions[${id}]`" class="testerino" />
  </div>
</template>

<script>
import SurveyTableMultiSelectInput from './SurveyTable/SurveyTableMultiSelectInput.vue';
import SurveyTableRadioInput from './SurveyTable/SurveyTableRadioInput.vue';
import SurveyTableNumberInput from './SurveyTable/SurveyTableNumberInput.vue';
export default {
  name: "SurveyTableInput",
  components: {
    SurveyTableMultiSelectInput,
    SurveyTableNumberInput,
    SurveyTableRadioInput
  },
  props: {
    id: {},
    survey: {},
    options: {},
    answer: {},
  },
  data() {
    return { stateRows: null, hasAnswer: false };
  },
  methods: {
    updateInput(value){
      this.stateRows = value.stateRows;
      this.hasAnswer = value.hasAnswer;
    }
  },
  computed: {
    response() {
      return this.hasAnswer ? JSON.stringify(this.stateRows) : null
    }
  }
};
</script>
