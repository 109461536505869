<template>
    <div>
        <input
            type="text"
            placeholder="Question"
            class="sml-push-top-half"
            :class="questionLabelHasErrors() ? 'is-invalid' : ''"
            :name="`pages[${this.pageNumber}][questions][${this.questionNumber}][config][label]`"
            :data-vv-name="`pages.${this.pageNumber}.questions.${this.questionNumber}.config.label`"
            data-vv-as="question"
            v-validate="{ required: true }"
            v-model="questionLabel"
            v-focus 
        />
        <small v-if="questionLabelHasErrors()" class="danger">
            Remember to enter your question
        </small>
    </div>
</template>

<script>
import Focus from "../../directives/focus";
import { setQuestionLabel } from "./QuestionTypesUtilityFunctions";

export default {
    name: "QuestionLabel",
    components: {},
    props: {
        pageNumber: Number,
        questionNumber: Number,    
    },
    inject: ["$validator"],
    directives: {
        Focus,
    },
    data() {
        return {};
    },
    methods: {
        setQuestionLabel,
        questionLabelHasErrors() {
            return this.errors.has(
                `pages.${this.pageNumber}.questions.${this.questionNumber}.config.label`
            )
        }
    },
    computed: {
        config() {
            return this.$store.getters.config(this.pageNumber, this.questionNumber);
        },
        questionLabel: {
            get() {
                return this.config.label
            },
            set(value) {
                this.setQuestionLabel(this.pageNumber, this.questionNumber, value)
            }
        },

    },
};
</script>
