import Stickyfill from 'stickyfilljs';

const sticky = (() => {
  function init() {
    const subnav = document.querySelector('.subnav');

    if (subnav) {
      Stickyfill.add(subnav);
    }
  }

  return {
    init,
  };
})();

export default sticky;
