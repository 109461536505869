import detectElementOverflow from 'detect-element-overflow';

const dropdown = ((document) => {
  const dropdowns = Array.from(document.querySelectorAll('.js-dropdown'));
  const visibleClass = 'dropdown--is-visible';

  function closeDropdown(dropdown) {
    dropdown.classList.remove(visibleClass);
  }

  function openDropdown(dropdown) {
    dropdown.classList.add(visibleClass);
  }

  function closeAll() {
    dropdowns.forEach((dropdown) => {
      closeDropdown(dropdown);
    });
  }

  function toggleDropdown(dropdown) {
    // If the clicked dropdown is open, close
    if (dropdown.classList.contains(visibleClass)) {
      closeDropdown(dropdown);
      // Otherwise, close everything else first
    } else {
      closeAll();
      openDropdown(dropdown);
    }
  }

  function bindUIEvents() {
    dropdowns.forEach((dropdown) => {
      const trigger = dropdown.querySelector('.js-dropdown__trigger');

      const { overflowWrapper } = dropdown.dataset;
      const menu = dropdown.querySelector('.js-dropdown__menu');
      if (overflowWrapper) {
        const overflowWrapperElem = menu.closest(overflowWrapper);
        if (overflowWrapperElem) {
          const overflowDetection = detectElementOverflow(menu, overflowWrapperElem);
          if (overflowDetection.collidedBottom) {
            menu.classList.add('dropdown__menu--up');
          }
        }
      }

      // Toggle menu on click
      trigger.addEventListener('click', toggleDropdown.bind(null, dropdown));

      dropdown.addEventListener('click', e => e.stopPropagation());
    });

    // Close all dropdown menus when we click away
    document.body.addEventListener('click', () => {
      closeAll();
    });
  }

  function init() {
    bindUIEvents();
  }

  return {
    init,
    closeDropdown,
    closeAll,
  };
})(document);

export default dropdown;
