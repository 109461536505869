<template>
    <div class="sml-padding-v-half">
      <small v-if="optionLabels.length > 1">
        Click and drag options to re-order
        <icon-base icon-name="reorder" width="12" height="11" className="">
          <icon-reorder />
        </icon-base>
      </small>
      <draggable v-model="optionLabels" @start="drag = true" @end="drag = false"
        :options="{ disabled: optionLabels.length < 2 }">
        <div class="form__input--with-actions sml-push-top" v-for="(option, index) in optionLabels" :key="index">
          <span v-if="showInputIndex" class="form__input-index">{{ index + 1 }}.</span>
          <input 
              v-validate="{ required: true }"
              type="text" 
              class="form__input_label"
              :class="optionHasErrors(index) ? 'is-invalid' : ''"
              :placeholder="`Option ${index + 1}`"
              :name="`pages[${pageNumber}][questions][${questionNumber}][config][options][${index}][label]`"
              :data-vv-name="`pages.${pageNumber}.questions.${questionNumber}.config.options.${index}.label`"
              :data-vv-as="`option ${index + 1}`"
              v-model="option.label"
              @input="setOptionLabel(pageNumber, questionNumber, index, option.label)"
              v-focus="optionLabels.length > 1" 
            />                                                                                                      
          <div class="form__input_remove" @click="removeOption(pageNumber, questionNumber, index)"
            v-show="optionLabels.length > 1">
            <icon-base icon-name="cross" width="11" height="11" className="">
              <icon-cross />
            </icon-base>
          </div>
        </div>
      </draggable>
      <div class="sml-push-top">
        <span class="pointer" @click="addOption(pageNumber, questionNumber)">
          <icon-base icon-name="plus" width="9" height="9">
            <icon-plus />
          </icon-base>
          Add option
        </span>
      </div>
    </div>
</template>

<script>
import draggable from "vuedraggable";
import Focus from "../../directives/focus";
import IconBase from "../icons/IconBase.vue";
import IconReorder from "../icons/IconReorder.vue";
import IconCross from "../icons/IconCross.vue";
import IconPlus from "../icons/IconPlus.vue";
import { addOption, removeOption, setOptionLabel,  setOptionLabels } from "./QuestionTypesUtilityFunctions";

export default {
  name: "QuestionOptions",
  components: {
    draggable,
    IconBase,
    IconReorder,
    IconCross,
    IconPlus
  },
  props: {
    pageNumber: Number,
    questionNumber: Number,
    showInputIndex: {
      required: false,
      default: false,
      type: Boolean
    },
    minimumOptionsLength: {
      required: false,
      default: 1,
      type: Number
    }
  },
  inject: ["$validator"],
  directives: {
    Focus,
  },
  data() {
    return {
      
    };
  },
  mounted() {
    if (this.config.options == 0) {
      for (let i = 0; i < this.minimumOptionsLength; i++) {
        this.addOption(this.pageNumber, this.questionNumber);
      }
    }
  },
  methods: {
    addOption,
    removeOption,
    setOptionLabel,
    setOptionLabels,
    optionHasErrors(index) {
      return this.errors.has(
        `pages.${this.pageNumber}.questions.${this.questionNumber}.config.options.${index}.label`
      );
    }
  },
  computed: {
    config() {
      return this.$store.getters.config(this.pageNumber, this.questionNumber);
    },
    optionLabels: {
      get() {
        return this.config.options
      },
      set(value) {
        this.setOptionLabels(this.pageNumber, this.questionNumber, value)
      }
    }
  },
};
</script>