import short from "short-uuid";
import { cloneDeep } from "lodash";

const surveyBuilderStore = {
  state: {
    pages: [],
    selected: {},
  },
  getters: {
    pages: (state) => state.pages,
    questions: (state) => (pageNumber) => state.pages[pageNumber].questions,
    config: (state) => (pageNumber, questionNumber) =>
      state.pages[pageNumber].questions[questionNumber].config,
  },
  mutations: {
    // Init the survey with previous survey config (used if editing survey)
    populateSurvey(state, payload) {
      state.pages = payload.pages;
    },
    setSelected(state, payload) {
      state.selected = payload.selected;
    },
    setPage(state, payload) {
      state.pages[payload.pageNumber] = { ...payload.page };
    },
    addPage(state, payload) {
      state.pages.push({
        ...payload.page,
      });
    },
    updatePageTitle(state, payload) {
      state.pages[state.selected.page.pageNumber].name = payload.val;
    },
    updatePageDescription(state, payload) {
      state.pages[state.selected.page.pageNumber].description = payload.val;
    },
    removePage(state, payload) {
      state.pages.splice(state.selected.page.pageNumber, 1);
    },
    changeFieldType(state, { pageNumber, questionType, questionNumber }) {
      const questions = this.getters.questions(pageNumber);
      const field = questions[questionNumber];

      field.type = questionType;

      // Clear out the 'config' object to avoid scenarios where e.g. a TextInput object still has
      // data in the options array.
      field.config = {
        label: "",
        options: [],
        children: [],
        uid: short.generate(),
        parentUID: field.config.parentUID,
        displayNo: field.config.displayNo
      };

      // Change field required value to 'on' or delete key to mirror how the value is stored in the DB
      if (field.validation.required) {
        field.validation.required = "on";
      } else {
        delete field.validation.required;
      }
    },
    addField(state, payload) {
      const previousQuestion = state.pages[payload.pageNumber].questions.findLast(q=> !q.is_probe);
      const setDisplayNo = () => {
        if (previousQuestion) {
          if (payload.parentUID) {
            const parentQuestion = state.pages[payload.pageNumber].questions.find(question => question.config.uid == payload.parentUID);
            if (parentQuestion){
              const currentParentQuestionCount = state.pages[payload.pageNumber].questions.filter(question => question.config.parentUID == payload.parentUID).length
              return `${parentQuestion.config.displayNo}.${currentParentQuestionCount + 1}`
            }
          }
          return `${Math.floor(previousQuestion.config.displayNo) + 1}`
        }
          return "1"
        }

      let config;

      if (payload.questionType === "Table") {
        config = {
          label: "",
          options: [{ columns: [ { label: "" }], rows: [ { label: "" }] }],
          uid: short.generate(),
          parentUID: payload.parentUID,
          validation: { required: false },
          displayNo: setDisplayNo(),
        };
      }
      else if(payload.questionType === "Checkbox"){
        config = {
          label: "",
          options: [],
          uid: short.generate(),
          parentUID: payload.parentUID,
          displayNo: setDisplayNo(),
          minSelections: null,
          maxSelections: null
        }
      }
      else {
        config = {
          label: "",
          options: [],
          uid: short.generate(),
          parentUID: payload.parentUID,
          displayNo: setDisplayNo(),
        };
      }

      state.pages[payload.pageNumber].questions.push({
        type: payload.questionType,
        validation: {},
        config,
        required: false,
        is_probe: payload.is_probe,
      });

      if (!payload.parentUID) {
        const reducedIndex = state.pages[payload.pageNumber].questions.filter((x) => {
          return !x.is_probe || !x.config.parentUID || x.config.parentUID == "root_survey_parent"
        }).length -1
        const index = state.pages[payload.pageNumber].questions.length - 1;
        state.selected = {
          page: { ...state.selected.page },
          question: {
            validation: {},
            config,
            questionNumber: index,
            originalIndex: index,
            newIndex: reducedIndex,
            type: payload.questionType,
          },
        };
      }
    },
    duplicateField(state, payload) {
      let arr = state.pages[state.selected.page.pageNumber].questions;
      arr.map((question) => {
        if (question.questionNumber > state.selected.question.questionNumber)
          return { ...question, questionNumber: question.questionNumber + 1 };
      });

      const {config, is_probe, type, validation} = cloneDeep(state.selected.question)
      const newQuestion = {
        is_probe,
        config: {
          label: config.label,
          options: config.options,
          uid: short.generate(),
        },
        questionNumber:
          state.pages[state.selected.page.pageNumber].questions.length,
        type,
        validation,
      };
      arr.push(newQuestion);

      if (state.selected.question.type === "Probed"){
        const childQuestions = arr.filter(x=> x.config.parentUID === state.selected.question.config.uid);
        childQuestions.forEach(question => {
          const newChildQuestion = cloneDeep(question)
          newChildQuestion.config.parentUID = newQuestion.config.uid;
          newChildQuestion.config.uid = short.generate();
          delete newChildQuestion.id;
          arr.push(newChildQuestion);
        });
      }
      const reducedIndex = state.pages[state.selected.page.pageNumber].questions.filter((x) => {
        return !x.is_probe || !x.config.parentUID || x.config.parentUID == "root_survey_parent" }).length - 1;
      newQuestion.newIndex = reducedIndex;
      state.pages[state.selected.page.pageNumber].questions = arr;
      state.selected = {
        ...state.selected,
        question:
          state.pages[state.selected.page.pageNumber].questions[
            newQuestion.questionNumber
          ],
      };
      this.commit("recalculateDisplayNo", {pageNumber: state.selected.page.pageNumber})
    },
    removeField(state, { pageNumber, questionNumber }) {
      const questions = this.getters.questions(pageNumber);
      const questionToRemove = questions[questionNumber]
      const { uid } = questionToRemove.config;
      const children = questions.filter(
        ({ config: { parentUID } }) => parentUID === uid
      );

      state.pages[pageNumber].questions = questions.filter(
        (question) =>
        !children.includes(question) && question.config.uid !== uid
        );
      },
    recalculateDisplayNo(state, payload){
      const questions = this.getters.questions(payload.pageNumber).filter(x=> !x.is_probe)
      for (let i = 0; i < questions.length; i++) {
        const question = questions[i];
        question.config.displayNo = `${i+1}`
        if (question.type === "Probed"){
          const questionChildren = this.getters.questions(payload.pageNumber).filter(q=> q.config.parentUID == question.config.uid);
          for (let ii = 0; ii < questionChildren.length; ii++) {
            const childQuestion = questionChildren[ii];
            childQuestion.config.displayNo = `${i+1}.${ii+1}`
          }
        }
      }
    },
    moveField(state, { pageNumber, questionNumber, direction }) {
      const questions = this.getters.questions(pageNumber);
      const question = questions[questionNumber];
      const questionParentUID = question.config.parentUID;

      // A slice of the questions either before or after that represent the target
      // set we're moving the questions within.
      const questionSet = (() => {
        if (direction === "up") {
          // Get the questions before the current question and swap the order.
          // This is so we can find the closest child going up the tree that isn't a sibling.
          return [...questions.slice(0, questionNumber)].reverse();
        } else if (direction === "down") {
          // Get the questions after the current question. We don't need to reverse it, since
          // '.find' will naturally find the first one we want.
          return questions.slice(questionNumber + 1, questions.length);
        }
        return questions;
      })();

      // The target item we'll be swapping with (i.e. the item immediately above or below).
      const matchingItem = questionSet.find(
        ({ config: { parentUID } }) => parentUID === questionParentUID
      );

      // If we don't have one, early exit. This shouldn't ever run, since the UI should remove the
      // buttons if we're at the top/end, but this acts as a safeguard.
      if (!matchingItem) return;

      // Get the index of the sibling within the flat question list
      const matchingIndex = questions.findIndex(
        ({ config: { uid: currentUID } }) =>
          matchingItem.config.uid === currentUID
      );

      // Cut the field we want to move
      const fieldToMove = questions.splice(questionNumber, 1)[0];

      const directionToMove =
        direction === "up" ? matchingIndex : questionNumber + 1;

      // Insert field at relevant index
      questions.splice(directionToMove, 0, fieldToMove);
    },
    setQuestionLabel(state, payload) {
      state.pages[payload.pageNumber].questions[
        payload.questionNumber
      ].config.label = payload.val;
    },
    toggleRequired(state, payload) {
      // Change value to 'on' or delete key to mirror DB structure
      state.pages[payload.pageNumber].questions[
        payload.questionNumber
      ].validation.required = payload.required ? "on" : "";

      if (!payload.required) {
        delete state.pages[payload.pageNumber].questions[payload.questionNumber]
          .validation.required;
      }
    },
    addOption(state, payload) {
      state.pages[payload.pageNumber].questions[
        payload.questionNumber
      ].config.options.push({
        label: "",
      });
    },
    setTableType(state, payload) {
      const config =
        state.pages[payload.pageNumber].questions[payload.questionNumber]
          .config;
      const options =
        state.pages[payload.pageNumber].questions[payload.questionNumber].config
          .options;
      state.pages[payload.pageNumber].questions[payload.questionNumber].config =
        { ...config, options: [{ ...options[0], type: payload.val }] };
    },
    setColumns(state, payload) {
      state.pages[payload.pageNumber].questions[
        payload.questionNumber
      ].config.options[0].columns = payload.columns;
    },
    setColumnLabel(state, payload){
      state.pages[payload.pageNumber].questions[
        payload.questionNumber
      ].config.options[0].columns[payload.columnNumber].label = payload.val;
    },
    removeColumn(state, payload) {
      const columns = state.pages[payload.pageNumber].questions[
        payload.questionNumber].config.options[0].columns
      columns.splice(payload.columnNumber, 1);
    },
    addColumn(state, payload) {
      const columns = state.pages[payload.pageNumber].questions[payload.questionNumber].config.options[0].columns;
      columns.push(payload.column)
    },
    setRows(state, payload) {
      state.pages[payload.pageNumber].questions[
        payload.questionNumber
      ].config.options[0].rows = payload.rows;
    },
    setRowLabel(state, payload){
      state.pages[payload.pageNumber].questions[
        payload.questionNumber
      ].config.options[0].rows[payload.rowNumber].label = payload.val;
    },
    removeRow(state, payload) {
      const rows = state.pages[payload.pageNumber].questions[
        payload.questionNumber].config.options[0].rows
      rows.splice(payload.rowNumber, 1);
    },
    addRow(state, payload) {
      const rows = state.pages[payload.pageNumber].questions[payload.questionNumber].config.options[0].rows;
      rows.push(payload.row)
    },
    removeOption(state, payload) {
      state.pages[payload.pageNumber].questions[
        payload.questionNumber
      ].config.options.splice(payload.optionNumber, 1);
    },
    setOptionLabel(state, payload) {
      state.pages[payload.pageNumber].questions[
        payload.questionNumber
      ].config.options[payload.optionNumber].label = payload.val;
    },
    setOptionLabels(state, payload) {
      state.pages[payload.pageNumber].questions[
        payload.questionNumber
      ].config.options = payload.val;
    },
    setMinOptions(state, payload) {
      state.pages[payload.pageNumber].questions[
        payload.questionNumber
      ].config.minSelections = payload.val;
    },
    setMaxOptions(state, payload) {
      state.pages[payload.pageNumber].questions[
        payload.questionNumber
      ].config.maxSelections = payload.val;
    },
    setLikertLabel(state, payload) {
      state.pages[payload.pageNumber].questions[payload.questionNumber].config[
        `${payload.label}Label`
      ] = payload.val;
    },
    setLikertLabelVal(state, payload) {
      state.pages[payload.pageNumber].questions[payload.questionNumber].config[
        `${payload.label}LabelVal`
      ] = payload.val;
    },
  },
  actions: {
    addField({ state, ...rest }, payload) {
      state.pages[payload.pageNumber].questions.push({
        type: payload.questionType,
        validation: {},
        config: {
          label: "",
          options: [],
          uid: short.generate(),
          parentUID: payload.parentUID,
        },
      });
    },
  },
};

export default surveyBuilderStore;
