<template>
  <div :class="computedWrapperClasses">
    <slot
      name="trigger"
      :isOpen="isOpen"
      :toggleOpen="toggleOpen"
    >
      <button
        @click.prevent="toggleOpen"
        :class="computedBtnClasses"
      >
        <slot name="title" :isOpen="isOpen"></slot>
      </button>
    </slot>
    <div v-show="isOpen" :class="contentClasses">
      <slot name="content" :isOpen="isOpen"></slot>
      <slot :isOpen="isOpen"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Collapsible',
    props: {
      defaultOpen: Boolean,
      triggerElemTag: String,
      contentClasses: {
        type: Array,
        default(){
          return ['collapsible-panel__content'];
        },
      },
      wrapperClasses: {
        type: Array,
        default(){
          return ['collapsible-panel'];
        },
      },
      btnClasses: {
        type: Array,
        default(){
          return ['btn', 'btn--full-width'];
        },
      },
      openClasses: {
        type: Array,
        default(){
          return ['collapsible-panel--is-open'];
        },
      },
      onChange: {
        type: Function,
        default: () => {},
      },
    },
    data() {
      return {
        isOpen: this.defaultOpen,
      };
    },
    computed: {
      computedBtnClasses() {
        return this.btnClasses;
      },
      computedWrapperClasses() {
        if (!this.isOpen) return this.wrapperClasses;
        return [].concat(this.wrapperClasses, this.openClasses);
      },
    },
    methods: {
      setOpen() {
        this.isOpen = true;
      },
      setClosed() {
        this.isOpen = false;
      },
      toggleOpen() {
        this.isOpen = !this.isOpen;
      },
    },
    watch: {
      isOpen() {
        this.onChange(this.isOpen);
      },
    },
  };
</script>
