<template>
  <section>

    <div class="respondent-back-btn">
      <a :href="`/admin/survey/${survey.uuid}/distribution`"
        >← Back to distribution</a
      >
    </div>
    <div class="respondent-header">
      <div class="respondent-overview">
        <div>
          <h3 class="profile__title">Country</h3>
          <h4>{{ sector.level.country.name }}</h4>
        </div>
        <div>
          <h3 class="profile__title">Level</h3>
          <h4>{{ sector.level.name }}</h4>
        </div>
        <div>
          <h3 class="profile__title">Sector</h3>
          <h4>{{ sector.name }}</h4>
        </div>
      </div>
      <div class="respondent-allocation">
        <div class="respondent-allocation__total">
          <div class="respondent-allocation__total-title">
            <span>Allocated</span> <span>Required</span>
          </div>
          <div class="respondent-allocation__total-requested" title="Requested">
            <span> {{ totalAllocations }} / {{ requiredAllocations }}</span>
            <span
              class="respondent-allocation__total-allocated"
              :style="`width:${
                totalAllocations / (requiredAllocations / 100)
              }%`"
            >
            </span>
          </div>
        </div>
        <button
          class="btn btn--primary submit-survey"
          v-on:click="handleAllocation"
        >
          Save allocation
        </button>
      </div>
    </div>
    <UserDatabase
      :handleSubmitSelected="handleAllocation"
      :updateSelectedItems="updateSelectedItems"
      :selected="allocatedRespondents"
      :selectedStatuses="allocatedRespondentsStatuses"
      :previouslySelected="previouslyAllocated"
      :filters="filters"
      :defaultFilters="{
        country: this.sector.level.country.name,
        locality: this.sector.level.name,
        sector: this.sector.name,
      }"
      :columns="[
        {
          type: 'checkbox',
          //action: (item) => handleSelect(item),
        },
        {
          label: 'Name',
          value: (item) => item.name,
          type: 'link',
          action: (item) => `/admin/user/profile/${item.id}/`,
          // TODO - implement proper JS redirect on profile page
          // action: `/admin/user/profile/${item.id}/?return=/admin/survey/${survey.uuid}/distribution/allocate/${sector.id}`,
        },
        {
          label: 'Role',
          value: (item) => item.role || '-',
          hidden:
            'Cannot be selected. This respondent was selected in a previous version of this survey',
        },
        {
          label: 'Status',
          value: (user) => this.stateSelectedItemsStatus[user.id] ? this.stateSelectedItemsStatus[user.id] : '—'
        },
        {
          label: 'Locality',
          value: (item) => item.locality_names.length ? displayFirstOrWithCounter(item.locality_names) : '-',
        },
        {
          label: 'Sector',
          value: (item) => item.sector_names.length ? displayFirstOrWithCounter(item.sector_names) : '-',
        },
        {
          label: 'Country',
          value: (item) => item.country_names.length ? displayFirstOrWithCounter(item.country_names) : '-',
        },
        {
          label: 'Tags',
          value: (item) =>
            item.tags.length
              ? item.tags
                  .map((tag) => {
                    return `#${tag.tag}`;
                  })
                  .join(', ')
              : '-',
        },
      ]"
    ></UserDatabase>
  </section>
</template>

<script>
import axios from "axios";
import Table from "../table/Table.vue";
import UserDatabase from "../database/UserDatabase.vue";
import { uniq } from "lodash";

export default {
  name: "RespondentDatabase",
  components: {
    Table,
    UserDatabase,
  },
  props: {
    sector: {},
    survey: {},
    submit: String,
    filters: Array,
    items: Array,
    totalAllocatedRespondents: Array,
    allocatedRespondents: Array,
    allocatedRespondentsStatuses: {},
    requiredAllocations: Number,
    previouslyAllocated: Array,
  },
  data() {
    return {
      stateSelectedItems: [],
      stateSelectedItemsStatus: {}
    };
  },
  computed: {
    totalAllocations() {
      return this.stateSelectedItems.length;
    },
    hasChanged() {
      // console.log(this.stateSelectedItems, this.allocatedRespondents);
      // return !this.stateSelectedItems.every((i) =>
      //   this.allocatedRespondents.includes(i)
      // );
    },
  },
  created() {
    this.stateSelectedItems = this.totalAllocatedRespondents;
    this.stateSelectedItemsStatus = this.allocatedRespondentsStatuses;
  },
  methods: {
    updateSelectedItems(selected) {
      this.stateSelectedItems = selected;
    },
    // TODO - refactor to avoid repetition with identicla UserDatabase function
    handleAllocation() {
      let arr = this.allocatedRespondents.filter(
        (item) => !this.totalAllocatedRespondents.includes(item)
      );

      const req = axios.post(`/admin/survey/${this.survey.uuid}/allocate`, {
        users: uniq([...this.stateSelectedItems, ...arr]),
      });
      req.then((res) => {
        window.location.href = `/admin/survey/${this.survey.uuid}/distribution`;
      });
    },
    displayFirstOrWithCounter(array) {
      if (array.length > 1) {
        return `${array[0]} (+${array.length - 1})`;
      }

      return array[0];
    }
  },
};
</script>
