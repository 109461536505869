<template>
  <span> ({{ total }}) </span>
</template>

<script>
export default {
  name: "Count",
  props: {
    slice: Object | Array,
  },
  methods: {
    /**
     * Recursively walks the tree and adds selected_count values of children.
     * This way you can pass a slice of state and have it automatically calculate the total.
     */
    addChildren(current = 0, path) {
      const { selected_count = 0, children } = path;

      const total = Number(selected_count) + current;

      if (children) {
        return children.reduce(
          (acc, child) => this.addChildren(acc, child),
          total
        );
      }

      return total;
    },
    getTotal(childIndex) {
      return this.addChildren(0, childIndex);
    },
  },
  computed: {
    total() {
      return this.getTotal(this.slice);
    },
  },
};
</script>
