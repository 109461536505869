<template>
  <div class="tableQuestion">
    <div class="form__input_actions flex flex-align-center flex-between">
      <div class="flex flex-align-center">
        <div class="flex flex-align-center">
          <icon-base icon-name="radio" width="19" height="20">
            <icon-radio />
          </icon-base>
          <span class="form__input_type"> {{ displayNumber }}. Table </span>
        </div>
        <div>
          <slot name="changeFieldType"></slot>
        </div>
      </div>
      <div class="flex flex-align-center">
        <slot name="hiddenQuestionInputs"></slot>
        <slot name="requiredField"></slot>
        <slot name="removeField"></slot>
      </div>
    </div>
    <question-label :pageNumber="pageNumber" :questionNumber="questionNumber">
    </question-label>
    <div>
      <div class="sml-push-top">
        <select v-model="tableType" v-focus 
          v-validate="{ required: true, included: ['radio', 'multiselect', 'number'] }"
          :name="`pages[${pageNumber}][questions][${questionNumber}][config][options][0][type]`"
          :data-vv-name="`pages.${pageNumber}.questions.${questionNumber}.config.options.0.type`"
          data-vv-as="table question type"
     >
          <option :value="null">Select type</option>
          <option value="radio">Radio</option>
          <option value="multiselect">Multi-select</option>
          <option value="number">Number</option>
        </select>
        <small v-if="errors.has(this.tableTypeValidationName)" class="danger">
            {{ errors.first(this.tableTypeValidationName) }}
        </small>
      </div>

      <div class="sml-push-top">
        <h5>Columns</h5>
        <small>
          Click and drag columns to re-order
          <icon-base icon-name="reorder" width="12" height="11" className="">
            <icon-reorder />
          </icon-base>
        </small>
        <draggable v-model="stateColumns" @start="drag = true" @end="drag = false"
          :options="{ disabled: stateColumns.length < 2 }">
          <div class="form__input--with-actions sml-push-top" v-for="(option, index) in stateColumns" :key="index">
              <input 
                v-validate="{ required: true }"
                type="text" 
                class="form__input_label"
                :class="columnHasErrors(index) ? 'is-invalid' : ''"
                :placeholder="`Column ${index + 1}`"
                :name="`pages[${pageNumber}][questions][${questionNumber}][config][options][0][column][${index}][label]`"
                :data-vv-name="`pages.${pageNumber}.questions.${questionNumber}.config.options.0.column.${index}.label`"
                :data-vv-as="`column ${index + 1}`"
                v-model="option.label"
                @input="setColumnLabel(index, option.label)"
                v-focus="stateColumns.length > 1" 
              />    
            <div class="form__input_remove" @click="removeColumn(index)" name="removeColumn" v-show="stateColumns.length > 2">
              <icon-base icon-name="cross" width="11" height="11" className="">
                <icon-cross />
              </icon-base>
            </div>
          </div>
        </draggable>
        <div class="sml-push-top">
          <span class="pointer" name="addColumn" @click="addColumn">
            <icon-base icon-name="plus" width="9" height="9">
              <icon-plus />
            </icon-base>
            Add column
          </span>
        </div>
      </div>

      <div class="sml-push-top">
        <h5>Rows</h5>
        <small>
          Click and drag rows to re-order
          <icon-base icon-name="reorder" width="12" height="11" className="">
            <icon-reorder />
          </icon-base>
        </small>
        <draggable v-model="stateRows" @start="drag = true" @end="drag = false"
          :options="{ disabled: stateRows.length < 2 }">
          <div class="form__input--with-actions sml-push-top" v-for="(option, index) in stateRows" :key="index">
              <input 
                v-validate="{ required: true }"
                type="text" 
                class="form__input_label"
                :class="rowHasErrors(index) ? 'is-invalid' : ''"
                :placeholder="`Row ${index + 1}`"
                :name="`pages[${pageNumber}][questions][${questionNumber}][config][options][0][row][${index}][label]`"
                :data-vv-name="`pages.${pageNumber}.questions.${questionNumber}.config.options.0.row.${index}.label`"
                :data-vv-as="`row ${index + 1}`"
                v-model="option.label"
                @input="setRowLabel(index, option.label)"
                v-focus="stateRows.length > 1" 
              />  
            <div class="form__input_remove" @click="removeRow(index)" name="removeRow" v-show="stateRows.length > 1">
              <icon-base icon-name="cross" width="11" height="11" className="">
                <icon-cross />
              </icon-base>
            </div>
          </div>
        </draggable>
        <div class="sml-push-top">
          <span class="pointer" name="addRow" @click="addRow">
            <icon-base icon-name="plus" width="9" height="9">
              <icon-plus />
            </icon-base>
            Add row
          </span>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import Focus from "../../directives/focus";
import IconBase from "../icons/IconBase.vue";
import IconRadio from "../icons/IconRadio.vue";
import IconAdd from "../icons/IconAdd.vue";
import IconRating from "../icons/IconRating.vue";
import IconNumber from "../icons/IconNumber.vue";
import IconCross from "../icons/IconCross.vue";
import IconPlus from "../icons/IconPlus";
import IconReorder from "../icons/IconReorder.vue";
import QuestionLabel from "./QuestionLabel.vue";

export default {
  name: "Table",
  components: {
    draggable,
    IconAdd,
    IconBase,
    IconCross,
    IconRadio,
    IconRating,
    IconNumber,
    IconPlus,
    IconReorder,
    QuestionLabel
  },
  inject: ["$validator"],
  directives: {
    Focus,
  },
  inject: ["$validator"],
  props: {
    pageNumber: Number,
    questionNumber: Number,
    displayNumber: String | Number,
  },
  data() {
    return {
      tableTypeValidationName: `pages.${this.pageNumber}.questions.${this.questionNumber}.config.options.0.type`
    };
  },
  methods: {
    columnHasErrors(index) {
      return this.errors.has(`pages.${this.pageNumber}.questions.${this.questionNumber}.config.options.0.column.${index}.label`)
    },
    rowHasErrors(index) {
      return this.errors.has(`pages.${this.pageNumber}.questions.${this.questionNumber}.config.options.0.row.${index}.label`)
    },
    setColumnLabel(index, val) {
      this.$store.commit({
        type: "setColumnLabel",
        pageNumber: this.pageNumber,
        questionNumber: this.questionNumber,
        columnNumber: index,
        val
      });
    },
    addColumn() {
      this.$store.commit({
        type: "addColumn",
        pageNumber: this.pageNumber,
        questionNumber: this.questionNumber,
        column: {
            label: "",
            type: "",
            edit: true,
        },
      });
    },
    removeColumn(index) {
      this.$store.commit({
        type: "removeColumn",
        pageNumber: this.pageNumber,
        questionNumber: this.questionNumber,
        columnNumber: index,
      });
    },
    setRowLabel(index, val) {
      this.$store.commit({
        type: "setRowLabel",
        pageNumber: this.pageNumber,
        questionNumber: this.questionNumber,
        rowNumber: index,
        val
      });
    },
    addRow() {
      this.$store.commit({
        type: "addRow",
        pageNumber: this.pageNumber,
        questionNumber: this.questionNumber,
        row: { label: "" },
      });
    },

    removeRow(index) {
      this.$store.commit({
        type: "removeRow",
        pageNumber: this.pageNumber,
        questionNumber: this.questionNumber,
        rowNumber: index,
      });
    }
  },
  computed: {
    config() {
      return this.$store.getters.config(this.pageNumber, this.questionNumber);
    },
    stateColumns: {
      get() {
        return this.config.options[0].columns;
      },
      set(value) {
        this.$store.commit({
          type: "setColumns",
          pageNumber: this.pageNumber,
          questionNumber: this.questionNumber,
          columns: value,
        });
      }
    },
    stateRows: {
      get() {
        return this.config.options[0].rows;
      },
      set(value) {
        this.$store.commit({
          type: "setRows",
          pageNumber: this.pageNumber,
          questionNumber: this.questionNumber,
          rows: value,
        });
      }
    },
    tableType: {
      get() {
        return this.config.options[0]?.type || null
      },
      set(val) {
        this.$store.commit({
          type: "setTableType",
          pageNumber: this.pageNumber,
          questionNumber: this.questionNumber,
          val,
      });
      }
    }
  },
};
</script>
