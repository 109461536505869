import hey from 'hey.js';
import _merge from 'lodash/merge';

const modal = ((document) => {
  const triggers = Array.from(document.querySelectorAll('.js-modal-trigger'));

  function create(elem) {
    const confirmYesClass = elem.getAttribute('data-modal-confirm-yes-class') || 'btn--danger';
    const confirmYesClasses = ['btn'].concat(confirmYesClass.split(' '));

    return hey(elem, {
      classes: {
        confirmYes: confirmYesClasses,
        confirmCancel: ['btn', 'btn--muted'],
      },
    });
  }

  function createBatch(elems) {
    elems.forEach(create);
  }

  function init() {
    createBatch(triggers);
  }

  return {
    init,
  };
})(document);

export default modal;
