<template>
    <tbody>
        <tr v-for="(row, rowIndex) in options[0].rows" v-bind:key="rowIndex">
            <td class="table-input__label">{{ row.label || row }}</td>
            <td v-for="(column, colIndex) in options[0].columns" v-bind:key="`row-${rowIndex}-col-${colIndex}`">
                <label class="table-input__label">
                    <input type="checkbox" class="radio" :checked="stateRows[rowIndex].selected.includes(colIndex)"
                        :value="colIndex" v-model="stateRows[rowIndex].selected"
                        :name="`question-${questionId}-row-${rowIndex}-col-${colIndex}`" v-on:change="updateTextValue(rowIndex, colIndex)" />
                    <span></span>
                </label>
            </td>
        </tr>
    </tbody>
</template>

<script>
import { find } from "lodash";
export default {
    name: "SurveyTableMultiSelectInput",
    props: {
        options: {},
        answer: {},
        questionId: ""
    },
    data() {
        return { stateRows: [] };
    },

    methods: {
        updateTextValue(index) {
            const current = this.stateRows[index];
            const newTextValue = current.selected.map((s) => this.options[0].columns[s]).join(", ")
            this.$set(this.stateRows[index], "textValue", newTextValue);
            this.updateParent();
        },
        updateParent() {
            this.$emit("update", {stateRows: this.stateRows, hasAnswer: this.hasAnswer});
        }
    },
    created() {
        const answer = this.answer;
        this.stateRows = this.options[0].rows.map((row, index) => {
            const prevAnswer = find(answer, { index: index });
            return {
                index,
                row,
                selected: prevAnswer?.selected || [],
                textValue: prevAnswer?.textValue ?? "N/A"
            };
        });
        this.updateParent();
    },
    computed: {
        hasAnswer() {
            return this.stateRows.some(x=> !!x.selected.length);
        }
    }
};
</script>
