<template>
  <div>
    <h3>
      Allocate respondents
      <span v-if="currentAllocatedRespondentIds && respondentList" class="pull-right">{{ currentAllocatedRespondentIds.length }} / {{ enabledRespondentList.length }}</span>
    </h3>
    <div v-if="parentAllocatedList.length">
      {{ parentAllocatedList.length }} respondent{{ parentAllocatedList.length !== 1 ? 's' : '' }}
      (marked with <font-awesome-icon :icon="parentAllocatedIcon" size="sm" class="danger"></font-awesome-icon>)
      were selected in a previous version of this survey and cannot be selected.
    </div>
    <div class="sml-padding-v section--keyline">
      <label>
        <input type="checkbox" v-model="selectAll">
        <span>Select all</span>
      </label>
    </div>
    <div class="sml-padding-v">
      <div v-for="(respondent, index) in respondentList" :key="index"
           class="payer_allocation_item flex flex-between flex-align-center"
           :class="borderClass(respondent)">
        <label class="flex flex-align-center flex-expand sml-padding-v-half">
            <div class="sml-c12 c5">
                <div>
                  <a v-if="isParentAllocated(respondent)" href="#" data-tooltip="This respondent was selected in the parent survey" @click.prevent>
                    <font-awesome-icon :icon="parentAllocatedIcon" size="sm" class="ml-1 danger"></font-awesome-icon>
                  </a>
                  <a v-else-if="isSelectedInOtherCategory(respondent)" href="#" data-tooltip="This respondent has already been selected in another category" @click.prevent>
                    <font-awesome-icon :icon="alreadySelectedIcon" size="sm" class="ml-1 success"></font-awesome-icon>
                  </a>
                  <input v-else name="payers[]" type="checkbox" :value="respondent.id" v-model="currentAllocatedRespondentIds" :disabled="isDisabled(respondent)">
                  <span :class="{disabled: isDisabled(respondent)}">{{ respondent.name }}</span>
                  <span v-if="respondent.custom_amount_requested" data-tooltip="This user has requested a special honorarium">
                    <font-awesome-icon class="danger" :icon="poundIcon" size="sm"></font-awesome-icon>
                  </span>
                  <div v-if="respondent.surveys_in_progress_count">
                    <small class="label--light">{{ respondent.surveys_in_progress_count }} survey(s)</small>
                  </div>
                </div>
            </div>
            <div class="sml-c12 c2">
              <span :class="statusClass('text', respondent.state)" v-if="respondent.state">
                {{ respondent.state.label }}
              </span>
            </div>
            <div class="sml-c12 c2" v-if="respondent.subcategory">
              <div class="small">
                {{ respondent.subcategory }}
              </div>
            </div>
            <div class="sml-c12 c3" v-if="respondent.category">
              <div class="small">
                {{ respondent.category }}
                <small class="text-muted" :data-tooltip="'This respondent\'s primary sector/category is ' + respondent.primary_category" v-if="!respondent.is_primary_category">(secondary)</small>
              </div>
            </div>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faUserCheck, faPoundSign, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

import _ from "lodash";
export default {
  name: 'RespondentAllocation',
  components: {
      FontAwesomeIcon
  },
  props: {
    category: {},
    respondents: Array,
    allocatedRespondents: Array,
    parentAllocatedRespondents: Array
  },
  data() {
    return {
      currentAllocatedRespondentIds: [],
      poundIcon: faPoundSign,
      parentAllocatedIcon: faTimesCircle,
      alreadySelectedIcon: faUserCheck
    };
  },
  computed: {
    respondentList() {
      return this.respondents.map((respondent) => {
        respondent.state = respondent.state || {};
        return respondent;
      });
    },
    enabledRespondentList() {
        return this.respondentList.filter(respondent => !this.isDisabled(respondent));
    },
    parentAllocatedList() {
        return this.respondentList.filter(respondent => this.isParentAllocated(respondent));
    },
    selectAll: {
      get: function() {
        return this.enabledRespondentList.length ? this.currentAllocatedRespondentIds.length === this.enabledRespondentList.length : false;
      },
      set: function(value) {
        let selectedDisabledRespondents = this.respondentList
            .filter(respondent => this.currentAllocatedRespondentIds.indexOf(respondent.id) !== -1 && this.isDisabled(respondent));

        this.currentAllocatedRespondentIds = (value ? [...selectedDisabledRespondents, ...this.enabledRespondentList] : selectedDisabledRespondents)
          .map(({ id }) => id);
      },
    },
  },
  created() {
    let enabledRespondentIdList = _.map(this.enabledRespondentList, 'id');
    this.currentAllocatedRespondentIds = _(this.allocatedRespondents)
      .filter(allocatedRespondent => enabledRespondentIdList.indexOf(allocatedRespondent.user_id) !== -1)
      .map('user_id')
      .value()
  },
  methods: {
    isSelectedInOtherCategory(payerSurvey) {
      let payerAllocatedRespondent = _.find(this.allocatedRespondents, ['user_id', payerSurvey.id]);
      return this.category && payerAllocatedRespondent && payerAllocatedRespondent.category_id !== this.category.id;
    },
    isDisabled: function (payerSurvey) {
      if (this.isParentAllocated(payerSurvey) || this.isSelectedInOtherCategory(payerSurvey)) {
          return true;
      }
      if (!payerSurvey.state) {
        return false;
      }
      return ['survey_completed', 'invitation_declined'].indexOf(payerSurvey.state.name) !== -1;
    },
    isParentAllocated: function(payerSurvey) {
        return this.parentAllocatedRespondents.indexOf(payerSurvey.id) !== -1;
    },
    borderClass: function(payerSurvey) {
      let borderClass = [];
      if (payerSurvey.state) {
        borderClass.push('payer_allocation_status_border--' + payerSurvey.state.name);
      }
      if (this.isDisabled(payerSurvey)) {
          borderClass.push('payer_allocation_item--disabled');
          borderClass.push('cursor-not-allowed');
      }
      return borderClass;
    },
    statusClass: function (effect, payerSurvey) {
      return 'small payer_allocation_status_' + effect + '--' + payerSurvey.name;
    },
    stopEvent(event) {
        // prevent click in certain areas from turning deselecting
        event.preventDefault();
        event.stopPropagation()
    }
  }
};
</script>

<style scoped>
label {
  margin-bottom: 0;
}

.label--light {
  margin-left: 26px;
}

.payer_allocation_item__name {
  margin-right: auto;
}
</style>
