/* eslint-disable no-new */
import Vue from "vue";
import VeeValidate from "vee-validate";
import VTooltip from "v-tooltip";
import PortalVue from "portal-vue";
import "es6-promise/auto";
import "classlist-polyfill";
import "element-closest";
import "object_values";
import "core-js/fn/array/from";
import "./polyfills/_matches";
import cardAction from "./partials/_card-action";
import additionalUserFields from "./partials/_additional-user-fields";
import dropdown from "./partials/_dropdowns";
import toggleShow from "./partials/_toggle_show";
import fileUpload from "./partials/_file-upload";
import formSubmitDelete from "./partials/_form-submit-delete";
import formDirty from "./partials/_form-dirty";
import hamburger from "./partials/_hamburger";
import modal from "./partials/_modal";
import sticky from "./partials/_sticky";
import vatFields from "./partials/_vat-fields";
import timezone from "./partials/_timezone";
import formAutosave from "./partials/_form-autosave";
import passwordStrength from "./partials/_password-strength";
import stt from "./partials/_stt";
import store from "./store";
import Matrix from "./components/payer_distribution/Matrix.vue";
import RespondentAllocation from "./components/payer_allocation/RespondentAllocation.vue";
import TagList from "./components/tags/TagList.vue";
import HonorariumAllocation from "./components/payer_allocation/HonorariumAllocation.vue";
import UserSectorSelector from "./components/admin/user/UserSectorSelector--new.vue";
import BankAccount from "./components/bank_account/BankAccount.vue";
import UserOrganisationRoleInput from "./components/admin/user/UserOrganisationRoleInput.vue";
import MultiSelectField from "./components/form/MultiSelectField.vue";
import RespondentDatabase from "./components/payer_allocation/RespondentDatabase.vue";
import AdminCustomSelector from "./components/settings/AdminCustomSelector.vue";
import AdminCustomCurrencySelector from "./components/settings/AdminCustomCurrencySelector.vue";
import UserManagementDatabase from "./components/admin/user-management/UserManagementDatabase.vue";
import SurveyRankingInput from "./components/survey/SurveyRankingInput.vue";
import SurveyTableInput from "./components/survey/SurveyTableInput.vue";
import SurveyBuilder from "./components/survey/SurveyBuilder.vue";
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import VueLogger from 'vuejs-logger';

const appEnvIsProduction = process.env.APP_ENV === 'production';

if (process.env.BUGSNAG_API_KEY){
  Bugsnag.start({
    apiKey: process.env.BUGSNAG_API_KEY_JS,
    plugins: [new BugsnagPluginVue()]
  });

  const bugsnagVue = Bugsnag.getPlugin('vue');
  bugsnagVue.installVueErrorHandler(Vue);
}

const vueLoggerOptions = {
    isEnabled: true,
    logLevel : appEnvIsProduction ? 'error' : 'debug',
    stringifyArguments : false,
    showLogLevel : true,
    showMethodName : true,
    separator: '|',
    showConsoleColors: true
};

Vue.use(VueLogger, vueLoggerOptions);
Vue.use(VTooltip);
Vue.use(PortalVue);

(() => {
  cardAction.init();
  additionalUserFields.init();
  dropdown.init();
  toggleShow.init();
  fileUpload.init();
  hamburger.init();
  modal.init();
  formSubmitDelete.init();
  formDirty.init();
  sticky.init();
  vatFields.init();
  timezone.init();
  passwordStrength.init();
  stt.init();
})();

// Only initialise Vue if the page is a survey builder or payer distribution matrix
if (
  document.getElementById("survey-page-builder") ||
  document.getElementById("survey-builder")
) {
  Vue.use(VeeValidate, {
    events: "change",
    inject: false,
  });

  VeeValidate.Validator.extend("num_questions", {
    getMessage: (field) => "You must add at least one question.",
    validate: (value) => value > 0,
  });

  VeeValidate.Validator.extend("num_probe_questions", {
    getMessage: (field) => "Probes must contain at least one question.",
    validate: (value) => value > 0,
  });
}

if (document.getElementById("payer-distribution")) {
  new Vue({
    el: "#payer-distribution",
    store,
    components: {
      matrix: Matrix,
    },
  });
}

if (document.getElementById("user-management-database")) {
  new Vue({
    el: "#user-management-database",
    store,
    components: {
      "user-management-database": UserManagementDatabase,
    },
  });
}

if (document.getElementById("respondent-database")) {
  new Vue({
    el: "#respondent-database",
    store,
    components: {
      "respondent-database": RespondentDatabase,
    },
  });
}

if (document.getElementById("payer-allocation")) {
  new Vue({
    el: "#respondent-database",
    store,
    components: {
      "respondent-database": RespondentDatabase,
    },
  });
}

if (document.getElementById("payer-allocation")) {
  new Vue({
    el: "#payer-allocation",
    store,
    components: {
      "respondent-allocation": RespondentAllocation,
    },
  });
}

if (document.getElementById("honorarium-allocation")) {
  new Vue({
    el: "#honorarium-allocation",
    store,
    components: {
      HonorariumAllocation,
    },
  });
}

if (document.getElementById("tag-list")) {
  new Vue({
    el: "#tag-list",
    store,
    components: {
      "tag-list": TagList,
    },
  });
}

if (document.getElementById("user-sector-selector")) {
  new Vue({
    el: "#user-sector-selector",
    store,
    components: {
      UserSectorSelector,
    },
  });
}

if (document.querySelectorAll("#admin-custom-selector")) {
  document.querySelectorAll("#admin-custom-selector").forEach(
    (el) =>
      new Vue({
        el,
        store,
        components: {
          AdminCustomSelector,
        },
      })
  );
}

if (document.querySelectorAll("#admin-custom-currency-selector")) {
  document.querySelectorAll("#admin-custom-currency-selector").forEach(
    (el) =>
      new Vue({
        el,
        store,
        components: {
          AdminCustomCurrencySelector,
        },
      })
  );
}

if (document.getElementById("user-organisation-role-input")) {
  new Vue({
    el: "#user-organisation-role-input",
    store,
    components: {
      UserOrganisationRoleInput,
    },
  });
}

if (document.querySelectorAll("#multi-select-field").length) {
  document.querySelectorAll("#multi-select-field").forEach((el) => {
    new Vue({
      el,
      store,
      components: {
        MultiSelectField,
      },
    });
  });
}

if (document.getElementById("user-category-selector")) {
  new Vue({
    el: "#user-category-selector",
    store,
    components: {
      UserSectorSelector,
    },
  });
}

if (document.getElementById("bank-account")) {
  new Vue({
    el: "#bank-account",
    store,
    components: {
      BankAccount,
    },
  });
}

if (document.querySelectorAll(".survey-ranking-input").length) {
  document.querySelectorAll(".survey-ranking-input").forEach(
    (el) =>
      new Vue({
        el,
        store,
        components: {
          SurveyRankingInput,
        },
      })
  );
}

if (document.querySelectorAll(".survey-table-input").length) {
  document.querySelectorAll(".survey-table-input").forEach(
    (el) =>
      new Vue({
        el,
        store,
        components: {
          SurveyTableInput,
        },
      })
  );
}

if (document.getElementById("survey-builder")) {
  new Vue({
    el: "#survey-builder",
    store,
    components: {
      SurveyBuilder,
    },
  });
}

if (document.querySelector('.js-respond-form')) {
  formAutosave.init();
}
