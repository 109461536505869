<template>
  <div>
    <h3>Allocate respondents honorarium</h3>
    <div class="flex flex-align-center flex-expand show hide-sml">
      <div class="table-head c4">Respondent</div>
      <div class="table-head c1">Type / Level</div>
      <div class="table-head c2">Sector / Category</div>
      <div class="table-head c4">Honorarium</div>
      <div class="table-head c1">Total Cost</div>
    </div>
    <div v-for="respondentSection in respondentSections">
      <div
        v-for="user in respondentSection.users"
        class="payer_allocation_item flex flex-between flex-align-center"
      >
        <label
          class="flex flex-align-center flex-expand sml-padding-v-half mb-0"
        >
          <div class="sml-c12 c4">
            <div>
              <span v-text="user.name"></span>
              <span
                v-if="user.custom_amount_requested"
                class="danger"
                data-tooltip="This user has requested a special honorarium"
              >
                <font-awesome-icon
                  class="danger"
                  :icon="poundIcon"
                  size="sm"
                ></font-awesome-icon>
              </span>
            </div>
          </div>
          <div class="sml-c12 c1 small" v-text="user.subcategory"></div>
          <div class="sml-c12 c2 small" v-text="user.category"></div>
          <div class="sml-c12 c4 flex flex-align-center">
            <!--
            <div v-if="user.rate_mode === 'question'">
              <input
                class="with-prefix"
                :placeholder="user.default_rate"
                :name="'offered_question_rate[' + user.id + ']'"
                type="number"
                step="1"
                min="0"
                v-model.number="user.offered_question_rate"
              />
              <div class="input-prefix" v-text="user.currency_symbol"></div>
            </div>
            -->

            <div v-if="user.rate_mode === 'question'">
              <input
                :class="
                  user.currency_symbol.length === 1
                    ? 'prefixed-input-symbol'
                    : 'prefixed-input-code'
                "
                :placeholder="user.default_rate"
                :name="'offered_question_rate[' + user.id + ']'"
                type="number"
                step="1"
                min="0"
                v-model.number="user.offered_question_rate"
              />
              <div class="prefix" v-text="user.currency_symbol"></div>
            </div>

            <div v-if="user.rate_mode === 'survey'">
              <input
                :class="
                  user.currency_symbol.length === 1
                    ? 'prefixed-input-symbol'
                    : 'prefixed-input-code'
                "
                :placeholder="user.default_rate * totalQuestions"
                :name="'offered_survey_rate[' + user.id + ']'"
                type="number"
                step="1"
                min="0"
                v-model.number="user.offered_survey_rate"
                required
              />
              <div class="prefix" v-text="user.currency_symbol"></div>
            </div>

            <input
              type="hidden"
              :value="user.rate_mode"
              :name="'rate_mode[' + user.id + ']'"
            />
            <label class="auto-width mb-0 ml-2">
              <input
                type="checkbox"
                v-model="user.rate_mode"
                true-value="question"
                false-value="survey"
              />
              <span>per question</span>
            </label>
          </div>
          <div class="sml-c12 c1 flex flex-align-center">
            {{ user.currency_symbol }}{{ getRespondentCost(user) }}
          </div>
        </label>
      </div>
    </div>
    <div class="flex flex-between flex-align-center">
      <div class="c11"></div>
      <div class="c1 mb-1"><strong>Total</strong></div>
      <div class="c11"></div>
      <div class="c1">
        <strong>{{ totalCosts }}</strong>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faPoundSign } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";

export default {
  name: "HonorariumAllocation",
  components: {
    FontAwesomeIcon,
  },
  props: {
    payers: {
      required: true,
      type: Array,
    },
    clinical: {
      required: true,
      type: Array,
    },
    totalQuestions: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      poundIcon: faPoundSign,
      statePayers: [],
      stateClinical: [],
    };
  },
  computed: {
    respondentSections() {
      return [
        { title: "Payers", users: this.statePayers },
        { title: "Clinical", users: this.stateClinical },
      ];
    },
    totalCosts() {
      let totalsByCurrency = {};

      [...this.statePayers, ...this.stateClinical].forEach((respondent) => {
        if (totalsByCurrency[respondent.currency_symbol] === undefined) {
          totalsByCurrency[respondent.currency_symbol] = 0;
        }
        totalsByCurrency[respondent.currency_symbol] +=
          this.getRespondentCost(respondent);
      });

      return _.map(
        Object.keys(totalsByCurrency),
        (symbol) => symbol + totalsByCurrency[symbol].toString()
      ).join(" + ");
    },
  },
  created() {
    this.statePayers = this.payers;
    this.stateClinical = this.clinical;
  },
  methods: {
    getRespondentCost(respondent) {
      if (respondent.rate_mode === "question") {
        if (
          respondent.offered_question_rate !== null &&
          respondent.offered_question_rate !== ""
        ) {
          return respondent.offered_question_rate * this.totalQuestions;
        } else {
          return respondent.default_rate * this.totalQuestions;
        }
      }
      if (respondent.rate_mode === "survey") {
        return respondent.offered_survey_rate;
      }
    },
  },
};
</script>

<style scoped>
.prefixed-input-symbol {
  padding-left: 35px;
  position: relative;
}

.prefixed-input-code {
  padding-left: 55px;
  position: relative;
}

.prefix {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding: 0 15px;
}
</style>
