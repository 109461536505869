const formDirty = (() => {
  const forms = Array.from(document.querySelectorAll('.js-form-dirty'));
  const modalTriggers = Array.from(document.querySelectorAll('.js-modal-trigger'));
  const disableDirties = Array.from(document.querySelectorAll('.js-form-dirty-disable'));
  let isDirty = false;

  function confirmLeave(e) {
    if (!isDirty) {
      return '';
    }

    const msg = 'Are you sure you want to navigate away? ' +
    'Any unsaved data will be lost.';

    e.preventDefault();
    e.returnValue = msg;

    return msg;
  }

  function setIsDirty() {
    isDirty = true;
  }

  function unsetDirty() {
    isDirty = false;
  }

  function onSubmit() {
    window.removeEventListener('beforeunload', confirmLeave);
  }

  function bindUIEvents() {
    forms.forEach((form) => {
      form.addEventListener('change', setIsDirty);
      form.addEventListener('submit', onSubmit);
    });

    modalTriggers.forEach((modalTrigger) => {
      modalTrigger.addEventListener('click', unsetDirty);
    });

    disableDirties.forEach((disableDirty) => {
      disableDirty.addEventListener('click', unsetDirty);
    });

    if (forms.length > 0) {
      window.addEventListener('beforeunload', confirmLeave);
    }
  }

  function init() {
    bindUIEvents();
  }

  return {
    init,
  };
})();

export default formDirty;
