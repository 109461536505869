import mic from "microphone-stream";

const stt = (() => {
  const audioUtils        = require('./_audioUtils');
  const marshaller        = require("@aws-sdk/eventstream-marshaller"); // for converting binary event stream messages to and from JSON
  const util_utf8_node    = require("@aws-sdk/util-utf8-node"); // utilities for encoding and decoding UTF8

  let eventStreamMarshaller = new marshaller.EventStreamMarshaller(util_utf8_node.toUtf8, util_utf8_node.fromUtf8);

  const start = document.querySelector('.js-stt-start');
  const stop = document.querySelector('.js-stt-stop');
  const status = document.querySelector('.js-stt-status');
  const language = document.querySelector('.js-stt-language');
  const mode = document.querySelector('.js-stt-mode');
  const debugMessages = document.querySelector('.js-stt-debug');

  let socket, url, inputSampleRate, sampleRate, micStream, target, languageCode;
  let transcription = "";

  function debug(message) {
    if (debugMessages) {
      const m = document.createElement('p');
      if (m) {
        m.innerText = message;
        debugMessages.prepend(m)
      }
    }
  }

  function stopStt(event) {
    event.preventDefault();
    debug('stopStt()');

    showStartButton();
    closeSocket();
    if (transcription !== "") {
      transcription += ' ';
    }
    if (status) {
      status.innerText = "";
    }
  }

  function startStt(event) {
    event.preventDefault();
    debug('startStt()');

    if (mode.options[mode.selectedIndex].value === "medical") {
      languageCode = "en-US";
    } else {
      languageCode = language.options[language.selectedIndex].value;
    }

    stopStt(event);

    if (status) {
      status.innerText = "Please wait...";
    }

    // First see if we have a target field
    const targetSelector = event.target.dataset.target;
    if (targetSelector) {
      target = document.querySelector(targetSelector);

      if (target) {
        fetch("/stt-url?language=" + languageCode)
          .then(response => response.json())
          .then(data => {
            url = data.url;
            debug('Pre signed URL: ' + url);
            startStreaming();
          })
      } else {
        debug('Target field ' + targetSelector + ' not found')
      }
    }  else {
      debug('Not target selector is empty');
    }
  }

  function startStreaming() {
    debug('startStreaming()');
    window.navigator.mediaDevices.getUserMedia({
      video: false,
      audio: true
    })
      // ...then we convert the mic stream to binary event stream messages when the promise resolves
      .then(streamAudioToWebSocket)
      .catch(function (error) {
        console.log(error);
        console.log('There was an error streaming your audio to Amazon Transcribe. Please try again.');
        debug('Streaming error!');
        debug(error);
        // toggleStartStop();
      });
  }

  function streamAudioToWebSocket(userMediaStream) {
    debug('streamAudioToWebSocket()');
    //let's get the mic input from the browser, via the microphone-stream module
    micStream = new mic();

    micStream.on("format", function (data) {
      inputSampleRate = data.sampleRate;
    });

    micStream.setStream(userMediaStream);


    //open up our WebSocket connection
    socket = new WebSocket(url);
    socket.binaryType = "arraybuffer";

    sampleRate = 8000;
    if (languageCode === "en-US" || languageCode === "es-US") {
      sampleRate = 41000;
    }

    // when we get audio data from the mic, send it to the WebSocket if possible
    socket.onopen = function () {
      micStream.on('data', function (rawAudioChunk) {
          // the audio stream is raw audio bytes. Transcribe expects PCM with additional metadata, encoded as binary
          let binary = convertAudioToBinaryMessage(rawAudioChunk);

          if (socket.readyState === socket.OPEN) {
            socket.send(binary);
          }
        }
      );
      showStopButton();
      if (status) {
        status.innerText = 'LISTENING! ' + languageCode + '@' + sampleRate + '. Please dictate your answer normally, click "Stop listening" when you are done';
      }
      debug('Listening');
    };

    // handle messages, errors, and close events
    socket.onmessage = function (message) {
      //convert the binary event stream message to JSON
      let messageWrapper = eventStreamMarshaller.unmarshall(Buffer(message.data));
      let messageBody = JSON.parse(String.fromCharCode.apply(String, messageWrapper.body));
      if (messageWrapper.headers[":message-type"].value === "event") {
        handleEventStreamMessage(messageBody);
      } else {
        console.log('Not event');
        console.log(messageBody);
        debug('Socket (not event): ' + messageBody);
      }
    };

    socket.onerror = function (event) {
      console.log('WebSocket connection error. Try again.');
      debug('Websocket connection error: ' + event);
    };

    socket.onclose = function (closeEvent) {
      debug('Websocket onclose called: ' + closeEvent.code + ' - ' + closeEvent.reason);
      micStream.stop();

      // the close event immediately follows the error event; only handle one.
      // if (!socketError && !transcribeException) {
        if (closeEvent.code !== 1000) {
          console.log('</i><strong>Streaming Exception</strong><br>' + closeEvent.reason);
          showStopButton();
        }
      // }
    };
  }

  function closeSocket () {
    if (socket) {
      debug('closeSocket() -- Socket exists');
      if (socket.readyState === socket.OPEN) {
        micStream.stop();

        // Send an empty frame so that Transcribe initiates a closure of the WebSocket after submitting all transcripts
        let emptyMessage = getAudioEventMessage(Buffer.from(new Buffer([])));
        let emptyBuffer = eventStreamMarshaller.marshall(emptyMessage);
        socket.send(emptyBuffer);

        showStartButton();
      } else {
        debug('closeSocket(): Socket was not open');
      }
    }
  }

  function handleEventStreamMessage (messageJson) {
    let results = messageJson.Transcript.Results;

    if (results.length > 0) {
      if (results[0].Alternatives.length > 0) {
        let transcript = results[0].Alternatives[0].Transcript;

        // fix encoding for accented characters
        transcript = decodeURIComponent(escape(transcript));

        // update the textarea with the latest result
        // $('#transcript').val(transcription + transcript + "\n");
        if (target) {
          target.innerText = transcription + transcript + ' \r\n';
        }

        // if this transcript segment is final, add it to the overall transcription
        if (!results[0].IsPartial) {
          //scroll the textarea down
          // $('#transcript').scrollTop($('#transcript')[0].scrollHeight);

          transcription += transcript + ' \r\n';
        }
      }
    }
  }


  function convertAudioToBinaryMessage(audioChunk) {
    let raw = mic.toRaw(audioChunk);

    if (raw == null)
      return;

    // downsample and convert the raw audio bytes to PCM
    let downsampledBuffer = audioUtils.downsampleBuffer(raw, inputSampleRate, sampleRate);
    let pcmEncodedBuffer = audioUtils.pcmEncode(downsampledBuffer);

    // add the right JSON headers and structure to the message
    let audioEventMessage = getAudioEventMessage(Buffer.from(pcmEncodedBuffer));

    //convert the JSON object + headers into a binary event stream message
    return eventStreamMarshaller.marshall(audioEventMessage);
  }

  function getAudioEventMessage(buffer) {
    // wrap the audio data in a JSON envelope
    return {
      headers: {
        ':message-type': {
          type: 'string',
          value: 'event'
        },
        ':event-type': {
          type: 'string',
          value: 'AudioEvent'
        }
      },
      body: buffer
    };
  }

  function toggleLanguagePicker() {
    debug('toggleLanguagePicker()');
    let selectedMode = mode.options[mode.selectedIndex].value;
    if (selectedMode === "medical") {
      language.setAttribute('style', 'display: none;');
    } else {
      language.setAttribute('style', '');
    }
  }

  function showStartButton() {
    debug('showStartButton()');
    start.setAttribute('style', '');
    stop.setAttribute('style', 'display: none;');
  }

  function showStopButton() {
    debug('showStopButton()');
    stop.setAttribute('style', '');
    start.setAttribute('style', 'display: none;');
  }

  function bindUIEvents() {
    debug('bindUIEvents()');

    if (window.navigator.mediaDevices) {
      if (start) {
        debug('Start button was found on this page, setting up TTP');
        showStartButton();
        toggleLanguagePicker();
        stop.addEventListener('click', stopStt);
        start.addEventListener('click', startStt);
        mode.addEventListener('change', toggleLanguagePicker);
      }
    } else {
      debug("Browser does not support window.navigator.mediaDevices");
      if (status) {
        status.innerText = "Browser does not support window.navigator.mediaDevices"
      }
    }
  }

  function init() {
    bindUIEvents();
  }

  return {
    init,
  };
})();

export default stt;
