<template>
  <div class="user-database__select">
    {{ data.label }}:
    <select :name="data.id" v-on:change="handleFilter($event, uid)">
      <option value>Select</option>
      <option
        v-for="(filterOption, index) in data.options"
        :key="index"
        :value="filterOption"
        :selected="data.selected(filterOption)"
      >
        {{ filterOption }}
      </option>
    </select>
    <button class="btn btn--icon" v-on:click="handleRemoveFilter(data, uid)">
      <icon-base icon-name="cross" width="11" height="11" className=""
        ><icon-cross
      /></icon-base>
    </button>
  </div>
</template>

<script>
import IconBase from "../icons/IconBase.vue";
import IconAdd from "../icons/IconAdd";
import IconFilter from "../icons/IconFilter";
import IconCross from "../icons/IconCross";

export default {
  name: "UserDatabaseFilter",
  components: {
    IconBase,
    IconAdd,
    IconFilter,
    IconCross,
  },
  props: {
    data: {},
    uid: {},
    handleAddFilter: {},
    handleFilter: {},
    handleRemoveFilter: {},
  },
  created() {},
};
</script>
