<template>
  <div>
    <div class="user-management__title">
      <h1>User Management</h1>
      <a class="btn btn--primary" href="/admin/user/create" target="_self"><svg class="icon sml-push-left"
          viewBox="0 0 18 20" width="16px" height="20px">
          <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/svg/icon-sprite.svg#icon-survey-new"></use>
        </svg>
        New user</a>
    </div>
    <UserDatabase ref="userDatabase" allocationEnabled :allocationOptions="allocationOptions.data" :defaultFilters="{}"
      :filters="[
        {
          id: 'role',
          label: 'Role',
          options: ['Admin', 'Client', 'Respondent']
        },
        ...filters
      ]" :columns="[
        {
          type: 'checkbox'
        },
        {
          label: 'Name',
          value: item => item.name,
          type: 'link',
          action: item => `/admin/user/profile/${item.id}/`
        },
        {
          label: 'Role',
          value: item => item.role || '-'
        },
        {
          label: 'Locality',
          value: item => item.locality_names.length ? displayFirstOrWithCounter(item.locality_names) : '-'
        },
        {
          label: 'Sector',
          value: item => item.sector_names.length ? displayFirstOrWithCounter(item.sector_names) : '-'
        },
        {
          label: 'Country',
          value: item => item.country_names.length ? displayFirstOrWithCounter(item.country_names) : '-'
        },
        {
          label: 'Tags',
          value: item =>
            item.tags.length
              ? item.tags.map(tag => {
                  return `#${tag.tag}`;
                }).join(', ')
              : '-'
        },
        { label: '', type: 'actions', value: item => item }
      ]"></UserDatabase>
  </div>
</template>

<script>
import UserDatabase from "../../database/UserDatabase.vue";
export default {
  name: "UserManagementDatabase",
  components: {
    UserDatabase
  },
  props: {
    filters: {},
    allocationOptions: {}
  },
  methods: {
    callDownload() {
      this.$refs.userDatabase.downloadUsers();
    },
    displayFirstOrWithCounter(array) {
      if (array.length > 1) {
        return `${array[0]} (+${array.length - 1})`;
      }

      return array[0];
    }
  }
};
</script>
