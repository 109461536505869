export function  setQuestionLabel(pageNumber, questionNumber, value) {
    this.$store.commit({
      type: "setQuestionLabel",
      pageNumber: pageNumber,
      questionNumber: questionNumber,
      val: value,
    })
}
export function  addOption(pageNumber, questionNumber) {
    this.$store.commit({
      type: "addOption",
      pageNumber: pageNumber,
      questionNumber: questionNumber,
    });
  }
export function removeOption(pageNumber, questionNumber, index) {
    this.$store.commit({
      type: "removeOption",
      pageNumber: pageNumber,
      questionNumber: questionNumber,
      optionNumber: index,
    });
  }
export function setOptionLabel(pageNumber, questionNumber, index, value) {
    this.$store.commit({
      type: "setOptionLabel",
      pageNumber: pageNumber,
      questionNumber: questionNumber,
      optionNumber: index,
      val: value,
    });
  }

export function setOptionLabels(pageNumber, questionNumber, value){
  this.$store.commit({
    type: "setOptionLabels",
    pageNumber: pageNumber,
    questionNumber: questionNumber,
    val: value,
  });
}

export function setMinOptions(pageNumber, questionNumber, value) {
  this.$store.commit({
    type: "setMinOptions",
    pageNumber: pageNumber,
    questionNumber: questionNumber,
    val: value,
  });
}
export function setMaxOptions(pageNumber, questionNumber, value) {
  this.$store.commit({
    type: "setMaxOptions",
    pageNumber: pageNumber,
    questionNumber: questionNumber,
    val: value,
  })
}
