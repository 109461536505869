<template>
  <div>
    <slot name="pageTitleAndDescription"></slot>
    <slot
      name="hiddenPageInputs"
      :total-inputs="renderableInputs.length"
    ></slot>
    <transition-group
      name="form__input_list"
      tag="div"
      mode="out-in"
      class="form__questions sml-push-top-double"
    >
      <component
        :is="input.type"
        v-for="(input, index) in renderableInputs"
        :key="input.config.uid"
        :page-number="pageNumber"
        :display-number="input.config.displayNo"
        :question-number="input.questionIndex"
        :total-questions-up-to-current-page="totalQuestionsUpToCurrentPage"
        :readonly="disabled"
        class="form__input"
        v-bind:class="{
          'form__input--moved': questionMoved === input.questionIndex,
        }"
      >
        <div slot="hiddenQuestionInputs">
          <!-- Hidden input used to capture input type. Value of question title is captured in the input template  -->
          <input
            type="hidden"
            :name="`pages[${pageNumber}][questions][${input.questionIndex}][type]`"
            :value="`${input.type}`"
          />
          <input
            type="hidden"
            :name="`pages[${pageNumber}][questions][${input.questionIndex}][is_probe]`"
            :value="input.config.parentUID !== rootUID ? 1 : 0"
          />
          <input
            type="hidden"
            :name="`pages[${pageNumber}][questions][${input.questionIndex}][config][parent_question_number]`"
            :value="questionNumber"
          />
        </div>
        <div slot="changeFieldType">
          <div
            class="dropdown"
            v-bind:class="{
              'dropdown--is-visible':
                questionSwitcherActive === input.questionIndex,
            }"
          >
            <div
              class="btn btn--tertiary sml-push-right-double"
              @click="toggleQuestionSwitcher(input.questionIndex)"
              :disabled="disabled"
            >
              {{
                questionSwitcherActive === input.questionIndex
                  ? "Cancel"
                  : "Change"
              }}
            </div>
            <div class="dropdown__menu">
              <a
                class="dropdown__menu_item"
                @click.prevent="
                  changeFieldType('TextInput', input.questionIndex)
                "
              >
                <icon-base icon-name="text" width="17" height="7"
                  ><icon-text
                /></icon-base>
                Short text
              </a>
              <a
                class="dropdown__menu_item"
                @click.prevent="
                  changeFieldType('TextArea', input.questionIndex)
                "
              >
                <icon-base icon-name="text-area" width="17" height="15"
                  ><icon-text-area
                /></icon-base>
                Long text
              </a>
              <a
                class="dropdown__menu_item"
                @click.prevent="
                  changeFieldType('NumberInput', input.questionIndex)
                "
              >
                <icon-base icon-name="number" width="15" height="17"
                  ><icon-number
                /></icon-base>
                Number
              </a>
              <a
                class="dropdown__menu_item"
                @click.prevent="
                  changeFieldType('Checkbox', input.questionIndex)
                "
              >
                <icon-base icon-name="checkbox" width="19" height="20"
                  ><icon-checkbox
                /></icon-base>
                Checkbox
              </a>
              <a
                class="dropdown__menu_item"
                @click.prevent="changeFieldType('Radio', input.questionIndex)"
              >
                <icon-base icon-name="radio" width="19" height="20"
                  ><icon-radio
                /></icon-base>
                Multiple choice
              </a>
              <a
                class="dropdown__menu_item"
                @click.prevent="
                  changeFieldType('Dropdown', input.questionIndex)
                "
              >
                <icon-base icon-name="dropdown" width="19" height="20"
                  ><icon-dropdown
                /></icon-base>
                Dropdown
              </a>
              <a
                class="dropdown__menu_item"
                @click.prevent="changeFieldType('Rating', input.questionIndex)"
              >
                <icon-base icon-name="rating" width="19" height="6"
                  ><icon-rating
                /></icon-base>
                Rating
              </a>
            </div>
          </div>
        </div>
        <div slot="requiredField">
          <label class="sml-push-top flex flex-align-center">
            <input
              type="checkbox"
              :name="`pages[${pageNumber}][questions][${input.questionIndex}][validation][required]`"
              @click="
                !disabled ? toggleRequired(input.questionIndex, $event) : null
              "
              :checked="
                pages[pageNumber].questions[index].validation.required == 'on'
              "
              v-model="
                pages[pageNumber].questions[input.questionIndex].validation
                  .required
              "
              :readonly="disabled"
            />
            <div class="switch"></div>
            <span>Required</span>
          </label>
        </div>
        <div slot="removeField" class="sml-push-right-double">
          <a
            class="btn btn--tertiary"
            @click="!disabled ? removeField(input.questionIndex) : null"
            :disabled="disabled"
          >
            <icon-base icon-name="trash" width="16" height="16" className=""
              ><icon-trash
            /></icon-base>
          </a>
        </div>
        <div
          v-if="!disabled"
          slot="moveFieldDown"
          class="
            form__input_move_field form__input_move_field--down
            flex flex-align-center
            pointer
          "
          @click="!disabled ? moveField(input.questionIndex, 'down') : null"
          v-show="index !== renderableInputs.length - 1"
        >
          <small>Move down</small>
          <icon-base
            icon-name="chevron-down"
            width="13"
            height="7"
            className="sml-push-right"
            ><icon-chevron-down
          /></icon-base>
        </div>
        <div
          v-if="!disabled"
          slot="moveFieldUp"
          class="
            form__input_move_field form__input_move_field--up
            flex flex-align-center
            pointer
          "
          @click="moveField(input.questionIndex, 'up')"
          v-show="index !== 0"
        >
          <icon-base icon-name="chevron-up" width="13" height="7"
            ><icon-chevron-up
          /></icon-base>
          <small>Move up</small>
        </div>
      </component>
    </transition-group>
    <div class="flex flex-justify-center">
      <div v-if="!questionLimit || renderableInputs.length < questionLimit">
        <v-popover>
          <slot
            name="addQuestionButton"
            :toggleQuestionSelector="toggleQuestionSelector"
            :hideQuestionSelector="hideQuestionSelector"
          >
            <div
              class="btn btn--primary"
              v-on:click="toggleQuestionSelector()"
              v-click-outside="hideQuestionSelector"
              :disabled="disabled"
            >
              Add question
              <icon-base
                icon-name="add"
                width="17"
                height="20"
                className="sml-push-right"
                ><icon-add
              /></icon-base>
            </div>
          </slot>

          <template slot="popover">
            <a
              class="dropdown__menu_item"
              @click.prevent="addField('TextInput')"
            >
              <icon-base icon-name="text" width="17" height="7"
                ><icon-text
              /></icon-base>
              Short text
            </a>
            <a
              class="dropdown__menu_item"
              @click.prevent="addField('TextArea')"
            >
              <icon-base icon-name="text-area" width="17" height="15"
                ><icon-text-area
              /></icon-base>
              Long text
            </a>
            <a
              class="dropdown__menu_item"
              @click.prevent="addField('NumberInput')"
            >
              <icon-base icon-name="number" width="15" height="17"
                ><icon-number
              /></icon-base>
              Number
            </a>
            <a
              class="dropdown__menu_item"
              @click.prevent="addField('Checkbox')"
            >
              <icon-base icon-name="checkbox" width="19" height="20"
                ><icon-checkbox
              /></icon-base>
              Checkbox
            </a>
            <a class="dropdown__menu_item" @click.prevent="addField('Radio')">
              <icon-base icon-name="radio" width="19" height="20"
                ><icon-radio
              /></icon-base>
              Multiple choice
            </a>
            <a
              class="dropdown__menu_item"
              @click.prevent="addField('Dropdown')"
            >
              <icon-base icon-name="dropdown" width="19" height="20"
                ><icon-dropdown
              /></icon-base>
              Dropdown
            </a>
            <a class="dropdown__menu_item" @click.prevent="addField('Rating')">
              <icon-base icon-name="rating" width="19" height="6"
                ><icon-rating
              /></icon-base>
              Rating
            </a>
            <a
              class="dropdown__menu_item"
              @click.prevent="addField('Probed')"
              v-if="parentUID === rootUID"
            >
              <icon-base icon-name="probe" width="20" height="20"
                ><icon-probe
              /></icon-base>
              Probed
            </a>
            <a class="dropdown__menu_item" @click.prevent="addField('Ranked')">
              <icon-base icon-name="ranking" width="15" height="17"
                ><icon-number
              /></icon-base>
              Ranking
            </a>
          </template>
        </v-popover>
      </div>

      <div v-if="questionLimit" class="push-top small med-c12 text-center">
        <span v-if="renderableInputs.length !== questionLimit">
          ({{ questionLimit - renderableInputs.length }} remaining)
        </span>
        <span class="bold" v-if="renderableInputs.length === questionLimit">
          Maximum number of
          {{ parentUID !== rootUID ? "sub-questions" : "questions" }} reached
          ({{ questionLimit }}/{{ questionLimit }})
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import ScrollTo from "vue-scrollto";
import IconBase from "../icons/IconBase.vue";
import IconTrash from "../icons/IconTrash.vue";
import IconAdd from "../icons/IconAdd.vue";
import IconText from "../icons/IconText.vue";
import IconTextArea from "../icons/IconTextArea.vue";
import IconNumber from "../icons/IconNumber.vue";
import IconCheckbox from "../icons/IconCheckbox.vue";
import IconDropdown from "../icons/IconDropdown.vue";
import IconRadio from "../icons/IconRadio.vue";
import IconRating from "../icons/IconRating.vue";
import IconProbe from "../icons/IconProbe.vue";
import IconChevronDown from "../icons/IconChevronDown.vue";
import IconChevronUp from "../icons/IconChevronUp.vue";
import IconDrag from "../icons/IconDrag.vue";
import TextInput from "../question_types/TextInput";
import TextArea from "../question_types/TextArea";
import NumberInput from "../question_types/NumberInput";
import Ranked from "../question_types/RankingInput";
import Checkbox from "../question_types/Checkbox";
import Dropdown from "../question_types/Dropdown";
import Radio from "../question_types/Radio";
import Rating from "../question_types/Rating";
import Table from "../question_types/Table";

export default {
  name: "SurveyQuestionBuilder",
  components: {
    IconBase,
    IconTrash,
    IconAdd,
    IconText,
    IconTextArea,
    IconNumber,
    IconCheckbox,
    IconDropdown,
    IconRadio,
    IconRating,
    IconProbe,
    IconChevronDown,
    IconChevronUp,
    IconDrag,
    TextInput,
    TextArea,
    NumberInput,
    Checkbox,
    Dropdown,
    Radio,
    Rating,
    Ranked,
    Table,
  },
  directives: {
    ClickOutside,
  },
  props: {
    pageNumber: Number,
    parentUID: String,
    rootUID: String,
    parentIndex: String | Number,
    questionNumber: Number,
    questionLimit: Number,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      questionSelectorActive: false,
      questionSwitcherActive: null,
      questionMoved: null,
      questions: this.inputs,
    };
  },
  created() {},
  computed: {
    pages() {
      return this.$store.state.survey.pages;
    },
    renderableInputs() {
      return this.$store.getters
        .questions(this.pageNumber)
        .map((question, questionIndex) =>
          Object.assign({}, question, { questionIndex })
        )
        .filter(({ config: { parentUID } }) => this.parentUID === parentUID);
    },
    inputs() {
      return this.$store.getters.questions(this.pageNumber);
    },
    totalQuestionsUpToCurrentPage() {
      /**
       * Used when displaying question numbers. Returns an int which is the
       * number of questions on every page up to (but not including) the current.
       * In the question component, we add that onto the question index on the current page
       * to take advantage of Vue's reactivity system when displaying the final question number
       * @returns {number}
       */
      return this.pages.reduce((acc, curr, i) => {
        if (i < this.pageNumber) {
          const topLevelQuestions = curr.questions.filter(
            (question) => question.config.parentUID === this.parentUID
          );
          acc += topLevelQuestions.length;
        }
        return acc;
      }, 0);
    },
  },
  methods: {
    getDisplayNumber(renderableIndex, parentIndex) {
      const ownIndex =
        this.totalQuestionsUpToCurrentPage + (renderableIndex + 1);
      const parentIndexVal = parentIndex ? `${parentIndex}.` : "";
      return `${parentIndexVal}${ownIndex}`;
    },
    toggleQuestionSelector() {
      this.questionSelectorActive = !this.questionSelectorActive;
    },
    hideQuestionSelector() {
      this.questionSelectorActive = false;
    },
    toggleQuestionSwitcher(index) {
      this.questionSwitcherActive === null
        ? (this.questionSwitcherActive = index)
        : (this.questionSwitcherActive = null);
    },
    addField(type) {
      this.$store.commit({
        type: "addField",
        questionType: type,
        questionNumber: this.questionNumber,
        pageNumber: this.pageNumber,
        parentUID: this.parentUID,
        is_probe: true,
      });

      this.$nextTick(() => {
        ScrollTo.scrollTo(".form__input_list-enter", 2000, { offset: 75 });
      });
    },
    removeField(index) {
      this.$store.commit({
        type: "removeField",
        pageNumber: this.pageNumber,
        questionNumber: index,
      });
      this.$store.commit({
        type: "recalculateDisplayNo",
        pageNumber: this.pageNumber,
      });
    },
    moveField(index, direction) {
      this.$store.commit({
        type: "moveField",
        pageNumber: this.pageNumber,
        questionNumber: index,
        direction,
      });

      this.questionMoved = direction === "up" ? index - 1 : index + 1;

      // questionMoved sets a class on the question, which highlights it temporarily when moved. Here we reset questionMoved
      // after that transition completes to avoid issues with it being re-highlighted when toggling between pages,
      // or moving a question then removing the question above it
      setTimeout(() => {
        this.questionMoved = null;
      }, 2000);
    },
    changeFieldType(type, index) {
      this.$store.commit({
        type: "changeFieldType",
        pageNumber: this.pageNumber,
        questionNumber: index,
        questionType: type,
      });

      this.toggleQuestionSwitcher(index);
    },
    toggleRequired(index, e) {
      this.$store.commit({
        type: "toggleRequired",
        pageNumber: this.pageNumber,
        questionNumber: index,
        required: e.target.checked,
      });
    },
  },
};
</script>
