const cardAction = (() => {
  const triggerSelector = '.card__rollover a';
  const scrolldownTriggerSelector = '.card__scrolldown--trigger';

  function toggleCardSection(e) {
    const trigger = e.target.getAttribute('data-trigger');

    const anchor = e.target.closest('.card').querySelector(`[data-anchor='${trigger}']`);

    e.target.classList.toggle('link--active');
    e.target.closest('.card__inner').classList.toggle('card--active');

    if (anchor) {
      anchor.classList.toggle('hide-sml');
    }
  }

  function toggleScrolldown(e) {
    e.preventDefault();
    const anchor = e.target.closest('.card');
    const scrolldown = anchor.querySelector('.card__scrolldown');
    scrolldown.classList.toggle('card__scrolldown--visible');
  }

  function bindUIEvents() {
    document.addEventListener('click', (e) => {
      if (e.target.matches(triggerSelector)) {
        toggleCardSection(e);
      }
      if (e.target.matches(scrolldownTriggerSelector)) {
        toggleScrolldown(e);
      }
    });
  }

  function init() {
    bindUIEvents();
  }

  return {
    init,
  };
})();

export default cardAction;
