<template>
  <div class="user-database__filters">
    <div class="user-database__select-wrapper">
      <v-popover v-if="allocationEnabled" offset="16">
        <button class="btn btn--filters">
          Allocate to survey
          <icon-base width="13" height="7" className="sml-push-right"
            ><icon-chevron-down
          /></icon-base>
        </button>
        <template slot="popover">
          <span
            v-for="option in allocationOptions"
            :key="option.id"
            class="popover__item"
            v-on:click="handleAllocation(option.uuid)"
          >
            {{ option.name }}
            <icon-base
              icon-name="add"
              width="17"
              height="20"
              className="sml-push-right"
              ><icon-add
            /></icon-base>
          </span>
        </template>
      </v-popover>
      <v-popover v-else offset="16">
        <button class="btn btn--filters">
          <icon-base
            icon-name="add"
            width="16"
            height="16"
            :viewboxHeight="24"
            :viewboxWidth="24"
            ><icon-filter /></icon-base
          >Filters
          <icon-base width="13" height="7" className="sml-push-right"
            ><icon-chevron-down
          /></icon-base>
        </button>
        <template slot="popover">
          <span
            v-for="filter in filters"
            :key="filter.id"
            class="popover__item"
            v-on:click="handleAddFilter(filter)"
          >
            {{ filter.label }}
            <icon-base
              icon-name="add"
              width="17"
              height="20"
              className="sml-push-right"
              ><icon-add
            /></icon-base>
          </span>
        </template>
      </v-popover>
      <user-database-filter
        v-for="filter in Object.keys(activeFilters)"
        :data="{
          ...filters.find((item) => item.id === activeFilters[filter].type),
          selected: (item) => activeFilters[filter] === item,
        }"
        :key="filter"
        :uid="filter"
        :handleAddFilter="handleAddFilter"
        :handleFilter="handleFilter"
        :handleRemoveFilter="handleRemoveFilter"
      ></user-database-filter>
    </div>
    <div class="user-database__search">
      <input
        ref="userSearchInput"
        type="search"
        placeholder="Search user"
        @change="$emit('update:searchString', $event.target.value)"
      />
    </div>
  </div>
</template>

<script>
import UserDatabaseFilter from "./UserDatabaseFilter.vue";
import IconBase from "../icons/IconBase.vue";
import IconAdd from "../icons/IconAdd";
import IconFilter from "../icons/IconFilter";
import IconChevronDown from "../icons/IconChevronDown.vue";

export default {
  name: "UserDatabaseFilters",
  components: {
    UserDatabaseFilter,
    IconBase,
    IconAdd,
    IconFilter,
    IconChevronDown,
  },
  props: {
    allocationOptions: {},
    allocationEnabled: Boolean,
    handleAllocation: Function,
    filters: {},
    activeFilters: {},
    otherActiveFilters: {},
    handleAddFilter: {},
    handleFilter: {},
    handleRemoveFilter: {},
  },
  updated() {},
};
</script>
