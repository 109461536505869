<template>
  <div
    :key="stateQuestion.question.questionNumber"
    class="sb__question-inner animated fadeIn"
  >
    <component
      :is="stateQuestion.question.type"
      :page-number="stateQuestion.page.pageNumber"
      :question-number="stateQuestion.question.questionNumber"
      :display-number="stateQuestion.question.config.displayNo"
    >
      <div slot="requiredField">
        <label class="flex flex-align-center">
          <input
            type="checkbox"
            :name="`pages[${stateQuestion.page.pageNumber}][questions][${stateQuestion.question.questionNumber}][validation][required]`"
            @click="
              !disabled
                ? toggleRequired(stateQuestion.question.questionNumber, $event)
                : null
            "
            :checked="stateQuestion.question.validation.required"
          />
          <div class="switch"></div>
          <span>Required</span>
        </label>
      </div>
    </component>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import ScrollTo from "vue-scrollto";
import IconBase from "../icons/IconBase.vue";
import IconTrash from "../icons/IconTrash.vue";
import IconDelete from "../icons/IconDelete.vue";
import IconReorder from "../icons/IconReorder.vue";
import IconAdd from "../icons/IconAdd.vue";
import IconText from "../icons/IconText.vue";
import IconTextArea from "../icons/IconTextArea.vue";
import IconNumber from "../icons/IconNumber.vue";
import IconCheckbox from "../icons/IconCheckbox.vue";
import IconDropdown from "../icons/IconDropdown.vue";
import IconRadio from "../icons/IconRadio.vue";
import IconRating from "../icons/IconRating.vue";
import IconProbe from "../icons/IconProbe.vue";
import IconChevronDown from "../icons/IconChevronDown.vue";
import IconChevronUp from "../icons/IconChevronUp.vue";
import IconDrag from "../icons/IconDrag.vue";
import IconDuplicate from "../icons/IconDuplicate.vue";
import TextInput from "../question_types/TextInput";
import TextArea from "../question_types/TextArea";
import NumberInput from "../question_types/NumberInput";
import Ranked from "../question_types/RankingInput";
import Checkbox from "../question_types/Checkbox";
import Dropdown from "../question_types/Dropdown";
import Radio from "../question_types/Radio";
import Probed from "../question_types/Probed";
import Rating from "../question_types/Rating";
import Table from "../question_types/Table";

export default {
  name: "SurveyQuestionBuilder",
  inject: ["$validator"],
  components: {
    IconBase,
    IconTrash,
    IconDelete,
    IconAdd,
    IconText,
    IconTextArea,
    IconNumber,
    IconCheckbox,
    IconDropdown,
    IconRadio,
    IconRating,
    IconProbe,
    IconChevronDown,
    IconChevronUp,
    IconDrag,
    IconReorder,
    IconDuplicate,
    TextInput,
    TextArea,
    NumberInput,
    Checkbox,
    Dropdown,
    Radio,
    Probed,
    Rating,
    Ranked,
    Table,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    handleRemoveQuestion: { type: Function },
    stateQuestion: {
      type: Object,
      required: true
    }
  },
  computed: {
  },

  methods: {
    toggleRequired(index, e) {
      this.$store.commit({
        type: "toggleRequired",
        pageNumber: this.stateQuestion.page.pageNumber,
        questionNumber: index,
        required: e.target.checked,
      });
    },
  },
};
</script>
