<template>
  <div>
    <div class="form__input_actions flex flex-align-center flex-between">
      <div class="flex flex-align-center">
        <div class="flex flex-align-center">
          <icon-base icon-name="number" width="15" height="17"
            ><icon-number
          /></icon-base>
          <span class="form__input_type">
            {{ displayNumber }}. Number input
          </span>
        </div>
        <div>
          <slot name="changeFieldType"></slot>
        </div>
      </div>
      <div class="flex flex-align-center">
        <slot name="hiddenQuestionInputs"></slot>
        <slot name="requiredField"></slot>
        <slot name="removeField"></slot>
      </div>
    </div>
    <question-label
    :pageNumber="pageNumber"
    :questionNumber="questionNumber"
    >
    </question-label>
    <div>
      <slot name="moveFieldUp"></slot>
      <slot name="moveFieldDown"></slot>
    </div>
  </div>
</template>

<script>
import Focus from "../../directives/focus";
import IconBase from "../icons/IconBase.vue";
import IconNumber from "../icons/IconNumber.vue";
import QuestionLabel from "./QuestionLabel.vue";

export default {
  name: "NumberInput",
  components: {
    IconBase,
    IconNumber,
    QuestionLabel
  },
  directives: {
    Focus,
  },
  inject: ["$validator"],
  props: {
    pageNumber: Number,
    questionNumber: Number,
    displayNumber: String | Number,
  },
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>
