<template>
  <v-popover>
    <input
      :id="inputId"
      v-model="stateValue.tag"
      placeholder="Create a new tag..."
      type="text"
      :style="{ width: inputWidth }"
      autocomplete="off"
      @input="onInput"
      @focus="focus = true"
      @keydown="onKeydown"
    />
    <div slot="popover">
      <div v-for="(tag, index) in matchedTags" :key="index">
        <a
          href="#"
          class="dark-grey"
          v-text="'#' + tag.tag"
          @click.prevent="onClickTag(tag)"
        ></a>
      </div>
    </div>
  </v-popover>
</template>

<script>
import _ from "lodash";

export default {
  name: "TagInput",
  components: {},
  props: {
    value: {
      required: true,
      type: Object,
    },
    inputId: {
      type: String,
      default: "tag-input",
    },
    availableTags: {
      required: true,
      type: Array,
    },
    inputWidth: {
      type: String,
      default: "15rem",
    },
  },
  data() {
    return {
      stateValue: { tag: "", id: null },
      focus: false,
    };
  },
  computed: {
    showPopover() {
      return !!(this.focus && this.matchedTags.length);
    },
    matchedTags() {
      if (!this.stateValue.tag.length) {
        return this.availableTags.slice(0, 10);
      }
      return _.filter(this.availableTags, (tag) => {
        return (
          tag.tag.toLowerCase().indexOf(this.stateValue.tag.toLowerCase()) !==
          -1
        );
      }).slice(0, 10);
    },
  },
  watch: {
    value(value) {
      this.stateValue = value;
    },
  },
  methods: {
    onKeydown(event) {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        this.onClickTag(this.stateValue);
      }
    },
    onInput() {
      this.stateValue.id = null;
      this.$emit("input", this.stateValue);
    },
    onClickTag(tag) {
      this.$emit("clickExisting", tag);
    },
  },
};
</script>

<style scoped></style>
