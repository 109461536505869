<template>
  <div>
    <label v-text="label" v-bind:class="{ required: required }"></label>
    <div
      class="flex flex-align-center mb-2"
      v-for="(input, selectIndex) in stateSelected"
      v-bind:key="`select-${selectIndex}`"
    >
      <div style="flex-grow: 1">
        <select
          :required="required"
          class="required user-conditional-select"
          :name="name"
        >
          <option :value="null">Please select</option>
          <option
            v-for="(item, key, optionIndex) in options"
            :key="`option-${key}`"
            :value="key"
            :selected="stateSelected[selectIndex] == key"
          >
            {{ item }}
          </option>
        </select>
      </div>
      <div class="sml-padding-left" v-if="stateSelected.length">
        <a
          href="#"
          class="danger"
          title="Delete"
          @click.prevent="deleteOption(selectIndex)"
        >
          <icon-base
            :viewbox-height="16"
            :viewbox-width="16"
            :height="16"
            :width="16"
            hide-title
          >
            <icon-delete />
          </icon-base>
        </a>
      </div>
    </div>

    <div>
      <button
        v-if="addAll && renderAdd"
        type="button"
        class="btn btn--secondary"
        @click="addAllOptions"
      >
        {{ addAllButtonLabel }}
      </button>
      <button
        v-if="renderAdd"
        type="button"
        class="btn btn--secondary"
        @click="addOption"
      >
        {{ addButtonLabel }}
      </button>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import IconBase from "../icons/IconBase.vue";
import IconDelete from "../icons/IconDelete.vue";

export default {
  name: "MultiSelectField",
  components: {
    IconBase,
    IconDelete,
  },
  props: {
    label: {
      type: String,
    },
    addAll: {
      type: Boolean,
      default: false,
    },
    addButtonLabel: {
      type: String,
      default: "Add another item",
    },
    addAllButtonLabel: {
      type: String,
      default: "Add all items",
    },
    name: {
      type: String,
      required: true,
    },
    options: {
      required: true,
      default: [],
    },
    selected: {},
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      stateSelected: [],
    };
  },
  computed: {
    renderOptions() {
      if (Array.isArray(this.options)) return this.options;
      else return Object.values(this.options);
    },

    renderAdd() {
      return this.stateSelected.length !== this.renderOptions.length;
    },
  },
  created() {
    this.stateSelected = this.selected || [{}];
  },
  methods: {
    addOption() {
      this.stateSelected.push({ id: null });
    },
    addAllOptions() {
      this.stateSelected = Object.keys(this.options);
    },
    deleteOption(index) {
      this.stateSelected.splice(index, 1);
    },
  },
};
</script>

<style scoped></style>
